import React, { Component, Suspense } from "react";
import { Switch } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";

import FrontendAuth from "./FrontendAuth";
import routerConfig from "./routerMap";

class Routes extends Component {
    render() {
        return (
            <Suspense
                fallback={
                    <div className="kems-loading-container">
                        <SyncOutlined
                            className="kems-loading-icon"
                            spin={true}
                        />
                    </div>
                }
            >
                <Switch>
                    <FrontendAuth config={routerConfig} />
                </Switch>
            </Suspense>
        );
    }
}

export default Routes;
