import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger"; // Formal version, removing redux-logger middleware.

import reducer from "./reducer";

// const logger = createLogger();
//
// const store = createStore(reducer, applyMiddleware(thunk, logger));

// Redux Devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

// const store = createStore(reducer, applyMiddleware(thunk));

export default store;
