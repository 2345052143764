import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/**
 * Routing control logic
 */
class FrontendAuth extends Component {
    componentDidUpdate() {
        const {
            // action
            
        } = this.props;
    }

    render() {
        const {
            // default property
            location,

            // parent
            config,

            // state
            isLogined,
            userPrivilege
        } = this.props;

        const { pathname, search } = location;
        // 可考虑 window.open().postMessage() 方法

        // console.log(`location`, location);

        const targetRouterConfig = config.find((v) => v.path === pathname);

        // No authorization and no logined required.
        if(targetRouterConfig && !targetRouterConfig.authorization && !isLogined) {
            const { component } = targetRouterConfig;

            return (
                <Route path={pathname} exact={true} component={component} />
            );
        }

        if(isLogined) {
            if(pathname === '/' || pathname === '/login') {
                if(userPrivilege !== 0) {
                    return (
                        <Redirect to="/admin" />
                    );
                } else {
                    return (
                        <Redirect to="/station" />
                    );
                }
            } else {
                if(targetRouterConfig) {
                    const { component } = targetRouterConfig;
                    const specialPathReg = /(\/station\/device\/pcs)|(\/station\/device\/gse)|(\/station\/device\/battery)|(\/station\/device\/KDC-)/;

                    if(specialPathReg.test(pathname)) {
                        const { deviceDriver } = targetRouterConfig;

                        return (
                            <Route
                                path={pathname}
                                exact={true}
                                render={(props) => (
                                    <targetRouterConfig.component
                                        key={ deviceDriver }
                                        {...props}
                                    />
                                )}
                            />
                        );
                    } else {
                        return (
                            <Route path={pathname} exact={true} component={component} />
                        );
                    }
                } else {
                    return (
                        <Redirect to="/404" />
                    );
                }
            }
        } else {
            if(targetRouterConfig && targetRouterConfig.authorization) {
                if(pathname === '/station/largeScreenMonitoring') {
                    const { component } = targetRouterConfig;

                    return (
                        <Route path={pathname} exact={true} component={component} />
                    );
                } else {
                    return (
                        <Redirect to="/login" />
                    );
                }
            } else {
                return (
                    <Redirect to="/404" />
                );
            }
        }
    }
}

const mapStateToProps = (state) => ({
    isLogined: state.common.isLogined,
    userPrivilege: state.common.userLevel
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FrontendAuth);
