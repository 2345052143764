import { combineReducers } from "redux";

import reducerOfCommon from "./common";
import reducerOfLogin from "./login";
import reducerOfPersonalCenter from "./personalCenter";
import {
    stationList,
    accountManage,
    operationLog,
    collectorUpgrade,
    pageSetup,
} from "./reducerOfAdmin";
import {
    station,

    micronetMonitoring,
    runStrategy,
    LargeScreenMonitoring,

    GSE,
    battery,
    energyStorageTank,
    KDC,
    combinerBox,
    chargingPile,
    PVInverter,
    electricMeter,
    camera,
    aircondition,
    STS,

    energyStatistics,
    faultAlarm,
    screenLogs,
} from "./reducerOfStation";
import {
    stationInfoForm,
    navOfStation,
    tabCard,
} from "./component";

let reducer = combineReducers({
    common: reducerOfCommon,

    login: reducerOfLogin,

    personalCenter: reducerOfPersonalCenter,

    stationList: stationList,
    accountManage: accountManage,
    operationLog: operationLog,
    collectorUpgrade: collectorUpgrade,
    pageSetup: pageSetup,

    station: station,

    micronetMonitoring: micronetMonitoring,
    runStrategy: runStrategy,
    LargeScreenMonitoring,

    GSE: GSE,
    battery: battery,
    energyStorageTank: energyStorageTank,
    KDC: KDC,
    combinerBox: combinerBox,
    chargingPile: chargingPile,
    PVInverter: PVInverter,
    electricMeter: electricMeter,
    camera: camera,
    aircondition: aircondition,
    STS: STS,

    energyStatistics: energyStatistics,
    faultAlarm: faultAlarm,
    screenLogs: screenLogs,

    stationInfoForm: stationInfoForm,
    navOfStation: navOfStation,
    tabCard: tabCard,
});

export default reducer;
