import moment from "moment";

import iconOfGenerationPowerInPVInverter from "../assets/img/station/deviceMonitoring/generationPowerOfPVInverter.png";
import iconOfIncomeInPVInverter from "../assets/img/station/deviceMonitoring/incomeOfPVInverter.png";
import iconOfAlarmInPVInverter from "../assets/img/station/deviceMonitoring/alarmOfPVInverter.png";
import {
    createCurrentDateTime
} from "../tools/common";

/**
 * Station Common
 */
export const station = (state = {
    dailyExistAlarmNumOfStation: 0,
    visibilityOfRealTimeAlarmList: false,
    realtimeAlarmList: [],
    whetherHaveSecondaryPassword: false,
    collectorList: localStorage.getItem('collectorList') ? JSON.parse(localStorage.getItem('collectorList')) : [],
    collectorIdToControl: localStorage.getItem('collectorIdToControl') ? localStorage.getItem('collectorIdToControl') : '',
    statusOfCollectorControlled: localStorage.getItem('statusOfCollectorControlled') ? parseInt(localStorage.getItem('statusOfCollectorControlled')) : 0,
    collectorTypeToControl: localStorage.getItem('collectorTypeToControl') ? parseInt(localStorage.getItem('collectorTypeToControl')) : 1,
    openKeysOfStationNav: [],
    subDeviceList: [],
    deviceIdToControl: localStorage.getItem('deviceIdToControl') ? localStorage.getItem('deviceIdToControl') : '',
    deviceTypeToControl: localStorage.getItem('deviceTypeToControl') ? localStorage.getItem('deviceTypeToControl') : '',
    deviceDriverToControl: localStorage.getItem('deviceDriverToControl') ? localStorage.getItem('deviceDriverToControl') : '',
    deviceNameToControl: '',
    deviceTypeListOfStation: JSON.parse(localStorage.getItem('deviceTypeListOfStation')) ? JSON.parse(localStorage.getItem('deviceTypeListOfStation')) : [],
    navigationOfDevice: JSON.parse(localStorage.getItem('navigationOfDevice')) ? JSON.parse(localStorage.getItem('navigationOfDevice')) : [],
    indexOfStationSecondaryNav: localStorage.getItem('indexOfStationSecondaryNav') ? parseInt(localStorage.getItem('indexOfStationSecondaryNav')) : 0,
    selectedKeyOfStationNav: localStorage.getItem('selectedKeyOfStationNav') ? localStorage.getItem('selectedKeyOfStationNav') : '/station',
    webSocketOfRealAlarm: null,
}, action) => {
    switch(action.type) {
        case 'SET_DAILY_EXIST_ALARM_NUM_OF_STATION':
            return Object.assign({}, state, {
                dailyExistAlarmNumOfStation: action.payload.dailyExistAlarmNumOfStation
            });
        case 'QUERY_COLLECTOR_LIST_OF_STATION':
            return Object.assign({}, state, {
                collectorList: action.payload.collectorList,
                collectorIdToControl: action.payload.collectorIdToControl,
                statusOfCollectorControlled: action.payload.statusOfCollectorControlled,
                collectorTypeToControl: action.payload.collectorTypeToControl,
            });
        case 'CHANGE_COLLECTOR_ID_TO_CONTROL':
            return Object.assign({}, state, {
                collectorIdToControl: action.payload.collectorIdToControl,
                statusOfCollectorControlled: action.payload.statusOfCollectorControlled,
                collectorTypeToControl: action.payload.collectorTypeToControl,
            });
        case 'SET_SUB_DEVICE_LIST_OF_STATION':
            return Object.assign({}, state, {
                subDeviceList: action.payload.subDeviceList,
                deviceIdToControl: action.payload.deviceIdToControl,
                deviceTypeToControl: action.payload.deviceTypeToControl,
                deviceDriverToControl: action.payload.deviceDriverToControl,
            });
        case 'CHANGE_DEVICE_ID_TO_CONTROL':
            return Object.assign({}, state, {
                deviceIdToControl: action.payload.deviceIdToControl
            });
        case 'CHANGE_DEVICE_NAME_TO_CONTROL':
            return Object.assign({}, state, {
                deviceNameToControl: action.payload.deviceNameToControl
            });
        case 'SET_NAVIGATION_OF_DEVICE':
            return Object.assign({}, state, {
                deviceTypeListOfStation: action.payload.deviceTypeListOfStation,
                navigationOfDevice: action.payload.navigationOfDevice
            });
        case 'CHANGE_INDEX_OF_STATION_SECONDARY_NAV':
            return Object.assign({}, state, {
                indexOfStationSecondaryNav: action.payload.indexOfStationSecondaryNav
            });
        case 'CHANGE_SELECTED_KEY_OF_STATION_NAV':
            return Object.assign({}, state, {
                selectedKeyOfStationNav: action.payload.selectedKeyOfStationNav
            });
        case 'TOGGLE_VISIBILITY_OF_REALTIME_ALARM_LIST':
            return Object.assign({}, state, {
                visibilityOfRealTimeAlarmList: action.payload.visibilityOfRealTimeAlarmList,
            });
        case 'CHANGE_REALTIME_ALARM_LIST':
            return Object.assign({}, state, {
                visibilityOfRealTimeAlarmList: action.payload.visibilityOfRealTimeAlarmList,
                realtimeAlarmList: action.payload.realtimeAlarmList,
            });
        case 'SET_STATE_OF_HAVE_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                whetherHaveSecondaryPassword: action.payload.whetherHaveSecondaryPassword,
            });
        case 'SET_WEB_SOCKET_OF_REAL_ALARM':
            return Object.assign({}, state, {
                webSocketOfRealAlarm: action.payload.webSocketOfRealAlarm,
            });
        case 'RESET_WEB_SOCKET_OF_REAL_ALARM':
            return Object.assign({}, state, {
                webSocketOfRealAlarm: action.payload.webSocketOfRealAlarm,
                visibilityOfRealTimeAlarmList: action.payload.visibilityOfRealTimeAlarmList,
                realtimeAlarmList: action.payload.realtimeAlarmList,
            });
        case 'SET_DEVICE_TYPE_TO_CONTROL':
            return Object.assign({}, state, {
                deviceTypeToControl: action.payload.deviceTypeToControl,
            });
        case 'SET_OPEN_KEYS_OF_STATION_NAV':
            return Object.assign({}, state, {
                openKeysOfStationNav: action.payload.openKeysOfStationNav,
            });
        default:
            return state;
    }
}

/**
 * Microgrid Monitoring
 */
export const micronetMonitoring = (state = {
    mainDataList: [],
    collectorType: 1,
    canvasOfConceptMap: null,
    canvasUsedToPerformAnimation: null,
    dataOfConceptMap: {
        homePcsGuides: [
            {
                voltageOutab: 0.0,
                voltageOutbc: 0.0,
                voltageOutca: 0.0,
                currentOuta: 0.0,
                currentOutb: 0.0,
                currentOutc: 0.0,
                voltageLoadab: 0.0,
                voltageLoadbc: 0.0,
                voltageLoadca: 0.0,
                currentLoada: 0.0,
                currentLoadb: 0.0,
                currentLoadc: 0.0,
                voltagePowerab: 0.0,
                voltagePowerbc: 0.0,
                voltagePowerca: 0.0,
                currentPowera: 0.0,
                currentPowerb: 0.0,
                currentPowerc: 0.0,
                voltageDc: 0.0,
                currentDc: 0.0,
                powerDc: 0.0,
                dayOut: 0.0,
                dayIn: 0.0
            }
        ],
        homeKdcGuides: [],
        homeBmsGuides: [
            {
                soc: 0.0,
                soh: 0.0,
                voltage: 0.0,
                current: 0.0
            },
            {
                soc: 0.0,
                soh: 0.0,
                voltage: 0.0,
                current: 0.0
            }
        ],
        homeGseGuides: [],
        homeMeterGuides: [],
    },
    dataOfSpecialConceptMap: {
        state: 0,
        battery: [],
        PV: [],
        powerGrid: [],
        GSE: [],
        load: [],
    },
    pollingOfDrawConceptMap: -1,
    timesOfDrawConceptMap: 108000,
}, action) => {
    switch(action.type) {
        case 'SET_CANVAS_OF_CONCEPT_MAP':
            return Object.assign({}, state, {
                canvasOfConceptMap: action.payload.canvasOfConceptMap,
                canvasUsedToPerformAnimation: action.payload.canvasUsedToPerformAnimation,
            });
        case 'QUERY_MONITORING_DATA_OF_COLLECTOR':
            return Object.assign({}, state, {
                mainDataList: action.payload.mainDataList,
                collectorType: action.payload.collectorType,
                dataOfConceptMap: action.payload.dataOfConceptMap,
                dataOfSpecialConceptMap: action.payload.dataOfSpecialConceptMap,
            });
        case 'SET_POLLING_OF_DRAW_CONCEPT_MAP':
            return Object.assign({}, state, {
                pollingOfDrawConceptMap: action.payload.pollingOfDrawConceptMap
            });
        case 'SET_TIMES_OF_DRAW_CONCEPT_MAP':
            return Object.assign({}, state, {
                timesOfDrawConceptMap: action.payload.timesOfDrawConceptMap
            });
        default:
            return state;
    }
}

/**
 * Run Strategy
 */
export const runStrategy = (state = {
    collectorType: 1,
    strategyPatternList: [],
    strategyPatternCode: 0,
    maxCapacity: '',
    secondaryPassword: '',
    batterySOCDataList: [
        {
            name: '上限',
            value: 99,
            unit: '%',
            required: true
        },
        {
            name: '阶段2',
            value: '',
            unit: '%',
            required: true
        },
        {
            name: '阶段3',
            value: '',
            unit: '%',
            required: true
        },
        {
            name: '下限',
            value: 1,
            unit: '%',
            required: true
        }
    ],
    PVElectricPrice: '',
    electricPriceDataList: [
        {
            interval: 3,
            name: '尖',
            value: '',
            unit: '¥',
            required: true,
        },
        {
            interval: 0,
            name: '峰',
            value: '',
            unit: '¥',
            required: true,
        },
        {
            interval: 1,
            name: '平',
            value: '',
            unit: '¥',
            required: true,
        },
        {
            interval: 2,
            name: '谷',
            value: '',
            unit: '¥',
            required: true,
        },
    ],
    SPFVDataList: [],
    visibilityOfModalToChangeSecondaryPassword: false,
    originalSecondaryPassword: '',
    visibilityOfModalToResetSecondaryPassword: '',
    visibilityOfModalToSecondaryVerification: false,
    secondaryPasswordToVerify: '',
    callbackAfterSecondaryVerification: () => {},
    previousBasicInfoOfType6: {},
    basicInfoOfType6: {},
    formDataOfRunningModeOfType6: {
        "1": {},
        "2": {},
        "3": {},
        "4": {},
    },
    formDataOfACCoupleSetOfType6: {},
    periodListOfMode2OfType6: {
        weekday: [
            { orderId: 1, startHour: '0', startMin: '0', endHour: '0', endMin: '0', power: '0' },
        ],
        weekend: [
            { orderId: 1, startHour: '0', startMin: '0', endHour: '0', endMin: '0', power: '0' },
        ],
    },
    typeCodeOfListInDrawer: 1,
    visibilityOfDrawerForDataListOfAdvancedSet: false,
    ULLimitsOfTHInCabinetOfType6: [],
    batteryInfosOfType6: [],
}, action) => {
    switch(action.type) {
        case 'QUERY_TYPE_AND_STRATEGY_OF_COLLECTOR':
            return Object.assign({}, state, {
                collectorType: action.payload.collectorType,
                strategyPatternList: action.payload.strategyPatternList,
                strategyPatternCode: action.payload.strategyPatternCode
            });
        case 'QUERY_BASIC_INFO_OF_TYPE6':
            return Object.assign({}, state, {
                previousBasicInfoOfType6: action.payload.previousBasicInfoOfType6,
                basicInfoOfType6: action.payload.basicInfoOfType6,
                formDataOfRunningModeOfType6: action.payload.formDataOfRunningModeOfType6,
                formDataOfACCoupleSetOfType6: action.payload.formDataOfACCoupleSetOfType6,
            });
        case 'CHANGE_STRATEGY_PATTERN':
            return Object.assign({}, state, {
                strategyPatternCode: action.payload.strategyPatternCode
            });
        case 'SET_MAX_CAPACITY':
            return Object.assign({}, state, {
                maxCapacity: action.payload.maxCapacity,
            });
        case 'SET_BATTERY_SOC_DATA_OF_COLLECTOR':
            return Object.assign({}, state, {
                batterySOCDataList: action.payload.batterySOCDataList
            });
        case 'SET_PV_ELECTRIC_PRICE_OF_COLLECTOR':
            return Object.assign({}, state, {
                PVElectricPrice: action.payload.PVElectricPrice,
            });
        case 'SET_ELECTRIC_PRICE_DATA_OF_COLLECTOR':
            return Object.assign({}, state, {
                electricPriceDataList: action.payload.electricPriceDataList
            });
        case 'SET_SPFV_DATA_OF_COLLECTOR':
            return Object.assign({}, state, {
                SPFVDataList: action.payload.SPFVDataList
            });
        case 'INPUT_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                secondaryPassword: action.payload.secondaryPassword,
            });
        case 'TOOGLE_MODAL_TO_CHANGE_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfModalToChangeSecondaryPassword: action.payload.visibilityOfModalToChangeSecondaryPassword,
                originalSecondaryPassword: action.payload.originalSecondaryPassword,
                secondaryPassword: action.payload.secondaryPassword,
            });
        case 'INPUT_ORIGINAL_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                originalSecondaryPassword: action.payload.originalSecondaryPassword,
            });
        case 'TOOGLE_MODAL_TO_RESET_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfModalToResetSecondaryPassword: action.payload.visibilityOfModalToResetSecondaryPassword,
            });
        case 'TOOGLE_MODAL_TO_VERIFY_SECONDARY_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfModalToSecondaryVerification: action.payload.visibilityOfModalToSecondaryVerification,
                secondaryPasswordToVerify: action.payload.secondaryPasswordToVerify,
                callbackAfterSecondaryVerification: action.payload.callbackAfterSecondaryVerification,
            });
        case 'INPUT_SECONDARY_PASSWORD_TO_VERIFY':
            return Object.assign({}, state, {
                secondaryPasswordToVerify: action.payload.secondaryPasswordToVerify,
            });
        case 'CHANGE_BASIC_INFO_OF_TYPE6':
            return Object.assign({}, state, {
                basicInfoOfType6: action.payload.basicInfoOfType6,
            });
        case 'CHANGE_FORM_DATA_OF_RUNNING_MODE_OF_TYPE6':
            return Object.assign({}, state, {
                formDataOfRunningModeOfType6: action.payload.formDataOfRunningModeOfType6,
            });
        case 'CHANGE_PERIOD_LIST_OF_MODE2_OF_TYPE6':
            return Object.assign({}, state, {
                periodListOfMode2OfType6: action.payload.periodListOfMode2OfType6,
            });
        case 'OPEN_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET':
            return Object.assign({}, state, {
                typeCodeOfListInDrawer: action.payload.typeCodeOfListInDrawer,
                visibilityOfDrawerForDataListOfAdvancedSet: action.payload.visibilityOfDrawerForDataListOfAdvancedSet,
            });
        case 'CLOSE_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET':
            return Object.assign({}, state, {
                typeCodeOfListInDrawer: action.payload.typeCodeOfListInDrawer,
                visibilityOfDrawerForDataListOfAdvancedSet: action.payload.visibilityOfDrawerForDataListOfAdvancedSet,
                ULLimitsOfTHInCabinetOfType6: action.payload.ULLimitsOfTHInCabinetOfType6,
                batteryInfosOfType6: action.payload.batteryInfosOfType6,
            });
        case 'SET_UL_LIMITS_OF_TH_IN_CABINET_OF_TYPE6':
            return Object.assign({}, state, {
                ULLimitsOfTHInCabinetOfType6: action.payload.ULLimitsOfTHInCabinetOfType6,
            });
        case 'SET_BATTERY_INFOS_OF_TYPE6':
            return Object.assign({}, state, {
                batteryInfosOfType6: action.payload.batteryInfosOfType6,
            });
        case 'CHANGE_FORM_DATA_OF_AC_COUPLE_SET_OF_TYPE6':
            return Object.assign({}, state, {
                formDataOfACCoupleSetOfType6: action.payload.formDataOfACCoupleSetOfType6,
            });
        default:
            return state;
    }
}


/**
 * Large Screen Monitoring
 */
export const LargeScreenMonitoring = (state = {
    currentTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    dataOfConceptMap: {
        energyStorage: { out: 0, in: 0 },
        powerGrid: { out: 0, in: 0 },
        PV: 0,
        load: 0,
    },
    mainParameterList: [
        { key: 'cumulativeCharge', name: '累计充电量', value: 0, unit: 'kWh' },
        { key: 'cumulativeDischarge', name: '累计放电量', value: 0, unit: 'kWh' },
        { key: 'cumulativeIncome', name: '累计收益', value: 0, unit: '￥' },
        { key: 'powerStationCapacity', name: '电站容量', value: 0, unit: 'kWh' },
        { key: 'PVCumulativeGeneration', name: '光伏累计发电量', value: 0, unit: 'kWh' },
        { key: 'safeOperationDays', name: '安全运行天数', value: 0, unit: '天' },
    ],
    totalOfRealtimeAlarm: 0,
    classificationListOfRealtimeAlarm: [
        { key: 'tips', name: '恢复', value: 0 },
        { key: 'serious', name: '全部', value: 0 },
    ],
    pagesOfDevice: [],
    maxCountOfDevice: 0,
    currentPageNumberOfDevice: 1,
    dataOfPowerCurve: {
        energyStorage: [],
        powerGrid: [],
        load: [],
        PV: [],
    },
    dataOfEnergyTrendBarChart: [],
    dataOfEnergyStorageCurve: [],
}, action) => {
    switch(action.type) {
        case 'CHANGE_CURRENT_TIME':
            return Object.assign({}, state, {
                currentTime: action.payload.currentTime,
            });
        case 'INIT_LARGE_SCREEN_MONITORING':
            return Object.assign({}, state, {
                dataOfConceptMap: action.payload.dataOfConceptMap,
                mainParameterList: action.payload.mainParameterList,
                totalOfRealtimeAlarm: action.payload.totalOfRealtimeAlarm,
                classificationListOfRealtimeAlarm: action.payload.classificationListOfRealtimeAlarm,
                pagesOfDevice: action.payload.pagesOfDevice,
                maxCountOfDevice: action.payload.maxCountOfDevice,
                currentPageNumberOfDevice: action.payload.currentPageNumberOfDevice,
                dataOfPowerCurve: action.payload.dataOfPowerCurve,
                dataOfEnergyTrendBarChart: action.payload.dataOfEnergyTrendBarChart,
                dataOfEnergyStorageCurve: action.payload.dataOfEnergyStorageCurve,
            });
        case 'CHANGE_PAGE_NUMBER_OF_DEVICE':
            return Object.assign({}, state, {
                currentPageNumberOfDevice: action.payload.currentPageNumberOfDevice,
            });
        default:
            return state;
    }
}



/**
 * STS
 */
export const STS = (state = {
    basicInfoList: [],
    statusBit1List: [],
    statusBit2List: [],
    statusBit4List: [],
    dataOfRealAlarm: [],
    realParameterList: [],
    visibilityOfDrawerForHistoryData: false,
    startTimeToHistory: createCurrentDateTime('YYYY-MM-DD'),
    startMomentToHistory: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataSourceOfHistoryTable: [],
    loadingOfHistoryTable: false,
    pageNumOfHistoryTable: 1,
    totalOfHistoryTable: 0,
    visibilityOfModalSendSingle: false,
    formDataToSendSingle: {
        orderId: '',
        dataValue: '',
        previousValue: '',
    },
    sendSingleList: [],
    backupSendSingleList: [],
    sendFormConfig: {
        name: '',
        requestUrl: '',
        setUrl: '',
        attributes: [],
        backupAttributes: [],
    },
    visibilityOfModalSendForm: false,
    sn: '',
    visibilityOfModalToSPDebug: false,
    valueOfSerialPortDebugging: '',
    responseDataOfSerialPortDebugging: '',
}, action) => {
    switch(action.type) {
        case 'INITIALIZE_STS':
            return Object.assign({}, state, {
                basicInfoList: action.payload.basicInfoList,
                statusBit1List: action.payload.statusBit1List,
                statusBit2List: action.payload.statusBit2List,
                statusBit4List: action.payload.statusBit4List,
                dataOfRealAlarm: action.payload.dataOfRealAlarm,
                realParameterList: action.payload.realParameterList,
                sn: action.payload.sn,
            });
        case 'TOGGLE_HISTORY_DATA_DRAWER_OF_STS':
            return Object.assign({}, state, {
                visibilityOfDrawerForHistoryData: action.payload.visibilityOfDrawerForHistoryData,
            });
        case 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_STS':
            return Object.assign({}, state, {
                loadingOfHistoryTable: action.payload.loadingOfHistoryTable,
            });
        case 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_STS':
            return Object.assign({}, state, {
                pageNumOfHistoryTable: action.payload.pageNumOfHistoryTable,
                dataSourceOfHistoryTable: action.payload.dataSourceOfHistoryTable,
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_STS':
            return Object.assign({}, state, {
                startTimeToHistory: action.payload.startTimeToHistory,
                startMomentToHistory: action.payload.startMomentToHistory,
                totalOfHistoryTable: action.payload.totalOfHistoryTable,
                dataSourceOfHistoryTable: action.payload.dataSourceOfHistoryTable,
            });
        case 'TOGGLE_SEND_DOWN_DRAWER_OF_STS':
            return Object.assign({}, state, {
                visibilityOfModalSendSingle: action.payload.visibilityOfModalSendSingle,
            });
        case 'CHANGE_DATA_TO_ALL_SEND_DOWN_OF_STS':
            return Object.assign({}, state, {
                sendSingleList: action.payload.sendSingleList,
                backupSendSingleList: action.payload.backupSendSingleList,
                sendFormConfig: action.payload.sendFormConfig,
            });
        case 'CHANGE_DATA_TO_SEND_DOWN_OF_STS':
            return Object.assign({}, state, {
                sendSingleList: action.payload.sendSingleList,
                backupSendSingleList: action.payload.backupSendSingleList,
            });
        case 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_STS':
            return Object.assign({}, state, {
                formDataToSendSingle: action.payload.formDataToSendSingle,
            });
        case 'TOGGLE_MODAL_TO_SEND_FORM_OF_STS':
            return Object.assign({}, state, {
                visibilityOfModalSendForm: action.payload.visibilityOfModalSendForm,
                sendFormConfig: action.payload.sendFormConfig,
            });
        case 'CHANGE_DATA_TO_SEND_FORM_OF_STS':
            return Object.assign({}, state, {
                sendFormConfig: action.payload.sendFormConfig,
            });
        case 'SET_SEND_SINGLE_LIST_OF_STS':
            return Object.assign({}, state, {
                sendSingleList: action.payload.sendSingleList,
            });
        case 'TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING_IN_STS':
            return Object.assign({}, state, {
                visibilityOfModalToSPDebug: action.payload.visibilityOfModalToSPDebug,
                valueOfSerialPortDebugging: action.payload.valueOfSerialPortDebugging,
                responseDataOfSerialPortDebugging: action.payload.responseDataOfSerialPortDebugging,
            });
        case 'CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING_IN_STS':
            return Object.assign({}, state, {
                valueOfSerialPortDebugging: action.payload.valueOfSerialPortDebugging,
            });
        case 'CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING_IN_STS':
            return Object.assign({}, state, {
                responseDataOfSerialPortDebugging: action.payload.responseDataOfSerialPortDebugging,
            });
        default:
            return state;
    }
}

/**
 * GSE
 */
export const GSE = (state = {
    dataOfRunningState: [],
    dataOfElecStatistics: null,
    dataOfRealAlarm: [],
    typeCodeOfEnergyTrendChart: 0,
    typeCodeOfDatePickerInEnergyChart: 2,
    startTimeOfGSEEnergyTrendChart: createCurrentDateTime('YYYY-MM'),
    startTimeObjOfGSEEnergyTrendChart: moment(createCurrentDateTime('YYYY-MM'), 'YYYY-MM'),
    dataOfEnergyTrendChartInGSE: {
        powerIn: [],
        powerOut: [],
    },
    visibilityOfDrawerForHistoryDataInGSE: false,
    startTimeOfHistoryDataInGSE: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfHistoryDataInGSE: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    columnsOfHistoryDataTableInGSE: [],
    historyDataListOfGSE: [],
    dataSourceOfHistoryDataTableInGSE: [],
    loadingOfHistoryDataTableInGSE: false,
    pageNumOfHistoryDataTableInGSE: 1,
    visibilityOfModalToSendDownInGSE: false,
    formDataToSendDownOfGSE: {
        orderId: '',
        dataValue: ''
    },
    sendDownListOfGSE: [],
    backupSendDownListOfGSE: [],
    realParameterListOfGSE: [],
    totalOfHistoryDataTableInPCS: 0,
    visibilityOfModalToSendFormOfSpecialDistribution: false,
    formOfSpecialDistribution: {
        name: '',
        requestUrl: '',
        attributes: [],
        backupAttributes: [],
    },
    visibilityOfModalToSendControlBit: false,
    currentControlBit: '',
    bitListOfControlBit: [],
    backupBitListOfControlBit: [],
    formDataToSendDownOfControlBit: {},
    visibilityOfModalToSerialPortDebuggingInGSE: false,
    valueOfSerialPortDebugging: '',
    responseDataOfSerialPortDebugging: '',
}, action) => {
    switch(action.type) {
        case 'SET_DATA_ONLY_DISPLAYED_OF_GSE':
            return Object.assign({}, state, {
                dataOfRunningState: action.payload.dataOfRunningState,
                dataOfElecStatistics: action.payload.dataOfElecStatistics,
                dataOfRealAlarm: action.payload.dataOfRealAlarm
            });
        case 'SET_OPTION_OF_ENERGY_TREND_CHART_IN_GSE':
            return Object.assign({}, state, {
                typeCodeOfEnergyTrendChart: action.payload.typeCodeOfEnergyTrendChart,
                typeCodeOfDatePickerInEnergyChart: action.payload.typeCodeOfDatePickerInEnergyChart,
                startTimeOfGSEEnergyTrendChart: action.payload.startTimeOfGSEEnergyTrendChart,
                startTimeObjOfGSEEnergyTrendChart: action.payload.startTimeObjOfGSEEnergyTrendChart,
                dataOfEnergyTrendChartInGSE: action.payload.dataOfEnergyTrendChartInGSE
            });
        case 'SET_REAL_PARAMETER_LIST_OF_GSE':
            return Object.assign({}, state, {
                realParameterListOfGSE: action.payload.realParameterListOfGSE
            });
        case 'TOGGLE_HISTORY_DATA_DRAWER_OF_GSE':
            return Object.assign({}, state, {
                visibilityOfDrawerForHistoryDataInGSE: action.payload.visibilityOfDrawerForHistoryDataInGSE
            });
        case 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_GSE':
            return Object.assign({}, state, {
                loadingOfHistoryDataTableInGSE: action.payload.loadingOfHistoryDataTableInGSE
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_GSE':
            return Object.assign({}, state, {
                startTimeOfHistoryDataInGSE: action.payload.startTimeOfHistoryDataInGSE,
                startTimeObjOfHistoryDataInGSE: action.payload.startTimeObjOfHistoryDataInGSE,
                columnsOfHistoryDataTableInGSE: action.payload.columnsOfHistoryDataTableInGSE,
                historyDataListOfGSE: action.payload.historyDataListOfGSE,
                dataSourceOfHistoryDataTableInGSE: action.payload.dataSourceOfHistoryDataTableInGSE
            });
        case 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_GSE':
            return Object.assign({}, state, {
                pageNumOfHistoryDataTableInGSE: action.payload.pageNumOfHistoryDataTableInGSE,
                dataSourceOfHistoryDataTableInGSE: action.payload.dataSourceOfHistoryDataTableInGSE
            });
        case 'TOGGLE_SEND_DOWN_DRAWER_OF_GSE':
            return Object.assign({}, state, {
                visibilityOfModalToSendDownInGSE: action.payload.visibilityOfModalToSendDownInGSE
            });
        case 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_GSE':
            return Object.assign({}, state, {
                formDataToSendDownOfGSE: action.payload.formDataToSendDownOfGSE
            });
        case 'CHANGE_DATA_TO_SEND_DOWN_OF_GSE':
            return Object.assign({}, state, {
                sendDownListOfGSE: action.payload.sendDownListOfGSE,
                backupSendDownListOfGSE: action.payload.backupSendDownListOfGSE,
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_GSE_NEW':
            return Object.assign({}, state, {
                startTimeOfHistoryDataInGSE: action.payload.startTimeOfHistoryDataInGSE,
                startTimeObjOfHistoryDataInGSE: action.payload.startTimeObjOfHistoryDataInGSE,
                totalOfHistoryDataTableInPCS: action.payload.totalOfHistoryDataTableInPCS,
                columnsOfHistoryDataTableInGSE: action.payload.columnsOfHistoryDataTableInGSE,
                historyDataListOfGSE: action.payload.historyDataListOfGSE,
                dataSourceOfHistoryDataTableInGSE: action.payload.dataSourceOfHistoryDataTableInGSE
            });
        case 'SET_REAL_ALARM_OF_PCS':
            return Object.assign({}, state, {
                dataOfRealAlarm: action.payload.dataOfRealAlarm,
            });
        case 'CHANGE_DATA_TO_ALL_SEND_DOWN_OF_GSE':
            return Object.assign({}, state, {
                sendDownListOfGSE: action.payload.sendDownListOfGSE,
                backupSendDownListOfGSE: action.payload.backupSendDownListOfGSE,
                formOfSpecialDistribution: action.payload.formOfSpecialDistribution,
                bitListOfControlBit: action.payload.bitListOfControlBit,
                backupBitListOfControlBit: action.payload.backupBitListOfControlBit,
            });
        case 'TOGGLE_MODAL_TO_SEND_FORM_OF_SD':
            return Object.assign({}, state, {
                visibilityOfModalToSendFormOfSpecialDistribution: action.payload.visibilityOfModalToSendFormOfSpecialDistribution,
                formOfSpecialDistribution: action.payload.formOfSpecialDistribution,
            });
        case 'CHANGE_DATA_TO_SEND_FORM_OF_SD':
            return Object.assign({}, state, {
                formOfSpecialDistribution: action.payload.formOfSpecialDistribution,
            });
        case 'TOGGLE_MODAL_TO_SEND_CONTROL_BIT':
            return Object.assign({}, state, {
                visibilityOfModalToSendControlBit: action.payload.visibilityOfModalToSendControlBit,
                currentControlBit: action.payload.currentControlBit,
                bitListOfControlBit: action.payload.bitListOfControlBit,
            });
        case 'CHANGE_CURRENT_CONTROL_BIT':
            return Object.assign({}, state, {
                currentControlBit: action.payload.currentControlBit,
                bitListOfControlBit: action.payload.bitListOfControlBit,
            });
        case 'CHANGE_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS':
            return Object.assign({}, state, {
                bitListOfControlBit: action.payload.bitListOfControlBit,
            });
        case 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS':
            return Object.assign({}, state, {
                formDataToSendDownOfControlBit: action.payload.formDataToSendDownOfControlBit,
            });
        case 'TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING':
            return Object.assign({}, state, {
                visibilityOfModalToSerialPortDebuggingInGSE: action.payload.visibilityOfModalToSerialPortDebuggingInGSE,
                valueOfSerialPortDebugging: action.payload.valueOfSerialPortDebugging,
                responseDataOfSerialPortDebugging: action.payload.responseDataOfSerialPortDebugging,
            });
        case 'CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING':
            return Object.assign({}, state, {
                valueOfSerialPortDebugging: action.payload.valueOfSerialPortDebugging,
            });
        case 'CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING':
            return Object.assign({}, state, {
                responseDataOfSerialPortDebugging: action.payload.responseDataOfSerialPortDebugging,
            });
        default:
            return state;
    }
}

/**
 * Battery
 */
export const battery = (state = {
    branchesOfBattery: [],
    branchToControl: '',
    startTimeOfBatterySOCCurve: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfBatterySOCCurve: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfBatterySOCCurve: [],
    dataOfRealAlarm: [],
    startTimeOfBatteryEnergyTrendChart: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfBatteryEnergyTrendChart: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfBatteryEnergyTrendChart: {
        powerOut: [],
        powerIn: [],
    },
    MBMVList: [],
    sonIdUsedToQueryHistoryDataOfBatteryBox: '',
    visibilityOfDrawerForHistoryDataInBattery: false,
    typeCodeOfHistoryDataInBattery: 0, // 1 -> Battery；2 -> Slave；3 -> Battery Box；0 -> Close
    startTimeOfHistoryTableDrawerInBattery: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfHistoryDataInBattery: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    columnsOfHistoryDataTableInBattery: [],
    dataOfHistoryTableInBatteryDrawer: [],
    dataSourceOfHistoryDataTableInBattery: [],
    loadingOfHistoryDataTableInBattery: false,
    pageNumOfHistoryTableInBattery: 1,
    totalOfHistoryDataTableInBattery: 0,
    realParameterListOfBattery: [],
    systemParametersOfBatteryTBD: [],
    visibilityOfModalToSendDownInBattery: false,
    sendDownListOfBattery: [],
    formDataToSendDownOfBattery: {
        orderId: '',
        dataValue: ''
    },
    slaveIdUsedToControl: '',
}, action) => {
    switch(action.type) {
        case 'SET_BRANCHES_OF_BATTERY':
            return Object.assign({}, state, {
                branchesOfBattery: action.payload.branchesOfBattery,
                branchToControl: action.payload.branchToControl,
            });
        case 'SET_DATA_ONLY_DISPLAYED_OF_BATTERY':
            return Object.assign({}, state, {
                dataOfRealAlarm: action.payload.dataOfRealAlarm,
                MBMVList: action.payload.MBMVList
            });
        case 'SET_DATA_OF_BATTERY_SOC_CURVE':
            return Object.assign({}, state, {
                startTimeOfBatterySOCCurve: action.payload.startTimeOfBatterySOCCurve,
                startTimeObjOfBatterySOCCurve: action.payload.startTimeObjOfBatterySOCCurve,
                dataOfBatterySOCCurve: action.payload.dataOfBatterySOCCurve
            });
        case 'SET_DATA_OF_BATTERY_ENERGY_TREND_CHART':
            return Object.assign({}, state, {
                startTimeOfBatteryEnergyTrendChart: action.payload.startTimeOfBatteryEnergyTrendChart,
                startTimeObjOfBatteryEnergyTrendChart: action.payload.startTimeObjOfBatteryEnergyTrendChart,
                dataOfBatteryEnergyTrendChart: action.payload.dataOfBatteryEnergyTrendChart,
            });
        case 'SET_REAL_PARAMETER_LIST_OF_BATTERY':
            return Object.assign({}, state, {
                realParameterListOfBattery: action.payload.realParameterListOfBattery
            });
        case 'TOGGLE_HISTORY_DATA_DRAWER_OF_BATTERY':
            return Object.assign({}, state, {
                visibilityOfDrawerForHistoryDataInBattery: action.payload.visibilityOfDrawerForHistoryDataInBattery,
                typeCodeOfHistoryDataInBattery: action.payload.typeCodeOfHistoryDataInBattery,
                pageNumOfHistoryTableInBattery: action.payload.pageNumOfHistoryTableInBattery
            });
        case 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_BATTERY':
            return Object.assign({}, state, {
                loadingOfHistoryDataTableInBattery: action.payload.loadingOfHistoryDataTableInBattery
            });
        case 'CHANGE_SON_ID_TO_QUERY_HISTORY_DATA_OF_BATTERY_BOX':
            return Object.assign({}, state, {
                visibilityOfDrawerForHistoryDataInBattery: action.payload.visibilityOfDrawerForHistoryDataInBattery,
                typeCodeOfHistoryDataInBattery: action.payload.typeCodeOfHistoryDataInBattery,
                sonIdUsedToQueryHistoryDataOfBatteryBox: action.payload.sonIdUsedToQueryHistoryDataOfBatteryBox,
                pageNumOfHistoryTableInBattery: action.payload.pageNumOfHistoryTableInBattery
            });
        case 'CHANGE_START_TIME_OF_HISTORY_TABLE_IN_BATTERY_DRAWER':
            return Object.assign({}, state, {
                startTimeOfHistoryTableDrawerInBattery: action.payload.startTimeOfHistoryTableDrawerInBattery,
                startTimeObjOfHistoryDataInBattery: action.payload.startTimeObjOfHistoryDataInBattery,
                pageNumOfHistoryTableInBattery: action.payload.pageNumOfHistoryTableInBattery,
            });
        case 'GENERATE_HISTORY_TABLE_OF_DRAWER_IN_BATTERY':
            return Object.assign({}, state, {
                columnsOfHistoryDataTableInBattery: action.payload.columnsOfHistoryDataTableInBattery,
                dataOfHistoryTableInBatteryDrawer: action.payload.dataOfHistoryTableInBatteryDrawer,
                dataSourceOfHistoryDataTableInBattery: action.payload.dataSourceOfHistoryDataTableInBattery,
            });
        case 'CHANGE_PAGE_NUM_OF_HISTORY_TABLE_IN_BATTERY_DRAWER':
            return Object.assign({}, state, {
                pageNumOfHistoryTableInBattery: action.payload.pageNumOfHistoryTableInBattery,
                dataSourceOfHistoryDataTableInBattery: action.payload.dataSourceOfHistoryDataTableInBattery
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_DRAWER_IN_BATTERY':
            return Object.assign({}, state, {
                pageNumOfHistoryTableInBattery: action.payload.pageNumOfHistoryTableInBattery,
                dataSourceOfHistoryDataTableInBattery: action.payload.dataSourceOfHistoryDataTableInBattery
            });
        case 'SET_HISTORY_TABLE_OF_DRAWER_IN_BATTERY':
            return Object.assign({}, state, {
                totalOfHistoryDataTableInBattery: action.payload.totalOfHistoryDataTableInBattery,
                columnsOfHistoryDataTableInBattery: action.payload.columnsOfHistoryDataTableInBattery,
                dataOfHistoryTableInBatteryDrawer: action.payload.dataOfHistoryTableInBatteryDrawer,
                dataSourceOfHistoryDataTableInBattery: action.payload.dataSourceOfHistoryDataTableInBattery,
            });
        case 'SET_SYSTEM_PARAMETERS_OF_BATTERY_TBD':
            return Object.assign({}, state, {
                systemParametersOfBatteryTBD: action.payload.systemParametersOfBatteryTBD,
            });
        case 'SET_REAL_ALARM_OF_BATTERY':
            return Object.assign({}, state, {
                dataOfRealAlarm: action.payload.dataOfRealAlarm,
            });
        case 'TOGGLE_MODAL_TO_SEND_DOWN_IN_BATTERY':
            return Object.assign({}, state, {
                visibilityOfModalToSendDownInBattery: action.payload.visibilityOfModalToSendDownInBattery,
            });
        case 'SET_SEND_DOWN_LIST_OF_BATTERY':
            return Object.assign({}, state, {
                sendDownListOfBattery: action.payload.sendDownListOfBattery,
            });
        case 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_BATTERY':
            return Object.assign({}, state, {
                formDataToSendDownOfBattery: action.payload.formDataToSendDownOfBattery,
            });
        case 'CHANGE_SLAVE_ID_USED_TO_CONTROL':
            return Object.assign({}, state, {
                slaveIdUsedToControl: action.payload.slaveIdUsedToControl,
            });
        default:
            return state;
    }
}

/**
 * Energy Storage Tank
 */
export const energyStorageTank = (state = {
    realDataListOfEnergy: [],
    batteryRooms: [],
    PCSRooms: [],
    statusObjList: [],
}, action) => {
    switch(action.type) {
        case 'SET_DATA_ONLY_DISPLAYED_OF_ENERGY':
            return Object.assign({}, state, {
                realDataListOfEnergy: action.payload.realDataListOfEnergy
            });
        case 'INIT_DATA_OF_ENERGY':
            return Object.assign({}, state, {
                batteryRooms: action.payload.batteryRooms,
                PCSRooms: action.payload.PCSRooms,
                statusObjList: action.payload.statusObjList,
            });
        default:
            return state;
    }
}

/**
 * KDC
 */
export const KDC = (state = {
    dataOfRunningStateInKDC: [],
    dataOfElecStatisticsInKDC: [],
    dataOfRealAlarmInKDC: {
        classificationList: [
            {
                name: '恢复',
                value: 0,
                color: '#1890ff'
            },
            {
                name: '存在',
                value: 0,
                color: '#f5222d'
            }
        ],
        list: []
    },
    typeCodeOfDatePickerInEnergyTrendBar: 2,
    startTimeOfEnergyTrendBarInKDC: createCurrentDateTime('YYYY-MM'),
    startTimeObjOfEnergyTrendBarInKDC: moment(createCurrentDateTime('YYYY-MM'), 'YYYY-MM'),
    dataOfEnergyTrendBarInKDC: {
        powerIn: [],
        powerOut: []
    },
    startTimeOfInputCurveInKDC: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfInputCurveInKDC: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfInputCurveInKDC: {
        currentIn: [],
        powerIn: []
    },
    startTimeOfOutputCurveInKDC: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfOutputCurveInKDC: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfOutputCurveInKDC: {
        currentOut: [],
        powerOut: []
    },
    visibilityOfDrawerForHistoryDataInKDC: false,
    startTimeOfHistoryDataInKDC: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfHistoryDataInKDC: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    columnsOfHistoryDataTableInKDC: [],
    historyDataListOfKDC: [],
    dataSourceOfHistoryDataTableInKDC: [],
    loadingOfHistoryDataTableInKDC: false,
    pageNumOfHistoryDataTableInKDC: 1,
    realParameterListOfKDC: [],
    columnsOfSlaveDataTableInKDC: [],
    dataSourceOfSlaveDataTableInKDC: [],
    visibilityOfModalToSendDownInKDC: false,
    sendDownListOfKDC: [
        {
            key: '0411',
            name: '直流变换器工作模式',
            placeholderKey: '请选择',
            valueType: 'number',
            operationMode: 'Select',
            valueList: [
                {
                    name: '恒压',
                    value: 1
                },
                {
                    name: '恒流',
                    value: 2
                },
                {
                    name: 'MPPT',
                    value: 3
                }
            ]
        },
        {
            key: '0412',
            name: '拓扑方式',
            valueType: 'number',
            operationMode: 'Select',
            placeholderKey: '请选择',
            valueList: [
                {
                    name: 'BUCK',
                    value: 1
                },
                {
                    name: 'BOOST',
                    value: 2
                }
            ]
        },
        {
            key: '0413',
            name: '恒压输出电压',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: 'placeholderOfInputNumberInPCS',
            min: 0.1,
            max: 6553.4,
            precision: 1
        },
        {
            key: '0414',
            name: '恒流输出电流',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: 'placeholderOfInputNumberInPCS',
            min: 0.1,
            max: 6553.4,
            precision: 1
        },
        {
            key: '0415',
            name: '电池位置',
            valueType: 'number',
            operationMode: 'Select',
            placeholderKey: '请选择',
            valueList: [
                {
                    name: '高压侧',
                    value: 1
                },
                {
                    name: '低压侧',
                    value: 2
                }
            ]
        },
        {
            key: '0416',
            name: '开关机',
            valueType: 'string',
            operationMode: 'Select',
            placeholderKey: '请选择',
            valueList: [
                {
                    name: '开机',
                    value: '5555'
                },
                {
                    name: '关机',
                    value: 'aaaa'
                }
            ]
        }
    ],
    backupSendDownListOfKDC: [],
    formDataToSendDownOfKDC: {
        orderId: '',
        dataValue: ''
    }
}, action) => {
    switch(action.type) {
        case 'SET_DATA_ONLY_DISPLAYED_OF_KDC':
            return Object.assign({}, state, {
                dataOfRunningStateInKDC: action.payload.dataOfRunningStateInKDC,
                dataOfElecStatisticsInKDC: action.payload.dataOfElecStatisticsInKDC,
                dataOfRealAlarmInKDC: action.payload.dataOfRealAlarmInKDC
            });
        case 'SET_DATA_OF_ENERGY_TREND_BAR_IN_KDC':
            return Object.assign({}, state, {
                typeCodeOfDatePickerInEnergyTrendBar: action.payload.typeCodeOfDatePickerInEnergyTrendBar,
                startTimeOfEnergyTrendBarInKDC: action.payload.startTimeOfEnergyTrendBarInKDC,
                startTimeObjOfEnergyTrendBarInKDC: action.payload.startTimeObjOfEnergyTrendBarInKDC,
                dataOfEnergyTrendBarInKDC: action.payload.dataOfEnergyTrendBarInKDC
            });
        case 'SET_DATA_OF_INPUT_CURVE_IN_KDC':
            return Object.assign({}, state, {
                startTimeOfInputCurveInKDC: action.payload.startTimeOfInputCurveInKDC,
                startTimeObjOfInputCurveInKDC: action.payload.startTimeObjOfInputCurveInKDC,
                dataOfInputCurveInKDC: action.payload.dataOfInputCurveInKDC
            });
        case 'SET_DATA_OF_OUTPUT_CURVE_IN_KDC':
            return Object.assign({}, state, {
                startTimeOfOutputCurveInKDC: action.payload.startTimeOfOutputCurveInKDC,
                startTimeObjOfOutputCurveInKDC: action.payload.startTimeObjOfOutputCurveInKDC,
                dataOfOutputCurveInKDC: action.payload.dataOfOutputCurveInKDC
            });
        case 'SET_REAL_PARAMETER_LIST_OF_KDC':
            return Object.assign({}, state, {
                realParameterListOfKDC: action.payload.realParameterListOfKDC
            });
        case 'TOGGLE_HISTORY_DATA_DRAWER_OF_KDC':
            return Object.assign({}, state, {
                visibilityOfDrawerForHistoryDataInKDC: action.payload.visibilityOfDrawerForHistoryDataInKDC
            });
        case 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_KDC':
            return Object.assign({}, state, {
                loadingOfHistoryDataTableInKDC: action.payload.loadingOfHistoryDataTableInKDC
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_KDC':
            return Object.assign({}, state, {
                startTimeOfHistoryDataInKDC: action.payload.startTimeOfHistoryDataInKDC,
                startTimeObjOfHistoryDataInKDC: action.payload.startTimeObjOfHistoryDataInKDC,
                columnsOfHistoryDataTableInKDC: action.payload.columnsOfHistoryDataTableInKDC,
                historyDataListOfKDC: action.payload.historyDataListOfKDC,
                dataSourceOfHistoryDataTableInKDC: action.payload.dataSourceOfHistoryDataTableInKDC
            });
        case 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_KDC':
            return Object.assign({}, state, {
                pageNumOfHistoryDataTableInKDC: action.payload.pageNumOfHistoryDataTableInKDC,
                dataSourceOfHistoryDataTableInKDC: action.payload.dataSourceOfHistoryDataTableInKDC
            });
        case 'SET_SLAVE_DATA_OF_KDC':
            return Object.assign({}, state, {
                columnsOfSlaveDataTableInKDC: action.payload.columnsOfSlaveDataTableInKDC,
                dataSourceOfSlaveDataTableInKDC: action.payload.dataSourceOfSlaveDataTableInKDC
            });
        case 'TOGGLE_SEND_DOWN_DRAWER_OF_KDC':
            return Object.assign({}, state, {
                visibilityOfModalToSendDownInKDC: action.payload.visibilityOfModalToSendDownInKDC
            });
        case 'CHANGE_DATA_TO_SEND_DOWN_OF_KDC':
            return Object.assign({}, state, {
                sendDownListOfKDC: action.payload.sendDownListOfKDC,
                backupSendDownListOfKDC: action.payload.backupSendDownListOfKDC,
            });
        case 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_KDC':
            return Object.assign({}, state, {
                formDataToSendDownOfKDC: action.payload.formDataToSendDownOfKDC
            });
        default:
            return state;
    }
}

/**
 * Combiner Box
 */
export const combinerBox = (state = {
    realParameterListOfCombinerBox: [],
    circuitBreakerState: '',
    dataOfRealAlarm: {
        total: 0,
        classificationList: [],
        list: [],
    },
    startTimeOfBranchCurrentCurveInCombinerBox: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfBranchCurrentCurveInCombinerBox: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfBranchCurrentCurveInCombinerBox: {}
}, action) => {
    switch(action.type) {
        case 'SET_REAL_PARAMETER_LIST_OF_COMBINER_BOX':
            return Object.assign({}, state, {
                realParameterListOfCombinerBox: action.payload.realParameterListOfCombinerBox,
                circuitBreakerState: action.payload.circuitBreakerState,
            });
        case 'SET_REAL_ALARM_OF_COMBINER_BOX':
            return Object.assign({}, state, {
                dataOfRealAlarm: action.payload.dataOfRealAlarm,
            });
        case 'SET_DATA_OF_BRANCH_CURRENT_CURVE_IN_COMBINER_BOX':
            return Object.assign({}, state, {
                startTimeOfBranchCurrentCurveInCombinerBox: action.payload.startTimeOfBranchCurrentCurveInCombinerBox,
                startTimeObjOfBranchCurrentCurveInCombinerBox: action.payload.startTimeObjOfBranchCurrentCurveInCombinerBox,
                dataOfBranchCurrentCurveInCombinerBox: action.payload.dataOfBranchCurrentCurveInCombinerBox
            });
        default:
            return state;
    }
}

/**
 * Charging Pile
 */
export const chargingPile = (state = {
    chargingPileList: []
}, action) => {
    switch(action.type) {
        case 'SET_DATA_OF_CHARGING_PILE_LIST':
            return Object.assign({}, state, {
                chargingPileList: action.payload.chargingPileList
            });
        default:
            return state;
    }
}

/**
 * PV Inverter
 */
const initialDataOfPVInverter = {
    realTimePower: 74,
    mininumRealTimePower: 0,
    maxinumRealTimePower: 300,
    mainParameterList: [
        {
            title: '发电量',
            icon: iconOfGenerationPowerInPVInverter,
            name: "今日发电量",
            value: 180,
            unit: 'kWh',
            additionalData: {
                type: 'total',
                name: '总发电量',
                value: 1230,
                unit: 'kWh'
            }
        },
        {
            title: '收益',
            icon: iconOfIncomeInPVInverter,
            name: "今日收益",
            value: 133,
            unit: '￥',
            additionalData: {
                type: 'total',
                name: '总收益',
                value: 13000,
                unit: '￥'
            }
        },
        {
            title: '告警',
            icon: iconOfAlarmInPVInverter,
            name: "实时告警",
            value: 34,
            unit: '',
            additionalData: {
                type: 'list',
                used: 'classify',
                data: [
                    {
                        name: '存在',
                        value: 43,
                        unit: '',
                        style: {
                            color: '#cf1322'
                        }
                    },
                    {
                        name: '恢复',
                        value: 54,
                        unit: '',
                        style: {
                            color: '#389e0d'
                        }
                    },
                    {
                        name: '全部',
                        value: 97,
                        unit: '',
                        style: {
                            color: '#1890ff'
                        }
                    }
                ]
            }
        }
    ],
    typeCodeOfDatePickerOfGenerationPowerChart: 1,
    startTimeObjOfGenerationPowerChart: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfGenerationPowerChart: [],
    deviceList: [],
    visibilityOfModalToDataStatistics: false,
    SNOfStatisticsTabSelected: 0,
    typeCodeOfDatePickerOfGenerationPowerChartOfDevice: 1,
    startTimeObjOfGenerationPowerChartOfDevice: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfGenerationPowerChartOfDevice: [],
    startTimeObjOfPVCurveOfDevice: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    dataOfPVCurveOfDevice: {},
    startTimeObjOfHistoryDataTableOfDevice: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    columnsOfHistoryDataTableOfDevice: [],
    dataSourceOfHistoryDataTableOfDevice: [],
    loadingOfHistoryDataTableOfDevice: false,
    pageNumOfHistoryDataTableOfDevice: 1,
    historyDataListOfDevice: [],
    visibilityOfModalToSendDownOfDevice: false,
    sendDownListOfDevice: [
        {
            key: '0b12',
            name: '远程关机',
            placeholderKey: '请选择',
            valueType: 'string',
            operationMode: 'Select',
            valueList: [
                {
                    name: '关机',
                    value: '0001'
                }
            ]
        },
        {
            key: '0b13',
            name: '解除远程关机',
            placeholderKey: '请选择',
            valueType: 'string',
            operationMode: 'Select',
            valueList: [
                {
                    name: '解除',
                    value: '0001'
                }
            ]
        },
        {
            key: '0b15',
            name: '有功功率（百分比）',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: '',
            min: 0,
            max: 100,
            precision: 0
        },
        {
            key: '0b16',
            name: '无功功率',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: '',
            min: -60,
            max: 60,
            precision: 0
        },
        {
            key: '0b17',
            name: '无功控制方式',
            placeholderKey: '请选择',
            valueType: 'number',
            operationMode: 'Select',
            valueList: [
                {
                    name: '按照功因控制',
                    value: 0
                },
                {
                    name: '按照无功功率控制',
                    value: 1
                },
                {
                    name: '按照 QV 曲线控制',
                    value: 2
                },
                {
                    name: '按照 PF 曲线控制',
                    value: 3
                }
            ]
        },
        {
            key: '0b18',
            name: '过频降噪禁止',
            placeholderKey: '请选择',
            valueType: 'number',
            operationMode: 'Select',
            valueList: [
                {
                    name: '使能',
                    value: 0
                },
                {
                    name: '禁止',
                    value: 1
                }
            ]
        },
        {
            key: '0b19',
            name: '过频降噪阈值',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: '',
            min: 5020,
            max: 5500,
            precision: 0
        },
        {
            key: '0b1a',
            name: '过频降噪有功变化率',
            value: 4,
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: '',
            min: 0,
            max: 20,
            precision: 0
        },
        {
            key: '0b1b',
            name: '有功功率',
            value: '',
            valueType: 'number',
            operationMode: 'InputNumber',
            placeholderKey: '',
            min: 0,
            max: 250,
            precision: 0
        }
    ],
    backupSendDownListOfDevice: [],
    formDataToSendDownOfDevice: {
        orderId: '',
        dataValue: '',
        previousValue: '',
    }
};

export const PVInverter = (state = initialDataOfPVInverter, action) => {
    switch(action.type) {
        case 'QUERY_BASIC_DATA_IN_PV_INVERTER':
            return Object.assign({}, state, {
                realTimePower: action.payload.realTimePower,
                mainParameterList: action.payload.mainParameterList
            });
        case 'QUERY_DATA_OF_GENERATION_POWER_CHART_IN_PV_INVERTER':
            return Object.assign({}, state, {
                dataOfGenerationPowerChart: action.payload.dataOfGenerationPowerChart
            });
        case 'QUERY_DEVICE_LIST_IN_PV_INVERTER':
            return Object.assign({}, state, {
                deviceList: action.payload.deviceList
            });
        case 'CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_IN_PV_INVERTER':
            return Object.assign({}, state, {
                typeCodeOfDatePickerOfGenerationPowerChart: action.payload.typeCodeOfDatePickerOfGenerationPowerChart
            });
        case 'CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_IN_PV_INVERTER':
            return Object.assign({}, state, {
                startTimeObjOfGenerationPowerChart: action.payload.startTimeObjOfGenerationPowerChart
            });
        case 'TOGGLE_MODAL_TO_DATA_STATISTICS_IN_PV_INVERTER':
            return Object.assign({}, state, {
                visibilityOfModalToDataStatistics: action.payload.visibilityOfModalToDataStatistics
            });
        case 'CHANGE_SN_OF_STATISTICS_TAB_SELECTED_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                SNOfStatisticsTabSelected: action.payload.SNOfStatisticsTabSelected
            });
        case 'QUERY_DATA_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                dataOfGenerationPowerChartOfDevice: action.payload.dataOfGenerationPowerChartOfDevice
            });
        case 'CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                typeCodeOfDatePickerOfGenerationPowerChartOfDevice: action.payload.typeCodeOfDatePickerOfGenerationPowerChartOfDevice
            });
        case 'CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                startTimeObjOfGenerationPowerChartOfDevice: action.payload.startTimeObjOfGenerationPowerChartOfDevice
            });
        case 'QUERY_DATA_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                dataOfPVCurveOfDevice: action.payload.dataOfPVCurveOfDevice
            });
        case 'CHANGE_START_TIME_OBJ_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                startTimeObjOfPVCurveOfDevice: action.payload.startTimeObjOfPVCurveOfDevice
            });
        case 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                loadingOfHistoryDataTableOfDevice: action.payload.loadingOfHistoryDataTableOfDevice
            });
        case 'GENERATE_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                columnsOfHistoryDataTableOfDevice: action.payload.columnsOfHistoryDataTableOfDevice,
                historyDataListOfDevice: action.payload.historyDataListOfDevice,
                dataSourceOfHistoryDataTableOfDevice: action.payload.dataSourceOfHistoryDataTableOfDevice
            });
        case 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                pageNumOfHistoryDataTableOfDevice: action.payload.pageNumOfHistoryDataTableOfDevice,
                dataSourceOfHistoryDataTableOfDevice: action.payload.dataSourceOfHistoryDataTableOfDevice
            });
        case 'CHANGE_START_TIME_OBJ_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                startTimeObjOfHistoryDataTableOfDevice: action.payload.startTimeObjOfHistoryDataTableOfDevice
            });
        case 'TOGGLE_MODAL_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                visibilityOfModalToSendDownOfDevice: action.payload.visibilityOfModalToSendDownOfDevice
            });
        case 'SET_DATA_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER':
            return Object.assign({}, state, {
                sendDownListOfDevice: action.payload.sendDownListOfDevice,
                backupSendDownListOfDevice: action.payload.backupSendDownListOfDevice,
            });
        case 'SET_FORM_DATA_TO_SEND_DOWN_OF_Device_IN_PV_INVERTER':
            return Object.assign({}, state, {
                formDataToSendDownOfDevice: action.payload.formDataToSendDownOfDevice
            });
        default:
            return state;
    }
}

/**
 * Electric meter
 */
export const electricMeter = (state = {
    deviceList: [],
    visibilityOfDrawerToHistoryData: false,
    startTimeMomentOfHistoryData: moment(),
    columnsOfHistoryDataTable: [],
    dataSourceOfHistoryDataTable: [],
    loadingOfHistoryDataTable: false,
    pageNumOfHistoryDataTable: 1,
    pageSizeOfHistoryDataTable: 10,
    totalOfHistoryDataTable: 0,
}, action) => {
    switch(action.type) {
        case 'QUERY_DEVICE_LIST_IN_ELECTRIC_METER':
            return Object.assign({}, state, {
                deviceList: action.payload.deviceList
            });
        case 'TOGGLE_DRAWER_TO_HISTORY_DATA_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                visibilityOfDrawerToHistoryData: action.payload.visibilityOfDrawerToHistoryData,
            });
        case 'RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                startTimeMomentOfHistoryData: action.payload.startTimeMomentOfHistoryData,
                columnsOfHistoryDataTable: action.payload.columnsOfHistoryDataTable,
                dataSourceOfHistoryDataTable: action.payload.dataSourceOfHistoryDataTable,
                pageNumOfHistoryDataTable: action.payload.pageNumOfHistoryDataTable,
                pageSizeOfHistoryDataTable: action.payload.pageSizeOfHistoryDataTable,
                totalOfHistoryDataTable: action.payload.totalOfHistoryDataTable,
            });
        case 'CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                startTimeMomentOfHistoryData: action.payload.startTimeMomentOfHistoryData,
            });
        case 'SET_LOADING_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                loadingOfHistoryDataTable: action.payload.loadingOfHistoryDataTable,
            });
        case 'SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                totalOfHistoryDataTable: action.payload.totalOfHistoryDataTable,
                columnsOfHistoryDataTable: action.payload.columnsOfHistoryDataTable,
                dataSourceOfHistoryDataTable: action.payload.dataSourceOfHistoryDataTable,
            });
        case 'CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER':
            return Object.assign({}, state, {
                pageNumOfHistoryDataTable: action.payload.pageNumOfHistoryDataTable,
            });
        default:
            return state;
    }
}

/**
 * Camera
 */
const initialDataOfCamera = {
    cameraList: [],
    dateListOfPlayback: [
        { date: null, dateString: '' },
        { date: null, dateString: '' },
    ],
    timeListOfPlayback: [
        { times: [null, null], timeStrings: ['', ''] },
        { times: [null, null], timeStrings: ['', ''] },
    ],
}

export const camera = (state = initialDataOfCamera, action) => {
    switch(action.type) {
        case 'INIT_DATA_OF_CAMERA':
            return Object.assign({}, state, {
                cameraList: action.payload.cameraList,
            });
        case 'CHANGE_DATE_LIST_OF_PLAYBACK':
            return Object.assign({}, state, {
                dateListOfPlayback: action.payload.dateListOfPlayback,
            });
        case 'CHANGE_TIME_LIST_OF_PLAYBACK':
            return Object.assign({}, state, {
                timeListOfPlayback: action.payload.timeListOfPlayback,
            });
        case 'VIEW_PLAYBACK':
            return Object.assign({}, state, {
                dateListOfPlayback: action.payload.dateListOfPlayback,
                timeListOfPlayback: action.payload.timeListOfPlayback,
                cameraList: action.payload.cameraList,
            });
        default:
            return state;
    }
}

/**
 * Aircondition
 */
export const aircondition = (state = {
    deviceList: [],
    visibilityOfDrawerToHistoryData: false,
    startTimeMomentOfHistoryData: moment(),
    columnsOfHistoryDataTable: [],
    dataSourceOfHistoryDataTable: [],
    loadingOfHistoryDataTable: false,
    pageNumOfHistoryDataTable: 1,
    pageSizeOfHistoryDataTable: 10,
    totalOfHistoryDataTable: 0,
    visibilityOfModalToSendDownInAircondition: false,
    sendDownList: [],
}, action) => {
    switch(action.type) {
        case 'QUERY_DEVICE_LIST_IN_AIRCONDITION':
            return Object.assign({}, state, {
                deviceList: action.payload.deviceList
            });
        case 'TOGGLE_DRAWER_TO_HISTORY_DATA_IN_AIRCONDITION':
            return Object.assign({}, state, {
                visibilityOfDrawerToHistoryData: action.payload.visibilityOfDrawerToHistoryData,
            });
        case 'RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_AIRCONDITION':
            return Object.assign({}, state, {
                startTimeMomentOfHistoryData: action.payload.startTimeMomentOfHistoryData,
                columnsOfHistoryDataTable: action.payload.columnsOfHistoryDataTable,
                dataSourceOfHistoryDataTable: action.payload.dataSourceOfHistoryDataTable,
                pageNumOfHistoryDataTable: action.payload.pageNumOfHistoryDataTable,
                pageSizeOfHistoryDataTable: action.payload.pageSizeOfHistoryDataTable,
                totalOfHistoryDataTable: action.payload.totalOfHistoryDataTable,
            });
        case 'CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_AIRCONDITION':
            return Object.assign({}, state, {
                startTimeMomentOfHistoryData: action.payload.startTimeMomentOfHistoryData,
            });
        case 'SET_LOADING_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION':
            return Object.assign({}, state, {
                loadingOfHistoryDataTable: action.payload.loadingOfHistoryDataTable,
            });
        case 'SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION':
            return Object.assign({}, state, {
                totalOfHistoryDataTable: action.payload.totalOfHistoryDataTable,
                columnsOfHistoryDataTable: action.payload.columnsOfHistoryDataTable,
                dataSourceOfHistoryDataTable: action.payload.dataSourceOfHistoryDataTable,
            });
        case 'CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION':
            return Object.assign({}, state, {
                pageNumOfHistoryDataTable: action.payload.pageNumOfHistoryDataTable,
            });
        case 'TOGGLE_MODAL_TO_SEND_DOWN_IN_AIRCONDITION':
            return Object.assign({}, state, {
                visibilityOfModalToSendDownInAircondition: action.payload.visibilityOfModalToSendDownInAircondition,
                sendDownList: action.payload.sendDownList,
            });
        case 'SET_SEND_DOWN_LIST_IN_AIRCONDITION':
            return Object.assign({}, state, {
                sendDownList: action.payload.sendDownList,
            });
        default:
            return state;
    }
}

/**
 * Energy Statistics
 */
export const energyStatistics = (state = {
    typeCodeOfDatePickerInEnergyStatistics: 1,
    startTimeOfEnergyStatistics: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjOfEnergyStatistics: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    hasPowerDateList: [],
    dataOfEnergyStatisticsBarChart: {
        dayOutList: [],
        dayPvInList: [],
        dayInList: []
    },
    energyStatisticsList: []
}, action) => {
    switch(action.type) {
        case 'SET_DATA_OF_ENERGY_STATISTICS_BAR_CHART':
            return Object.assign({}, state, {
                dataOfEnergyStatisticsBarChart: action.payload.dataOfEnergyStatisticsBarChart
            });
        case 'SET_DATA_of_ENERGY_STATISTICS_LIST':
            return Object.assign({}, state, {
                energyStatisticsList: action.payload.energyStatisticsList
            });
        case 'SET_HAS_POWER_DATE_LIST':
            return Object.assign({}, state, {
                hasPowerDateList: action.payload.hasPowerDateList
            });
        case 'CHANGE_TYPE_CODE_OF_DATA_PICKER_IN_ENERGY_STATISTICS':
            return Object.assign({}, state, {
                typeCodeOfDatePickerInEnergyStatistics: action.payload.typeCodeOfDatePickerInEnergyStatistics,
                startTimeOfEnergyStatistics: action.payload.startTimeOfEnergyStatistics,
                startTimeObjOfEnergyStatistics: action.payload.startTimeObjOfEnergyStatistics
            });
        case 'CHANGE_START_TIME_OF_ENERGY_STATISTICS':
            return Object.assign({}, state, {
                startTimeOfEnergyStatistics: action.payload.startTimeOfEnergyStatistics,
                startTimeObjOfEnergyStatistics: action.payload.startTimeObjOfEnergyStatistics
            });
        default:
            return state;
    }
}

/**
 * Fault Alarm
 */
export const faultAlarm = (state = {
    scrollOptionsOfFaultTable: {
        scrollToFirstRowOnChange: true,
        y: 300
    },
    faultStateCodeUsedToQueryFault: 'null',
    deviceTypeCodeUsedToQueryFault: 'null',
    deviceCategory1UsedToQueryFault: 'null',
    startTimeUsedToQueryFault: createCurrentDateTime('YYYY-MM-DD'),
    startTimeObjUsedToQueryFault: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    endTimeUsedToQueryFault: createCurrentDateTime('YYYY-MM-DD'),
    endTimeObjUsedToQueryFault: moment(createCurrentDateTime('YYYY-MM-DD'), 'YYYY-MM-DD'),
    faultNameUsedToQueryFault: '',
    pageNumUsedToQueryFault: 1,
    pageSizeUsedToQueryFault: 10,
    dataOfFaultTableGroup: {
        total: 0,
        happen: 0,
        remove: 0,
        list: []
    }
}, action) => {
    switch(action.type) {
        case 'INIT_PARAMS_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                ...action.payload
            });
        case 'SET_DATA_OF_FAULT_TABLE_GROUP':
            return Object.assign({}, state, {
                dataOfFaultTableGroup: action.payload.dataOfFaultTableGroup,
                scrollOptionsOfFaultTable: action.payload.scrollOptionsOfFaultTable
            });
        case 'CHANGE_DEVICE_TYPE_CODE_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                deviceTypeCodeUsedToQueryFault: action.payload.deviceTypeCodeUsedToQueryFault,
            });
        case 'CHANGE_DEVICE_CATEGORY1_CODE_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                deviceCategory1UsedToQueryFault: action.payload.deviceCategory1UsedToQueryFault,
            });
        case 'CHANGE_FAULT_STATE_CODE_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                faultStateCodeUsedToQueryFault: action.payload.faultStateCodeUsedToQueryFault
            });
        case 'CHANGE_START_TIME_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                startTimeUsedToQueryFault: action.payload.startTimeUsedToQueryFault,
                startTimeObjUsedToQueryFault: action.payload.startTimeObjUsedToQueryFault
            });
        case 'CHANGE_END_TIME_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                endTimeUsedToQueryFault: action.payload.endTimeUsedToQueryFault,
                endTimeObjUsedToQueryFault: action.payload.endTimeObjUsedToQueryFault
            });
        case 'CHANGE_DEVICE_NAME_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                faultNameUsedToQueryFault: action.payload.faultNameUsedToQueryFault
            });
        case 'CHANGE_PAGE_NUM_USED_TO_QUERY_FAULT':
            return Object.assign({}, state, {
                pageNumUsedToQueryFault: action.payload.pageNumUsedToQueryFault
            });
        default:
            return state;
    }
}

/**
 * Screen Logs
 */
export const screenLogs = (state = {
    dataOfSOLogTable: { count: 0, list: [] },
    heightOfSOLogTable: 800,
    keywordsUsedToQueryScreenLogs: '',
    pageNumUsedToQuerySOLog: 1,
    pageSizeUsedToQuerySOLog: 10,
    visibilityOfDrawerForDescriptionTable: false,
    pageNumUsedToQueryDescriptions: 1,
    pageSizeUsedToQueryDescriptions: 10,
    logIdUsedToQueryDescriptions: '',
    keywordsUsedToQueryDescriptions: '',
    dataOfDescriptionTable: { count: 0, list: [] },
    visibilityOfModalForDescriptionForm: false,
    isNewOfModalForDescriptionForm: true,
    dataOfDescriptionForm: {
        logId: '',
        type: '',
        desc: '',
    },
    logIdUsedToControl: '',
    visibilityOfModalToDeleteDescription: false,
}, action) => {
    switch(action.type) {
        case 'SET_DATA_OF_SO_LOG_TABLE':
            return Object.assign({}, state, {
                dataOfSOLogTable: action.payload.dataOfSOLogTable,
            });
        case 'CHANGE_KEYWORDS_USED_TO_QUERY_SCREEN_LOGS':
            return Object.assign({}, state, {
                keywordsUsedToQueryScreenLogs: action.payload.keywordsUsedToQueryScreenLogs,
            });
        case 'CHANGE_PAGE_NUMBER_USED_TO_QUERY_SO_LOGS':
            return Object.assign({}, state, {
                pageNumUsedToQuerySOLog: action.payload.pageNumUsedToQuerySOLog,
            });
        case 'SET_DATA_OF_DESCRIPTION_TABLE':
            return Object.assign({}, state, {
                dataOfDescriptionTable: action.payload.dataOfDescriptionTable,
            });
        case 'TOGGLE_DRAWER_FOR_DESCRIPTION_TABLE':
            return Object.assign({}, state, {
                visibilityOfDrawerForDescriptionTable: action.payload.visibilityOfDrawerForDescriptionTable,
            });
        case 'RESET_DESCRIPTIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryDescriptions: action.payload.pageNumUsedToQueryDescriptions,
                pageSizeUsedToQueryDescriptions: action.payload.pageSizeUsedToQueryDescriptions,
                logIdUsedToQueryDescriptions: action.payload.logIdUsedToQueryDescriptions,
                keywordsUsedToQueryDescriptions: action.payload.keywordsUsedToQueryDescriptions,
                dataOfDescriptionTable: action.payload.dataOfDescriptionTable,
            });
        case 'CHANGE_LOG_ID_USED_TO_QUERY_DESCRIPTIONS':
            return Object.assign({}, state, {
                logIdUsedToQueryDescriptions: action.payload.logIdUsedToQueryDescriptions,
            });
        case 'CHANGE_KEYWORDS_USED_TO_QUERY_DESCRIPTIONS':
            return Object.assign({}, state, {
                keywordsUsedToQueryDescriptions: action.payload.keywordsUsedToQueryDescriptions,
            });
        case 'CHANGE_PAGE_NUMBER_USED_TO_QUERY_DESCRIPTIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryDescriptions: action.payload.pageNumUsedToQueryDescriptions,
            });
        case 'TOGGLE_MODAL_FOR_DESCRIPTION_FORM':
            return Object.assign({}, state, {
                visibilityOfModalForDescriptionForm: action.payload.visibilityOfModalForDescriptionForm,
                isNewOfModalForDescriptionForm: action.payload.isNewOfModalForDescriptionForm,
            });
        case 'CHANGE_DATA_OF_DESCRIPTION_FORM':
            return Object.assign({}, state, {
                dataOfDescriptionForm: action.payload.dataOfDescriptionForm,
            });
        case 'RESET_PAGE_PARAMETERS_OF_DESCRIPTIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryDescriptions: action.payload.pageNumUsedToQueryDescriptions,
                pageSizeUsedToQueryDescriptions: action.payload.pageSizeUsedToQueryDescriptions,
            });
        case 'TOGGLE_MODAL_TO_DELETE_DESCRIPTION':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteDescription: action.payload.visibilityOfModalToDeleteDescription,
                logIdUsedToControl: action.payload.logIdUsedToControl,
            });
        case 'RESET_PARAMETERS_OF_DESCRIPTIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryDescriptions: action.payload.pageNumUsedToQueryDescriptions,
                pageSizeUsedToQueryDescriptions: action.payload.pageSizeUsedToQueryDescriptions,
                logIdUsedToQueryDescriptions: action.payload.logIdUsedToQueryDescriptions,
                keywordsUsedToQueryDescriptions: action.payload.keywordsUsedToQueryDescriptions,
            });
        default:
            return state;
    }
}
