import moment from "moment";

import store from "../store";
import * as echarts from "echarts";

/**
 * time zone list
 */
export const timeZoneList = [
    { label: 'GMT+8:00', value: 'GMT+8:00' },
    { label: 'GMT+7:00', value: 'GMT+7:00' },
    { label: 'GMT+6:00', value: 'GMT+6:00' },
    { label: 'GMT+5:00', value: 'GMT+5:00' },
    { label: 'GMT+4:00', value: 'GMT+4:00' },
    { label: 'GMT+3:00', value: 'GMT+3:00' },
    { label: 'GMT+2:00', value: 'GMT+2:00' },
    { label: 'GMT+1:00', value: 'GMT+1:00' },
    { label: 'GMT+0:00', value: 'GMT+0:00' },
    { label: 'GMT-1:00', value: 'GMT-1:00' },
    { label: 'GMT-2:00', value: 'GMT-2:00' },
    { label: 'GMT-3:00', value: 'GMT-3:00' },
    { label: 'GMT-4:00', value: 'GMT-4:00' },
    { label: 'GMT-5:00', value: 'GMT-5:00' },
    { label: 'GMT-6:00', value: 'GMT-6:00' },
    { label: 'GMT-7:00', value: 'GMT-7:00' },
    { label: 'GMT-8:00', value: 'GMT-8:00' },
    { label: 'GMT-9:00', value: 'GMT-9:00' },
    { label: 'GMT-10:00', value: 'GMT-10:00' },
    { label: 'GMT-11:00', value: 'GMT-11:00' },
    { label: 'GMT+12:00', value: 'GMT+12:00' },
    { label: 'GMT+11:00', value: 'GMT+11:00' },
    { label: 'GMT+10:00', value: 'GMT+10:00' },
    { label: 'GMT+9:00', value: 'GMT+9:00' },
];

/**
 * Debounce Function
 * @param fn {Function} Function body needed to debounce
 * @param wait {number} Delay Time
 * @returns {function(...[*]=)}
 */
export const debounce = (fn, wait) => {
    let timeout = null;

    return () => {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(fn, wait);
    }
}

/**
 * Entries selector
 * @param entryObj {Object} The entry
 * @returns {string} The result entry
 */
export const entrySelector = (entryObj) => {
    const language = store.getState().common.language;

    const currentEntryObj = entryObj instanceof Object ? entryObj : { zh: '', en: '' };

    return (language === 'zh' ? currentEntryObj.zh : currentEntryObj.en);
}

/**
 * Transform an empty string to "--"
 * @param parameter {*} Parameter to be transformed
 * @returns {string} Transformed string
 */
export const transformEmptyStrToPlaceholder = (parameter) => {
    return (
        parameter && parameter.toString() ? parameter.toString() : '--'
    );
}

/**
 * Replace newlines in the string (Only handles the case that contains a newline character)
 * @param str {string} Target string
 * @returns {Array|string} The result after replacing the newline character
 */
export const transformEnter = (str) => {
    let result = '';

    if (str.indexOf('\r\n') !== -1) {
        result = str.split('\r\n');
    } else {
        result = str;
    }

    return result;
}

/**
 * Parse the cookie string
 * @param cookie {string} The cookie string
 * @returns {Object} Object containing cookie key-value pairs
 */
export const parseCookie = (cookie, optionsParseBase64 = {
    username: false,
    password: true,
    mailboxNeedToBind: true
}) => {
    let keyValueList = cookie.split(';'),
        cookieObj = {};

    keyValueList.forEach((item) => {
        let resultList = item.split('='),
            key = resultList[0].replace(/\s/g, ''),
            whetherParseBase64 = false;

        for (let keyOfParseBase64 in optionsParseBase64) {
            if (keyOfParseBase64 === key) {
                whetherParseBase64 = optionsParseBase64[keyOfParseBase64];
            }
        }

        if (whetherParseBase64) {
            if (resultList.length === 2) {
                cookieObj[key] = resultList[1];
            } else {
                let value = resultList[1];

                for (let i = 0; i < (resultList.length - 2); i++) {
                    value += '=';
                }

                cookieObj[key] = value;
            }
        } else {
            cookieObj[key] = resultList[1];
        }
    });

    return cookieObj;
}

/**
 * Convert the station type to the name of station type
 * @param stationType {number} The station type
 * @returns {string} The name of station type
 */
export const stationTypeConversion = (stationType) => {
    let stationTypeDescription = '';

    switch (stationType) {
        case 1:
            stationTypeDescription = '纯储能';
            break;
        case 2:
            stationTypeDescription = '光储并网消纳';
            break;
        case 3:
            stationTypeDescription = '光储充直流微网';
            break;
        case 4:
            stationTypeDescription = '光储离网供电';
            break;
        case 5:
            stationTypeDescription = '脉擎微电网系统模式';
            break;
        case 6:
            stationTypeDescription = '工商业储能壁挂式';
            break;
    }

    return stationTypeDescription;
}

/**
 * Create the object of account level
 * @param accountLevel {number} The account level
 * @returns {object} The object of account level
 */
export const accountLevelConversion = (accountLevel) => {
    let accountLevelObj = {},
        accountLevelDescriptionKey = '',
        accountLevelDescColor = '';

    switch (accountLevel) {
        case 0:
            accountLevelDescriptionKey = '个人';
            accountLevelDescColor = '#18C7AA';
            break;
        case 1:
            accountLevelDescriptionKey = '超级管理员';
            accountLevelDescColor = '#EA4B59'
            break;
        case 2:
            accountLevelDescriptionKey = '企业';
            accountLevelDescColor = '#EA4B59';
            break;
    }

    accountLevelObj.descriptionKey = accountLevelDescriptionKey;
    accountLevelObj.color = accountLevelDescColor;

    return accountLevelObj;
}

/**
 * Create the object of device status
 * @param deviceStatus {number} The device Status: 0 -> online; 1 -> offline; 2 -> alarm
 * @returns {Object} The object of device status
 */
export const deviceStatusConversion = (deviceStatus) => {
    let deviceStatusObj = {};

    switch (deviceStatus) {
        case 0:
            deviceStatusObj = {
                style: {
                    borderColor: '#a0d911',
                    backgroundColor: '#f4ffb8',
                    color: '#7cb305',
                },
                description: '在线'
            }
            break;
        case 1:
            deviceStatusObj = {
                style: {
                    borderColor: '#bfbfbf',
                    backgroundColor: '#fafafa',
                    color: '#bfbfbf',
                },
                description: '离线'
            }
            break;
        case 2:
            deviceStatusObj = {
                style: {
                    borderColor: '#f5222d',
                    backgroundColor: '#ffa39e',
                    color: '#cf1322',
                },
                description: '告警'
            }
            break;
    }

    return deviceStatusObj;
}

/**
 * Convert the device driver to the device Info
 * @param deviceDriver {string} The device driver
 * @returns {Object} The device info
 */
export const deviceDriverToDeviceInfo = (deviceDriver) => {
    let allDeviceTypeList = store.getState().common.allDeviceTypeList,
        deviceInfo = '';

    allDeviceTypeList.forEach((item) => {
        if (item.deviceDriver === deviceDriver) {
            deviceInfo = { ...item };
        }
    });

    return deviceInfo;
}

/**
 * Convert the device driver to the device name
 * @param deviceDriver {string} The device driver
 * @returns {string} The device name
 */
export const deviceDriverToDeviceName = (deviceDriver) => {
    let allDeviceTypeList = store.getState().common.allDeviceTypeList,
        deviceName = '';

    allDeviceTypeList.forEach((item) => {
        if (item.deviceDriver === deviceDriver) {
            const currentItem = item instanceof Object ? item : {
                deviceName: '',
                deviceNameUs: ''
            };

            deviceName = entrySelector({
                zh: currentItem.deviceName,
                en: currentItem.deviceNameUs
            });
        }
    });

    return deviceName;
}

/**
 * Prevent default action of the event and stop propagation
 * @param e {Object} Event object
 * @returns {undefined}
 */
export const preventDefaultAndPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
}

/**
 * Generate a time string in a custom format
 * @param formatter {string} The custom format string
 * @returns {string} The time string
 */
export const createCurrentDateTime = (formatter) => {
    let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        currentMonth = (currentDate.getMonth() + 1) < 10 ? ('0' + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1),
        currentDay = currentDate.getDate() < 10 ? ('0' + currentDate.getDate()) : currentDate.getDate(),
        currentHours = currentDate.getHours() < 10 ? ('0' + currentDate.getHours()) : currentDate.getHours(),
        currentMinutes = currentDate.getMinutes() < 10 ? ('0' + currentDate.getMinutes()) : currentDate.getMinutes(),
        currentSeconds = currentDate.getSeconds() < 10 ? ('0' + currentDate.getMinutes()) : currentDate.getMinutes(),
        currentDateStr = '';

    switch (formatter) {
        case 'YYYY-MM-DD HH:mm:ss':
            currentDateStr = currentYear + '-' + currentMonth + '-' + currentDay + ' ' + currentHours + ':' + currentMinutes + ':' + currentSeconds;
            break;
        case 'YYYY-MM-DD':
            currentDateStr = currentYear + '-' + currentMonth + '-' + currentDay;
            break;
        case 'YYYY-MM':
            currentDateStr = currentYear + '-' + currentMonth;
            break;
        case 'YYYY':
            currentDateStr = currentYear;
            break;
        case 'HH:mm':
            currentDateStr = currentHours + ':' + currentMonth;
            break;
    }

    return currentDateStr;
}

/**
 * Transform the moment object to formatted string based on the type code of date picker
 * @param momentObj {Object} The moment object
 * @param typeCodeOfDatePicker {number} The type code of date picker
 * @returns {string} The formatted string
 */
export const transformMomentToStringBasedOnTypeCodeOfDatePicker = (momentObj, typeCodeOfDatePicker) => {
    let timeStr = '';

    if (typeCodeOfDatePicker) {
        if (typeCodeOfDatePicker === 1) {
            timeStr = momentObj.format('YYYY-MM-DD');
        } else if (typeCodeOfDatePicker === 2) {
            timeStr = momentObj.format('YYYY-MM');
        } else {
            timeStr = momentObj.format('YYYY');
        }
    }

    return timeStr;
}

/**
 * Convert the month number to the month name
 * @param monthNum {number} The month number
 * @returns {string} The month name
 */
export const monthToDescription = (monthNum) => {
    const index = monthNum - 1;
    const zhMonthDescription = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
    const enMonthDescription = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    let description = index <= 11 ?
        entrySelector({
            en: enMonthDescription[index],
            zh: zhMonthDescription[index]
        }) :
        entrySelector({
            en: 'Unknown',
            zh: '未知'
        });

    return description;
}

/**
 * Generation the data of the XAxis item based on the type of date picker
 * @param data {Object} The origin data used to generate the data of XAxis
 * @param typeCodeOfDatePicker {number} The type code of date picker
 * @returns {Object} The data of the XAxis
 */
export const generationDataOfXAxisByTypeCodeOfDatePicker = (data, typeCodeOfDatePicker) => {
    let dataOfXAXis = [];

    if (data instanceof Array) {
        dataOfXAXis = data.map((item, index) => {
            const currentItem = item instanceof Object ? item : {
                saveTime: ''
            }

            let result = currentItem.saveTime;

            if (typeCodeOfDatePicker === 1) {
                result = result.slice(result.indexOf(' ') + 1, result.lastIndexOf(':'));
            } else if (typeCodeOfDatePicker === 2) {
                result = moment(result).date();

                result = result + entrySelector({
                    zh: '号',
                    en: 'st'
                });
            } else if (typeCodeOfDatePicker === 3) {
                result = moment(result).month();
                result = monthToDescription(result + 1);
            } else if (typeCodeOfDatePicker === 0) {
                result = result.slice(0, result.indexOf('-'));
            }

            return result;
        });
    }

    return dataOfXAXis;
}

export const transformDateString = (str, typeCodeOfDatePicker) => {
    const date = moment(str);

    if (typeCodeOfDatePicker === 1) {
        return date.format('HH:mm');
    } else if (typeCodeOfDatePicker === 2) {
        return `${date.date()}${entrySelector({ zh: '号', en: 'st' })}`;
    } else if (typeCodeOfDatePicker === 3) {
        return monthToDescription(date.month() + 1);
    } else if (typeCodeOfDatePicker === 0) {
        return date.year();
    }
}

/**
 * Create the real data of RunningStateOfDevice
 * @param responseData {Array} The response data that is a one-dimensional array
 * @param lengthOfItem {number} The length of each item in the two-dimensional array
 * @returns {Array} A two-dimensional array
 */
export const createDataOfRunningStateOfDevice = (responseData, lengthOfItem) => {
    const realParameterList = [];
    const lengthOfRealParameterList = responseData.length % lengthOfItem ?
        (parseInt((responseData.length / lengthOfItem).toString()) + 1) :
        (responseData.length / lengthOfItem);

    let itemOfRealParameterList = [];

    for (let i = 0; i < lengthOfRealParameterList; i++) {
        let j = 0;

        while (j < lengthOfItem) {
            let currentParameter = responseData[i * lengthOfItem + j];

            if (currentParameter) {
                currentParameter = currentParameter instanceof Object ? currentParameter : {
                    name: '--',
                    valueIsKey: false,
                    value: '--',
                    unit: '',
                };

                itemOfRealParameterList.push({
                    name: currentParameter.name,
                    valueIsKey: currentParameter.valueIsKey,
                    value: currentParameter.value,
                    unit: currentParameter.unit ?? '',
                });
            } else {
                itemOfRealParameterList.push({});
            }

            j++;
        }

        realParameterList.push(itemOfRealParameterList);
        itemOfRealParameterList = [];
    }

    return realParameterList;
}


export const createDataOfRunningStateOfDevice2 = (runningStateList, pageSize = 6, linSize = 2) => {
    const lengthOfGSERunningState = runningStateList.length === 0 ?
        0 :
        (
            parseInt((runningStateList.length % pageSize).toString()) === 0 ?
                parseInt((runningStateList.length / pageSize).toString()) :
                parseInt((runningStateList.length / pageSize).toString()) + 1
        );

    const lineNum = pageSize / linSize;
    
    const list = [];

    for (let i = 0; i < lengthOfGSERunningState; i++) {
        let itemOfGSERunningState = [];

        for (let j = 0; j < lineNum; j++) {
            let subItemOfGSERunningState = [];

            for (let k = 0; k < linSize; k++) {
                let itemOfRunningStateList = runningStateList[6 * i + 2 * j + k];

                if (itemOfRunningStateList) {
                    subItemOfGSERunningState.push(itemOfRunningStateList);
                } else {
                    subItemOfGSERunningState.push({});
                }
            }

            itemOfGSERunningState.push(subItemOfGSERunningState);
        }

        list.push(itemOfGSERunningState);
    }

    return list;
}

/**
 * Create the real parameter list
 * @param responseData {Array} The response data that is a one-dimensional array
 * @param lengthOfItem {number} The length of each item in the two-dimensional array
 * @returns {Array} A two-dimensional array
 */
export const createRealParameterList = (responseData, lengthOfItem) => {
    const statusOfCollectorControlled = store.getState().station.statusOfCollectorControlled;

    let realParameterList = [],
        lengthOfRealParameterList = responseData.length % lengthOfItem ?
            (parseInt((responseData.length / lengthOfItem).toString()) + 1) :
            (responseData.length / lengthOfItem),
        itemOfRealParameterList = [];

    for (let i = 0; i < lengthOfRealParameterList; i++) {
        let j = 0;

        while (j < lengthOfItem) {
            let currentParameter = responseData[i * lengthOfItem + j];

            if (currentParameter) {
                currentParameter = currentParameter instanceof Object ? currentParameter : {
                    attribName: '',
                    attribNameUs: '',
                    attribValue: '',
                    attribValueUs: '',
                    unit: '',
                };

                itemOfRealParameterList.push({
                    name: entrySelector({
                        zh: currentParameter.attribName,
                        en: currentParameter.attribNameUs
                    }),
                    value: statusOfCollectorControlled ?
                        (
                            currentParameter.isValid === 0 ?
                                entrySelector({
                                    zh: '--',
                                    en: '--'
                                }) :
                                entrySelector({
                                    zh: currentParameter.attribValue,
                                    en: currentParameter.attribValueUs
                                })
                        ) :
                        entrySelector({
                            zh: '--',
                            en: '--'
                        }),
                    unit: currentParameter.unit ? currentParameter.unit : '',
                });
            } else {
                itemOfRealParameterList.push({});
            }

            j++;
        }

        realParameterList.push(itemOfRealParameterList);
        itemOfRealParameterList = [];
    }

    return realParameterList;
}

/**
 * Create a two-dimensional array with a known length of each items on the basis of the original array
 * @param originalList {Array} The original list
 * @param lengthOfItem {number} The length of each item in the two-dimensional array
 * @returns {Array} A two-dimensional array
 */
export const createCustomList = (originalList, lengthOfItem) => {
    let customList = [],
        lengthOfCustomList = originalList.length % lengthOfItem ?
            (parseInt((originalList.length / lengthOfItem).toString()) + 1) :
            (originalList.length / lengthOfItem),
        itemOfCustomList = [];

    for (let i = 0; i < lengthOfCustomList; i++) {
        let j = 0;

        while (j < lengthOfItem) {
            let currentItem = originalList[i * lengthOfItem + j];

            if (currentItem) {
                itemOfCustomList.push(currentItem);
            } else {
                itemOfCustomList.push({});
            }

            j++;
        }

        customList.push(itemOfCustomList);
        itemOfCustomList = [];
    }

    return customList;
}

/**
 * Generate the new send down list
 * @param responseData {Array} There is the list of the key has been used to send down
 * @param originalSendDownList {Array} There is the list of the key used to render the page
 * @returns {Array} The new send down list
 */
export const transformDataUsedToSendDown = (responseData, originalSendDownList) => {
    let keyListUsed = responseData.map(item => {
        const currentItem = item instanceof Object ? item : {
            setCode: ''
        };

        return currentItem.setCode;
    }),
        sendDownList = null;

    sendDownList = originalSendDownList.map((item) => {
        let keyUsed = keyListUsed.indexOf(item.key);

        if (keyUsed > -1) {
            item.value = (item.valueType === 'number' ? parseFloat(responseData[keyUsed].setValue) : responseData[keyUsed].setValue);
        } else {
            delete (item.value);
        }

        return item;
    });

    return sendDownList;
}

/**
 * Create the options of charge and discharge bar chart
 * @param language {string} Current language code
 * @param dataOfChargeAndDischargeBar {Object} The data of charge and discharge bar chart
 * @returns {Object} The options of charge and discharge bar chart
 */
export const createOptionOfChargeAndDischargeBar = (typeCodeOfDatePicker, dataOfChargeAndDischargeBar, deviceDriver = '') => {
    const dataOfPowerIn = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.powerIn instanceof Array)) ?
        dataOfChargeAndDischargeBar.powerIn.map(item => item.attribValue) :
        [],
        dataOfPowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.powerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.powerOut.map(item => item.attribValue) :
            [],
        dataOfPVPowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pvPowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.pvPowerOut.map(item => item.attribValue) :
            [],
        dataOfPv1PowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pv1PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.pv1PowerOut.map(item => item.attribValue) :
            [],
        dataOfPv2PowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pv2PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.pv2PowerOut.map(item => item.attribValue) :
            [],
        dataOfPv3PowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pv3PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.pv3PowerOut.map(item => item.attribValue) :
            [],
        dataOfBat1PowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.bat1PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.bat1PowerOut.map(item => item.attribValue) :
            [],
        dataOfBat2PowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.bat2PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.bat2PowerOut.map(item => item.attribValue) :
            [],
        dataOfBat1PowerIn = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.bat1PowerIn instanceof Array)) ?
            dataOfChargeAndDischargeBar.bat1PowerIn.map(item => item.attribValue) :
            [],
        dataOfBat2PowerIn = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.bat2PowerIn instanceof Array)) ?
            dataOfChargeAndDischargeBar.bat2PowerIn.map(item => item.attribValue) :
            [],
        dataOfInvPowerIn = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.invPowerIn instanceof Array)) ?
            dataOfChargeAndDischargeBar.invPowerIn.map(item => item.attribValue) :
            [],
        dataOfInvPowerOut = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.invPowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.invPowerOut.map(item => item.attribValue) :
            [];

    let options = null;

    if (deviceDriver === 'kac') {
        const originalDataOfXAxis = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pv1PowerOut instanceof Array)) ?
            dataOfChargeAndDischargeBar.pv1PowerOut :
            [];
        const dataOfXAxis = generationDataOfXAxisByTypeCodeOfDatePicker(originalDataOfXAxis, typeCodeOfDatePicker);

        options = {
            title: {
                show: false
            },
            legend: {
                type: 'plain',
                show: true,
                orient: 'horizontal',
                padding: [5, 0, 0, 0],
                itemGap: 16,
                itemWidth: 25,
                itemHeight: 14,
                formatter: '{name}',
                selectedMode: 'multiple',
                inactiveColor: '#CCCCCC',
                textStyle: {
                    color: 'white',
                    fontSize: 14
                },
                icon: 'roundRect'
            },
            grid: {
                show: false,
                left: 80,
                top: 60,
                right: 60,
                bottom: 30
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: (params) => {
                    let formatterStr = params[0].name;

                    params.map((item) => {
                        // let marker = '<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px; height: 10px; background: ' +
                        //     (
                        //         (item.seriesName === '充电量' || item.seriesName === 'Charge Capacity') ?
                        //             'linear-gradient(90deg, #006EFF, #66A8FF)' :
                        //             (
                        //                 (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pvPowerOut instanceof Array)) ?
                        //                     (
                        //                         (item.seriesName === '放电量' || item.seriesName === 'Discharge Capacity') ?
                        //                             'linear-gradient(90deg, #722DFF, #BB18FF)' :
                        //                             'linear-gradient(90deg, #5CDBD3, #95DE64)'
                        //                     ) :
                        //                     'linear-gradient(90deg, #722DFF, #BB18FF)'
                        //             )
                        //     ) +
                        //     '"></span>';

                        const unit = 'kWh';

                        formatterStr += '<br />' + item.marker + ' ' + item.seriesName + '：' + item.value + unit;
                    });

                    return formatterStr;
                }
            },
            xAxis: {
                show: true,
                position: 'bottom',
                type: 'category',
                boundaryGap: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12
                },
                data: dataOfXAxis
            },
            dataZoom: {
                type: 'slider',

            },
            yAxis: {
                show: true,
                name: `${entrySelector({ zh: '单位', en: 'Unit' })}：kWh`,
                nameTextStyle: {
                    color: '#CCE4FF',
                    fontSize: 12,
                },
                type: 'value',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12,
                    margin: 10
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#666666',
                        type: 'dashed'
                    }
                }
            },
            series: [
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Pv 1 发电量',
                        en: 'Pv 1 Generation'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#006EFF'
                            },
                            {
                                offset: 1,
                                color: '#66A8FF'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfPv1PowerOut
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Pv 2 发电量',
                        en: 'Pv 2 Generation'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#722DFF'
                            },
                            {
                                offset: 1,
                                color: '#BB18FF'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfPv2PowerOut
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Pv 3 发电量',
                        en: 'Pv 3 Generation'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#5CDBD3'
                            },
                            {
                                offset: 1,
                                color: '#95DE64'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfPv3PowerOut,
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Bat 1 放电量',
                        en: 'Bat 1 Discharge'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#FAAD14'
                            },
                            {
                                offset: 1,
                                color: '#FFD666'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfBat1PowerOut
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Bat 2 放电量',
                        en: 'Bat 2 Discharge'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#FA541C'
                            },
                            {
                                offset: 1,
                                color: '#FF9C6E'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfBat2PowerOut
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Bat 1 充电量',
                        en: 'Bat 1 Charging'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#13C2C2'
                            },
                            {
                                offset: 1,
                                color: '#5CDBD3'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfBat1PowerIn
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Bat 2 充电量',
                        en: 'Bat 2 Charging'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#EB2F96'
                            },
                            {
                                offset: 1,
                                color: '#FF85C0'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfBat2PowerIn
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Inv 取电量',
                        en: 'Inv Withdraw'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#F5222D'
                            },
                            {
                                offset: 1,
                                color: '#FF7875'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfInvPowerIn
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: 'Inv 馈电量',
                        en: 'Inv Feed'
                    }),
                    barMaxWidth: 10,
                    barMinWidth: 3,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#2F54EB'
                            },
                            {
                                offset: 1,
                                color: '#85A5FF'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfInvPowerOut
                },
            ]
        };
    } else {
        const originalDataOfXAxis = (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.powerIn instanceof Array)) ?
            dataOfChargeAndDischargeBar.powerIn :
            [];
        const dataOfXAxis = generationDataOfXAxisByTypeCodeOfDatePicker(originalDataOfXAxis, typeCodeOfDatePicker);

        options = {
            title: {
                show: false
            },
            legend: {
                type: 'plain',
                show: true,
                orient: 'horizontal',
                padding: [5, 0, 0, 0],
                itemGap: 16,
                itemWidth: 25,
                itemHeight: 14,
                formatter: '{name}',
                selectedMode: 'multiple',
                inactiveColor: '#CCCCCC',
                textStyle: {
                    color: 'white',
                    fontSize: 14
                },
                icon: 'roundRect'
            },
            grid: {
                show: false,
                left: 100,
                top: 60,
                right: 60,
                bottom: 30
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: (params) => {
                    let formatterStr = params[0].name;

                    params.map((item) => {
                        let marker = '<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px; height: 10px; background: ' +
                            (
                                (item.seriesName === '充电量' || item.seriesName === 'Charge Capacity') ?
                                    'linear-gradient(90deg, #006EFF, #66A8FF)' :
                                    (
                                        (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pvPowerOut instanceof Array)) ?
                                            (
                                                (item.seriesName === '放电量' || item.seriesName === 'Discharge Capacity') ?
                                                    'linear-gradient(90deg, #722DFF, #BB18FF)' :
                                                    'linear-gradient(90deg, #5CDBD3, #95DE64)'
                                            ) :
                                            'linear-gradient(90deg, #722DFF, #BB18FF)'
                                    )
                            ) +
                            '"></span>',
                            unit = 'kWh';

                        formatterStr += '<br />' + marker + ' ' + item.seriesName + '：' + item.value + unit;
                    });

                    return formatterStr;
                }
            },
            xAxis: {
                show: true,
                position: 'bottom',
                type: 'category',
                boundaryGap: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12
                },
                data: dataOfXAxis
            },
            yAxis: {
                show: true,
                name: `${entrySelector({ zh: '单位', en: 'Unit' })}：kWh`,
                nameTextStyle: {
                    color: '#CCE4FF',
                    fontSize: 12,
                },
                type: 'value',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#1D4F85'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12,
                    margin: 10
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#666666',
                        type: 'dashed'
                    }
                }
            },
            series: [
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: '充电量',
                        en: 'Charge Capacity'
                    }),
                    barWidth: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#006EFF'
                            },
                            {
                                offset: 1,
                                color: '#66A8FF'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfPowerIn
                },
                {
                    type: 'bar',
                    name: entrySelector({
                        zh: '放电量',
                        en: 'Discharge Capacity'
                    }),
                    barWidth: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            {
                                offset: 0,
                                color: '#722DFF'
                            },
                            {
                                offset: 1,
                                color: '#BB18FF'
                            }
                        ]),
                        borderRadius: 5
                    },
                    data: dataOfPowerOut
                },
            ]
        };

        if (dataOfChargeAndDischargeBar && (dataOfChargeAndDischargeBar.pvPowerOut instanceof Array)) {
            // options.series.push({
            //     type: 'bar',
            //     name: entrySelector({
            //         zh: '光伏充电量',
            //         en: 'PV Charge Capacity'
            //     }),
            //     barWidth: 10,
            //     itemStyle: {
            //         color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //             {
            //                 offset: 0,
            //                 color: '#5CDBD3'
            //             },
            //             {
            //                 offset: 1,
            //                 color: '#95DE64'
            //             }
            //         ]),
            //         borderRadius: 5
            //     },
            //     data: dataOfPVPowerOut,
            // });
        }
    }

    return options;
}

export const generateOptionsOfChargeAndDischargeBarChart = (seriesInfo, originalData = [], typeCodeOfDatePicker) => {
    if (originalData instanceof Array) {
        const colorsOfSeries = [
            ['#006EFF', '#66A8FF'],
            ['#722DFF', '#BB18FF'],
            ['#FA541C', '#FF9C6E'],
            ['#FAAD14', '#FFD666'],
            ['#13C2C2', '#5CDBD3'],
            ['#EB2F96', '#FF85C0'],
            ['#5CDBD3', '#95DE64'],
            ['#F5222D', '#FF7875'],
            ['#2F54EB', '#85A5FF'],
        ];

        const dimensions = [{ name: 'product' }];
        const series = [];

        seriesInfo.forEach((item, index) => {
            dimensions.push({ name: item.name });

            const colors = colorsOfSeries[index];

            const parameterOfColor = colors.map((item, index, array) => {
                if (index > 0) {
                    return { offset: 1 / (array.length - 1) * index, color: item };
                } else {
                    return { offset: 0, color: item };
                }
            });

            series.push({
                type: 'bar',
                barMaxWidth: 10,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, parameterOfColor),
                    borderRadius: 5,
                },
            });
        });

        const dataSource = originalData.map((item = {}) => {
            const saveTime = transformDateString(item.saveTime, typeCodeOfDatePicker);

            const newItem = [saveTime];

            seriesInfo.forEach((serieInfo) => {
                if (item.hasOwnProperty(serieInfo.key)) {
                    newItem.push(item[serieInfo.key]);
                }
            });

            return newItem;
        });

        console.log('dataSource', dataSource);

        const options = {
            legend: {
                type: 'plain',
                show: true,
                orient: 'horizontal',
                padding: [5, 0, 0, 0],
                itemGap: 16,
                itemWidth: 25,
                itemHeight: 14,
                formatter: '{name}',
                selectedMode: 'multiple',
                inactiveColor: '#CCCCCC',
                textStyle: {
                    color: 'white',
                    fontSize: 14,
                },
                icon: 'roundRect',
            },
            grid: {
                show: false,
                left: 100,
                top: 60,
                right: 60,
                bottom: 30,
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: (params) => {
                    let formatterStr = '';

                    if (params instanceof Array && params.length > 0) {
                        formatterStr = params[0].name;

                        params.forEach((item, index) => {
                            const specialStrOfBackground = colorsOfSeries[index] instanceof Array ?
                                colorsOfSeries[index].reduce((prev, item, index, array) => {
                                    if (index === array.length - 1) {
                                        return `${prev}, ${item}`;
                                    } else {
                                        return `${prev}, ${item}, `;
                                    }
                                }) :
                                '';

                            const styleOfMarker = {
                                display: 'inline-block',
                                width: '10px',
                                height: '10px',
                                'margin-right': '5px',
                                'border-radius': '5px',
                            }

                            if (specialStrOfBackground) {
                                Object.assign(styleOfMarker, { background: `linear-gradient(0deg, ${specialStrOfBackground})` });
                            }

                            let styleStrOfMarker = '';

                            for (let key in styleOfMarker) {
                                styleStrOfMarker += `${key}: ${styleOfMarker[key]};`;
                            }

                            const marker = `<span style="${styleStrOfMarker}"></span>`;

                            let unit = 'kWh';

                            formatterStr += `<br />${marker} ${item.seriesName}${entrySelector({ zh: "：", en: ' : ' })}${item.value[index + 1]}${unit}`;
                        });
                    }

                    return formatterStr;
                },
            },
            xAxis: {
                show: true,
                position: 'bottom',
                type: 'category',
                boundaryGap: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85',
                    },
                },
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: '#1D4F85',
                    },
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12,
                },
            },
            // dataZoom: {
            //     type: 'inside',
            //     start: 0,
            //     end: 100,
            // },
            yAxis: {
                show: true,
                name: `${entrySelector({ zh: '单位', en: 'Unit' })}：kWh`,
                nameTextStyle: {
                    color: '#CCE4FF',
                    fontSize: 12,
                },
                type: 'value',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#1D4F85',
                    },
                },
                axisLabel: {
                    show: true,
                    color: '#CCE4FF',
                    fontSize: 12,
                    margin: 10,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#666666',
                        type: 'dashed',
                    },
                }
            },
            dataset: { dimensions, source: dataSource },
            series: series,
        };

        // const options = {
        //     title: {
        //         show: false,
        //     },
        //     legend: {
        //         type: 'plain',
        //         show: true,
        //         orient: 'horizontal',
        //         padding: [5, 0, 0, 0],
        //         itemGap: 16,
        //         itemWidth: 25,
        //         itemHeight: 14,
        //         formatter: '{name}',
        //         selectedMode: 'multiple',
        //         inactiveColor: '#CCCCCC',
        //         textStyle: {
        //             color: 'white',
        //             fontSize: 14,
        //         },
        //         icon: 'roundRect',
        //     },
        //     grid: {
        //         show: false,
        //         left: 100,
        //         top: 60,
        //         right: 60,
        //         bottom: 30,
        //     },
        //     tooltip: {
        //         show: true,
        //         trigger: 'axis',
        //         formatter: (params) => {
        //             let formatterStr = '';

        //             if(params instanceof Array && params.length > 0) {
        //                 formatterStr = params[0].name;

        //                 params.forEach((item, index) => {
        //                     const specialStrOfBackground = colorsOfSeries[index] instanceof Array ?
        //                         colorsOfSeries[index].reduce((prev, item, index, array) => {
        //                             if(index === array.length - 1) {
        //                                 return `${prev}${item}`;
        //                             } else {
        //                                 return `${prev}${item}, `;
        //                             }
        //                         }) :
        //                         '';

        //                     const styleOfMarker = {
        //                         style: 'inline-block',
        //                         width: '10px',
        //                         height: '10px',
        //                         'margin-right': '5px',
        //                         'border-radius': '5px',
        //                     }

        //                     if(specialStrOfBackground) {
        //                         Object.assign(styleOfMarker, { background: `linear-gradient(90deg, ${specialStrOfBackground})` });
        //                     }

        //                     let styleStrOfMarker = '';

        //                     for(let key in styleOfMarker) {
        //                         styleStrOfMarker += `${key}: ${styleOfMarker[key]}`;
        //                     }

        //                     const marker = `<span style="${styleStrOfMarker}"></span>`;

        //                     let unit = 'kWh';

        //                     formatterStr += `<br />${marker} ${item.seriesName}${entrySelector({ zh: "：", en: ' : ' })}${item.value}${unit}`;
        //                 });
        //             }

        //             return formatterStr;
        //         },
        //     },
        //     xAxis: {
        //         show: true,
        //         position: 'bottom',
        //         type: 'category',
        //         boundaryGap: true,
        //         axisLine: {
        //             show: true,
        //             lineStyle: {
        //                 color: '#1D4F85',
        //             },
        //         },
        //         axisTick: {
        //             show: true,
        //             lineStyle: {
        //                 color: '#1D4F85',
        //             },
        //         },
        //         axisLabel: {
        //             show: true,
        //             color: '#CCE4FF',
        //             fontSize: 12,
        //         },
        //     },
        //     yAxis: {
        //         show: true,
        //         name: `${entrySelector({ zh: '单位', en: 'Unit' })}：kWh`,
        //         nameTextStyle: {
        //             color: '#CCE4FF',
        //             fontSize: 12,
        //         },
        //         type: 'value',
        //         axisLine: {
        //             show: false,
        //             lineStyle: {
        //                 color: '#1D4F85',
        //             },
        //         },
        //         axisLabel: {
        //             show: true,
        //             color: '#CCE4FF',
        //             fontSize: 12,
        //             margin: 10,
        //         },
        //         splitLine: {
        //             show: true,
        //             lineStyle: {
        //                 color: '#666666',
        //                 type: 'dashed',
        //             },
        //         }
        //     },
        //     dataSet: { source: dataSource },
        //     series: series,
        // }

        return options;
    } else {
        return {};
    }
}

/**
 * Generate the option of gauge
 * @param value {number} Current Value
 * @param minPower {number} Mininum Power
 * @param maxPower {number} Maxinum Power
 * @returns {Object} The option of the customized gauge
 */
export const generateOptionOfGaugeUsedToShowPower = (value, minPower, maxPower) => {
    const dataArr = [{
        value: value,
        name: entrySelector({
            zh: '实时功率',
            en: 'Real Time Power'
        }),
        itemStyle: {
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: '#1890FF'
                    },
                    {
                        offset: 1,
                        color: '#18C7AA'
                    }
                ],
                global: false
            }
        }
    }];

    const color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        {
            offset: 0,
            color: '#5CF9FE'
        },
        {
            offset: 0.17,
            color: '#468EFD'
        },
        {
            offset: 0.9,
            color: '#468EFD'
        },
        {
            offset: 1,
            color: '#5CF9FE'
        }
    ]);

    const colorSet = [
        [1, color]
    ];

    const rich = {
        white: {
            fontSize: 18,
            color: 'whitesmoke',
            fontWeight: '700',
            padding: [58, 0, 0, 0]
        },
        blue: {
            fontSize: 18,
            fontFamily: 'DINBold',
            color: 'whitesmoke',
            fontWeight: '700',
            padding: [58, 0, 0, 0]
        },
        radius: {
            width: 350,
            height: 80,
            borderWidth: 1,
            borderColor: '#0092F2',
            fontSize: 50,
            color: '#fff',
            backgroundColor: '#1B215B',
            borderRadius: 20,
            textAlign: 'center'
        },
        size: {
            height: 400,
            padding: [100, 0, 0, 0]
        }
    };

    return {
        backgroundColor: '#0A264E',
        tooltip: {
            formatter: '{b} : {c}kW',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 0,
            textStyle: {
                color: 'white',
                fontSize: 12
            }
        },
        series: [
            {
                type: 'gauge',
                name: '外层辅助',
                radius: '74%',
                startAngle: '225',
                endAngle: '-45',
                splitNumber: maxPower - minPower,
                pointer: {
                    show: false
                },
                detail: {
                    show: false,
                },
                data: [{
                    value: 1
                }],
                title: {
                    show: true,
                    offsetCenter: [0, 30],
                    textStyle: {
                        color: '#fff',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontFamily: '微软雅黑',
                        fontSize: 20,
                    }
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: [
                            [1, '#00FFFF']
                        ],
                        width: 2,
                        opacity: 1
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true,
                    length: 20,
                    lineStyle: {
                        color: '#051932',
                        width: 0,
                        type: 'solid',
                    },
                },
                axisLabel: {
                    show: false
                }
            },
            {
                type: 'gauge',
                radius: '70%',
                startAngle: '225',
                endAngle: '-45',
                pointer: {
                    show: true
                },
                detail: {
                    formatter: function (value) {
                        let num = Math.round(value);

                        return '{white|' + num + '}' + '{blue|kW}{size|' + '}';
                    },
                    rich: rich,
                    "offsetCenter": ['0%', "55%"],
                },
                data: dataArr,
                min: minPower,
                max: maxPower,
                title: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: colorSet,
                        width: 12,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        opacity: 1
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false,
                    length: 25,
                    lineStyle: {
                        color: '#00377a',
                        width: 2,
                        type: 'solid',
                    },
                },
                axisLabel: {
                    show: false
                },
                animationDuration: 4000,
            },
            {
                name: '灰色内圈',
                type: 'gauge',
                z: 2,
                radius: '60%',
                startAngle: '225',
                endAngle: '-45',
                axisLine: {
                    lineStyle: {
                        color: [
                            [1, '#018DFF']
                        ],
                        fontSize: 20,
                        width: 2,
                        opacity: 1, //刻度背景宽度
                    }
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                detail: {
                    show: 0
                }
            },
            {
                name: "白色圈刻度",
                type: "gauge",
                radius: "72%",
                startAngle: 225,
                endAngle: -45,
                min: minPower,
                max: maxPower,
                splitNumber: 6,
                z: 4,
                axisTick: {
                    show: false
                },
                splitLine: {
                    length: 8,
                    lineStyle: {
                        width: 2,
                        color: '#018DFF'
                    }
                },
                axisLabel: {
                    color: 'rgba(255,255,255,8)',
                    fontSize: 12,
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        opacity: 0
                    }
                },
                detail: {
                    show: false
                },
                data: [{
                    value: 0,
                    name: ""
                }]
            },
            {
                type: 'pie',
                radius: '40%',
                center: ['50%', '50%'],
                z: 1,
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.RadialGradient(.5, .5, .8, [{
                            offset: 0,
                            color: '#4978EC'
                        },
                        {
                            offset: .5,
                            color: '#1E2B57'
                        },
                        {
                            offset: 1,
                            color: '#141F3D'
                        }
                        ], false),
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        }
                    },
                },
                hoverAnimation: false,
                label: {
                    show: false,
                },
                tooltip: {
                    show: false
                },
                data: [100],
                animationType: "scale"
            },
            {
                type: 'pie',
                radius: '85%',
                center: ['50%', '50%'],
                z: 0,
                itemStyle: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                hoverAnimation: false,
                label: {
                    show: false,
                },
                tooltip: {
                    show: false
                },
                data: [120],
                animationType: "scale"
            }
        ]
    };
}

/**
 * Generate the option of generation power chart
 * @param data {Array} The chart data
 * @param typeCodeOfDatePicker {number} The type code of date picker
 * @returns {Object} The option of the generation power chart
 */
export const generateOptionOfGenerationPowerChart = (data, typeCodeOfDatePicker) => {
    let dataOfXAxis = generationDataOfXAxisByTypeCodeOfDatePicker(data, typeCodeOfDatePicker),
        dataOfGenerationPower = data instanceof Array ?
            (
                data.map((item = {
                    attribValue: 0
                }) => {
                    // const currentItem = item instanceof Object ? item : {
                    //     attribValue: 0
                    // };

                    return item.attribValue;
                })
            ) :
            [],
        seriesOfGenerationPower = null;

    if (typeCodeOfDatePicker === 1) {
        seriesOfGenerationPower = {
            type: 'line',
            name: entrySelector({
                zh: '发电量',
                en: 'Generation Power'
            }),
            showSymbol: false,
            hoverAnimation: true,
            legendHoverLink: true,
            cursor: 'pointer',
            connectNulls: false,
            step: false,
            label: {
                show: false
            },
            itemStyle: {
                color: '#009FF2'
            },
            lineStyle: {
                color: '#009FF2',
                width: 1,
                type: 'solid'
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: '#2990c6'
                    }, {
                        offset: 1,
                        color: '#14365C'
                    }]
                }
            },
            smooth: true,
            data: dataOfGenerationPower
        }
    } else {
        seriesOfGenerationPower = {
            type: 'bar',
            name: entrySelector({
                zh: '发电量',
                en: 'Generation Power'
            }),
            barWidth: 10,
            itemStyle: {
                // The gradient starts at the bottom
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                        offset: 0,
                        color: '#006EFF'
                    },
                    {
                        offset: 1,
                        color: '#66A8FF'
                    }
                ]),
                borderRadius: 5
            },
            data: dataOfGenerationPower
        };
    }

    return {
        title: {
            show: false
        },
        legend: {
            type: 'plain',
            show: true,
            orient: 'horizontal',
            padding: [5, 0, 0, 0],
            itemGap: 16,
            itemWidth: 25,
            itemHeight: 14,
            formatter: '{name}',
            selectedMode: 'multiple',
            inactiveColor: '#CCCCCC',
            textStyle: {
                color: 'white',
                fontSize: 14
            },
            icon: 'roundRect'
        },
        grid: {
            show: false,
            left: 60,
            top: 60,
            right: 60,
            bottom: 30
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            formatter: (params) => {
                let formatterStr = params[0].name;

                params.forEach((item) => {
                    let marker = typeCodeOfDatePicker === 1 ? item.marker : '<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 10px; height: 10px; background: linear-gradient(90deg, #006EFF, #66A8FF)"></span>',
                        unit = 'kWh';

                    formatterStr += '<br />' + marker + ' ' + entrySelector({
                        zh: '发电量：',
                        en: 'Generation Power : '
                    }) + item.value + unit;
                });

                return formatterStr;
            }
        },
        xAxis: {
            show: true,
            position: 'bottom',
            type: 'category',
            boundaryGap: true,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisLabel: {
                show: true,
                color: '#CCE4FF',
                fontSize: 12
            },
            data: dataOfXAxis
        },
        yAxis: {
            show: true,
            name: `${entrySelector({ zh: '单位', en: 'Unit' })}：kWh`,
            nameTextStyle: {
                color: '#CCE4FF',
                fontSize: 12,
            },
            type: 'value',
            axisLine: {
                show: false,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisLabel: {
                show: true,
                color: '#CCE4FF',
                fontSize: 12,
                margin: 10
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#666666',
                    type: 'dashed'
                }
            }
        },
        series: [seriesOfGenerationPower]
    };
}

/**
 * Generate the option of the PV chart
 * @param data The data of the PV chart
 * @returns {Object} The option of the PV curve
 */
export const generateOptionOfPVCurve = (data) => {
    const dataOfPVCurve = data instanceof Array ? data : {
        PV1InputVoltage: []
    };

    let PVList = [],
        dataOfXAxis = dataOfPVCurve.PV1InputVoltage instanceof Array ? dataOfPVCurve.PV1InputVoltage : [],
        seriesOfPVCurve = [];

    for (let i = 0; i < 12; i++) {
        PVList.push({
            voltage: [],
            current: [],
            power: []
        });
    }

    for (let key in dataOfPVCurve) {
        let SNOfPV = parseInt(key.charAt(2)) - 1,
            keyOfItem = /(Power)$/.test(key) ?
                'power' :
                (
                    /(Voltage)$/.test(key) ? 'voltage' : 'current'
                );

        PVList[SNOfPV][keyOfItem] = dataOfPVCurve[key];
    }

    PVList.forEach((item, index) => {
        for (let key in item) {
            let series = null,
                seriesName = '',
                seriesData = null;

            if (key === 'voltage') {
                seriesName = entrySelector({
                    zh: `PV${index + 1} 电压`,
                    en: `PV${index + 1} Voltage`
                });
            } else {
                if (key === 'current') {
                    seriesName = entrySelector({
                        zh: `PV${index + 1} 电流`,
                        en: `PV${index + 1} Current`
                    });
                } else {
                    seriesName = entrySelector({
                        zh: `PV${index + 1} 功率`,
                        en: `PV${index + 1} Power`
                    });
                }
            }

            seriesData = item[key].map((item) => item.attribValue);

            series = {
                type: 'line',
                name: seriesName,
                showSymbol: false,
                hoverAnimation: true,
                legendHoverLink: true,
                cursor: 'pointer',
                connectNulls: false,
                step: false,
                label: {
                    show: false
                },
                smooth: true,
                data: seriesData
            }

            seriesOfPVCurve.push(series);
        }
    });

    return {
        title: {
            show: false
        },
        legend: {
            type: 'scroll',
            show: true,
            padding: [8, 0, 0, 0],
            itemGap: 16,
            itemWidth: 25,
            itemHeight: 14,
            formatter: '{name}',
            selectedMode: 'multiple',
            inactiveColor: '#CCCCCC',
            textStyle: {
                color: 'white',
                fontSize: 14
            },
            icon: 'circle',
            pageIconColor: '#CCCCCC',
            pageIconInactiveColor: '#666666',
            pageTextStyle: {
                color: '#CCCCCC'
            }
        },
        grid: {
            show: false,
            left: 30,
            top: 60,
            right: 30,
            bottom: 30
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            formatter: (params) => {
                let formatterOfSeriesValue = params[0].name;

                params.map((item) => {
                    let seriesName = item.seriesName,
                        unit = '';

                    if (/((\u7535\u538b)|(Voltage))$/.test(seriesName)) {
                        unit = 'V';
                    } else {
                        if (/((\u7535\u6d41)|(Voltage))$/.test(seriesName)) {
                            unit = 'A';
                        } else {
                            unit = 'kW';
                        }
                    }

                    formatterOfSeriesValue += '<br />' + item.marker + ' ' +
                        seriesName + '：' + item.value + unit;
                });

                return formatterOfSeriesValue;
            }
        },
        xAxis: {
            show: true,
            position: 'bottom',
            type: 'category',
            boundaryGap: true,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisLabel: {
                show: true,
                color: '#CCE4FF',
                fontSize: 12
            },
            data: dataOfXAxis.length > 0 ?
                dataOfXAxis.map(item => item.saveTime.slice(item.saveTime.indexOf(' ') + 1, item.saveTime.indexOf(' ') + 6)) :
                []
        },
        yAxis: {
            show: true,
            position: 'left',
            name: '',
            nameLocation: 'end',
            nameTextStyle: {
                color: '#CCE4FF',
                fontSize: 12
            },
            type: 'value',
            axisLine: {
                show: false,
                lineStyle: {
                    color: '#1D4F85'
                }
            },
            axisLabel: {
                show: true,
                color: '#CCE4FF',
                fontSize: 12,
                margin: 10
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#666666',
                    type: 'dashed'
                }
            }
        },
        series: seriesOfPVCurve
    }
}

/**
 * Create the WebSocket connection for real-time alarm
 */
export const createWebSocketForRealtimeAlarm = ({
    fail = () => { },
} = {}) => {
    const state = store.getState();
    const { webSocketOfRealAlarm = null } = state.station;

    if (webSocketOfRealAlarm instanceof WebSocket) {
        return {
            isNew: false,
            webSocket: webSocketOfRealAlarm,
        };
    } else {
        const stationIdToControl = parseInt(localStorage.getItem('stationIdToControl'));

        // const webSocket = new WebSocket(`wss://kems.ksdatacloud.com/wss/websocket/alarmprompt/${stationIdToControl}`); // 国内 KEMS
        // const webSocket = new WebSocket(`ws://ems.ksdatacloud.com/wss/websocket/alarmprompt/${stationIdToControl}`); // 海外 KEMS

        const { host = '', protocol = '' } = window.location;
        const webSocket = new WebSocket(`${/https/.test(protocol) ? 'wss' : 'ws'}://${host}/wss/websocket/alarmprompt/${stationIdToControl}`);

        webSocket.addEventListener('open', function (event) {
            // console.log('open event', event);
            console.log('连接成功！');
        });

        webSocket.addEventListener('error', function (event) {
            // console.log('error event', event);
            console.log('连接断开！');
        });

        webSocket.addEventListener('close', function (event) {
            // console.log('close event', event);
            console.log('连接关闭！');
            fail();
        });

        return {
            isNew: true,
            webSocket,
        };
    }
}

/**
 * Close the WebSocket connection for real-time alarm
 */
export const closeWebSocketForRealtimeAlarm = () => {
    const state = store.getState();
    const { webSocketOfRealAlarm = null } = state.station;

    if (webSocketOfRealAlarm instanceof WebSocket) {
        webSocketOfRealAlarm.close();
    }
}

/**
 * Convert Object to FormData
 * @param obj
 */
export const convertObjToFormData = (obj) => {
    const formData = new FormData();

    for (let key in obj) {
        formData.append(key, obj[key] || obj[key] === 0 ? obj[key].toString() : '');
    }

    return formData;
};
