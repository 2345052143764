import React, { Component } from "react";
import { connect } from "react-redux";
import { ConfigProvider, Spin } from "antd";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import moment from "moment";
import "./assets/iconfont/iconfont.css";

import "./assets/less/App.less";
import "./assets/css/App.css";
import "./assets/css/styleOfAntdComponent.css";
import Routes from "./router";
import {
    setInitialLanguage,
} from "./action/common";
import { entrySelector } from "./tools/common";
// import { entrySelector } from "./tools/common";

class App extends Component {
    componentDidMount() {
        window.addEventListener('message', this.processMessage);

        const {
            // action
            setInitialLanguage,
        } = this.props;

        // After determining the language code using i18next-browser-languagedetector,
        // the module stores the language code in the local store as 'i18nextLng' by default.
        let langCode = localStorage.getItem('i18nextLng'),
            scriptDOM = document.createElement('script'),
            head = document.getElementsByTagName('head')[0];

        if(/zh/.test(langCode)) {
            // Simplified Chinese is currently universally used in Chinese.
            localStorage.setItem('language', 'zh');
            moment.locale('zh-cn');

            scriptDOM.setAttribute('src', 'https://webapi.amap.com/maps?v=1.4.15&key=4185e1bbd83c3152074fa118d6a6cb29');

            scriptDOM.onload = () => {
                window.AMap = AMap;

                scriptDOM = null;
            }
        } else {
            // Default language is english.
            // English is currently flipped.
            localStorage.setItem('language', 'en');
            moment.locale('en');

            scriptDOM.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBBFkpnS4a8ShHRDY-iEhZ7lD6yNGzxuqE&language=en');

            scriptDOM.onload = () => {
                window.GoogleMap = google.maps;

                scriptDOM = null;
            }
        }

        head.appendChild(scriptDOM);

        setInitialLanguage();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.processMessage);
    }

    processMessage(event) {
        const {
            origin = {},
            source = {},
            data = {},
        } = event;
        const accessibleOriginSet = new Set(['http://134.175.42.51:9003']);

        const { prevLocation = {} } = source;
        const { pathname = '' } = prevLocation;
        const loginInfo = (data instanceof Object ? data : JSON.parse(data)) ?? {};

        if(accessibleOriginSet.has(origin) && pathname === '/' && loginInfo.id > 0) {
            sessionStorage.setItem('origin', origin);
            sessionStorage.setItem('dataSource', data);
            
            const {
                isAdmin: userLevel = 0,
                powerId: stationIdToControl,
                token = {},
                id: userId,
                username = '',
                userFile = '',
                logoName = '',
                logoFile= '',
                nickname ='',
            } = loginInfo;
        
            const { access_token = '' } = token ?? {};
            
            localStorage.setItem('isLogined', 'succeeded');
            localStorage.setItem('token', access_token ?? '');
            localStorage.setItem('userId', userId);
            localStorage.setItem('username', username ?? '');
            localStorage.setItem('nickname', nickname ?? '');
            localStorage.setItem('userLevel', userLevel);
            localStorage.setItem('userAvatarURL', userFile ?? '');
            localStorage.setItem('platformName', logoName ?? '');
            localStorage.setItem('pathOfPlatformLogo', logoFile ?? '');
            localStorage.setItem('stationIdToControl', stationIdToControl ? stationIdToControl : '');

            window.location.reload();
        }
    }

    render() {
        const {
            // state
            language,
            loading,
        } = this.props;

        return (
            <ConfigProvider
                locale={language === 'zh' ? zhCN : enUS}
                getPopupContainer={(node) => {
                    if(node) {
                        return node; // antd 4.x
                        // return node.parentNode; // antd 3.x
                    } else {
                        return document.body;
                    }
                }}
            >
                <div className="App">
                    <Routes />
                    <Spin
                        spinning={loading}
                        tip={entrySelector({ zh: '加载中……', en: 'Loading...' })}
                        size="large"
                    />
                </div>
            </ConfigProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.common.language,
    loading: state.common.loading,
});

const mapDispatchToProps = (dispatch) => ({
    setInitialLanguage: () => dispatch(setInitialLanguage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
