import actionTypes from './types';

export const setInitialLanguage = () => {
    return {
        type: actionTypes.CHANGE_LANGUAGE,
        payload: {
            language: localStorage.getItem('language')
        }
    }
}

export const logout = () => {
    localStorage.removeItem('isLogined');

    sessionStorage.removeItem('isLogined');

    return {
        type: actionTypes.LOGOUT,
        payload: {
            isLogined: false
        }
    }
}
