/**
 * Power Station Management Form
 */
export const stationInfoForm = (state = {
    nameOfStation: '',
    creationTimeObjOfStation: null,
    creationTimeOfStation: '',
    installedCapacityOfStation: '',
    timeZoneOfStation: 'GMT+8:00',
    addressOfStation: '',
    longitudeOfStation: 0,
    latitudeOfStation: 0,
    timesOfUseAMapGeolocation: 0,
    priceListOfStation: [
        {
            interval: 0,
            name: '峰',
            price: ''
        },
        {
            interval: 1,
            name: '平',
            price: ''
        },
        {
            interval: 2,
            name: '谷',
            price: ''
        }
    ],
    collectorListOfStation: [],
    collectorExpandedListOfStation: [],
    deviceListOfStation: [],
    visibilityOfModalForCollectorForm: false,
    visibilityOfModalToDeleteCollector: false,
    originalCollectorToControl: null,
    collectorToControl: {
        collectId: '',
        collectName: '',
        collectType: 1,
        collectStatus: 0,
        collectVersion: ''
    },
    visibilityOfModalForDeviceForm: false,
    visibilityOfModalToDeleteDevice: false,
    originalDeviceToControl: null,
    deviceToControl: {
        indexOfDeviceList: 0,
        deviceDriver: '',
        deviceType: '',
        deviceName: '',
        deviceAddress: ''
    }
}, action) => {
    switch(action.type) {
        case 'INIT_STATION_INFO_FORM':
            return Object.assign({}, state, {
                ...action.payload
            });
        case 'CHANGE_NAME_OF_STATION':
            return Object.assign({}, state, {
                nameOfStation: action.payload.nameOfStation
            });
        case 'CHANGE_CREATION_TIME_OF_STATION':
            return Object.assign({}, state, {
                creationTimeObjOfStation: action.payload.creationTimeObjOfStation,
                creationTimeOfStation: action.payload.creationTimeOfStation
            });
        case 'CHANGE_INSTALLED_CAPACITY_OF_STATION':
            return Object.assign({}, state, {
                installedCapacityOfStation: action.payload.installedCapacityOfStation
            });
        case 'CHANGE_TIME_ZONE_OF_STATION':
            return Object.assign({}, state, {
                timeZoneOfStation: action.payload.timeZoneOfStation
            });
        case 'CHANGE_ADDRESS_DETAILS_OF_STATION':
            return Object.assign({}, state, {
                addressOfStation: action.payload.addressOfStation
            });
        case 'CHANGE_LONGITUDE_OF_STATION':
            return Object.assign({}, state, {
                longitudeOfStation: action.payload.longitudeOfStation
            });
        case 'CHANGE_LATITUDE_OF_STATION':
            return Object.assign({}, state, {
                latitudeOfStation: action.payload.latitudeOfStation
            });
        case 'CHANGE_ADDRESS_OF_STATION':
            return Object.assign({}, state, {
                addressOfStation: action.payload.addressOfStation,
                longitudeOfStation: action.payload.longitudeOfStation,
                latitudeOfStation: action.payload.latitudeOfStation
            });
        case 'SET_TIMES_OF_USE_AMAP_GEOLOCATION':
            return Object.assign({}, state, {
                timesOfUseAMapGeolocation: action.payload.timesOfUseAMapGeolocation
            });
        case 'CHANGE_PRICE_LIST_OF_STATION':
            return Object.assign({}, state, {
                priceListOfStation: action.payload.priceListOfStation
            });
        case 'TOGGLE_COLLECTOR_RECORD_OF_STATION':
            return Object.assign({}, state, {
                collectorExpandedListOfStation: action.payload.collectorExpandedListOfStation
            });
        case 'TOGGLE_COLLECTOR_FORM_DRAWER':
            return Object.assign({}, state, {
                visibilityOfModalForCollectorForm: action.payload.visibilityOfModalForCollectorForm
            });
        case 'TOGGLE_DELETE_COLLECTOR_MODAL':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteCollector: action.payload.visibilityOfModalToDeleteCollector
            });
        case 'CHANGE_COLLECTOR_LIST_OF_STATION':
            return Object.assign({}, state, {
                collectorListOfStation: action.payload.collectorListOfStation
            });
        case 'CHANGE_COLLECTOR_TO_CONTROL':
            return Object.assign({}, state, {
                originalCollectorToControl : action.payload.originalCollectorToControl,
                collectorToControl: action.payload.collectorToControl
            });
        case 'TOGGLE_DEVICE_FORM_DRAWER':
            return Object.assign({}, state, {
                visibilityOfModalForDeviceForm: action.payload.visibilityOfModalForDeviceForm
            });
        case 'TOGGLE_DELETE_DEVICE_MODAL':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteDevice: action.payload.visibilityOfModalToDeleteDevice
            });
        case 'CHANGE_DEVICE_TO_CONTROL':
            return Object.assign({}, state, {
                originalDeviceToControl : action.payload.originalDeviceToControl,
                deviceToControl: action.payload.deviceToControl
            });
        case 'CHANGE_DEVICE_LIST_OF_STATION':
            return Object.assign({}, state, {
                deviceListOfStation: action.payload.deviceListOfStation
            });
        case 'RESET_STATION_INFO_FORM':
            return Object.assign({}, state, {
                nameOfStation: action.payload.nameOfStation,
                creationTimeObjOfStation: action.payload.creationTimeObjOfStation,
                creationTimeOfStation: action.payload.creationTimeOfStation,
                installedCapacityOfStation: action.payload.installedCapacityOfStation,
                timeZoneOfStation: action.payload.timeZoneOfStation,
                addressOfStation: action.payload.addressOfStation,
                longitudeOfStation: action.payload.longitudeOfStation,
                latitudeOfStation: action.payload.latitudeOfStation,
                priceListOfStation: action.payload.priceListOfStation,
                collectorListOfStation: action.payload.collectorListOfStation,
                deviceListOfStation: action.payload.deviceListOfStation
            });
        default:
            return state;
    }
}

/**
 * Navigation of Station Pages
 */
export const navOfStation = (state = {
    indexOfMouseHover: 0
}, action) => {
    switch(action.type) {
        case 'REJECT_SECONDARY_NAVIGATION':
            return Object.assign({}, state, {
                indexOfMouseHover: action.payload.indexOfMouseHover
            });
        case 'COLLAPSE_SECONDARY_NAVIGATION':
            return Object.assign({}, state, {
                indexOfMouseHover: action.payload.indexOfMouseHover
            });
        default:
            return state;
    }
}

/**
 * Tab Card
 */
export const tabCard = (state = {
    indexOfCardDisplayed: 0,
}, action) => {
    switch(action.type) {
        case 'CHANGE_INDEX_OF_CARD_DISPLAYED':
            return Object.assign({}, state, {
                indexOfCardDisplayed: action.payload.indexOfCardDisplayed,
            });
        default:
            return state;
    }
}
