/**
 * common
 */
const ofCommon = {
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    INITIAL_BASIC_INFORMATION: 'INITIAL_BASIC_INFORMATION',
    LOGOUT: 'LOGOUT',
    CHANGE_STATION_ID_TO_CONTROL: 'CHANGE_STATION_ID_TO_CONTROL',
    SET_ALL_DEVICE_TYPE_LIST: 'SET_ALL_DEVICE_TYPE_LIST',
    SET_DEVICE_TYPE_LIST: 'SET_DEVICE_TYPE_LIST',
    // CHANGE_NAME_AND_LOGO_OF_PLATFORM: 'CHANGE_NAME_AND_LOGO_OF_PLATFORM',
    CHANGE_NAME_OF_PLATFORM: 'CHANGE_NAME_OF_PLATFORM',
    CHANGE_LOGO_OF_PLATFORM: 'CHANGE_LOGO_OF_PLATFORM',
    CHANGE_USER_AVATAR: 'CHANGE_USER_AVATAR',
    TOGGLE_LOADING: 'TOGGLE_LOADING',
}

/**
 * login page
 */
const ofLogin = {
    INIT_LOGIN_FORM: 'INIT_LOGIN_FORM',
    CHANGE_USERNAME: 'CHANGE_USERNAME',
    TOGGLE_VISIBILITY_OF_PASSWORD: 'TOGGLE_VISIBILITY_OF_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    TOGGLE_IS_REMEMBER_PASSWORD: 'TOGGLE_IS_REMEMBER_PASSWORD',
    LOGIN_SUCCESSFULLY: 'LOGIN_SUCCESSFULLY',
    TOGGLE_FORGET_PASSWORD: 'TOGGLE_FORGET_PASSWORD',
    RETURN_TO_LOGIN: 'RETURN_TO_LOGIN',
    CHANGE_USERNAME_TO_VERIFY: 'CHANGE_USERNAME_TO_VERIFY',
    TOGGLE_IS_SEND_VERIFICATION_CODE: 'TOGGLE_IS_SEND_VERIFICATION_CODE',
    CLEAR_POLLING_OF_PROMPT_OF_VERIFY: 'CLEAR_POLLING_OF_PROMPT_OF_VERIFY',
    SET_POLLING_OF_PROMPT_OF_VERIFY: 'SET_POLLING_OF_PROMPT_OF_VERIFY',
    SET_PROMPT_OF_VERIFY: 'SET_PROMPT_OF_VERIFY',
    CHANGE_VERIFICATION_CODE: 'CHANGE_VERIFICATION_CODE',
    SET_CERTIFICATE_OF_RESET_PASSWORD: 'SET_CERTIFICATE_OF_RESET_PASSWORD',
    INPUT_NEW_PASSWORD: 'INPUT_NEW_PASSWORD',
    TOGGLE_VISIBILITY_OF_NEW_PASSWORD: 'TOGGLE_VISIBILITY_OF_NEW_PASSWORD',
    INPUT_NEW_PASSWORD_TO_CONFIRM: 'INPUT_NEW_PASSWORD_TO_CONFIRM',
    TOGGLE_VISIBILITY_OF_NEW_PASSWORD_TO_CONFIRM: 'TOGGLE_VISIBILITY_OF_NEW_PASSWORD_TO_CONFIRM'
}

/**
 * personal center page
 */
const ofPersonalCenter = {
    INIT_PERSONAL_DETAILS: 'INIT_PERSONAL_DETAILS',
    TOGGLE_ENABLE_TO_EDIT_USER_BASIC_INFO: 'TOGGLE_ENABLE_TO_EDIT_USER_BASIC_INFO',
    SET_USER_BASIC_INFO: 'SET_USER_BASIC_INFO',
    TOGGLE_MODAL_TO_CHANGE_USER_AVATAR: 'TOGGLE_MODAL_TO_CHANGE_USER_AVATAR',
    TOGGLE_UPLOAD_USER_AVATAR: 'TOGGLE_UPLOAD_USER_AVATAR',
    SUCCESSFULLY_UPLOAD_USER_AVATAR: 'SUCCESSFULLY_UPLOAD_USER_AVATAR',
    TOGGLE_MODAL_TO_CHANGE_USER_PASSWORD: 'TOGGLE_MODAL_TO_CHANGE_USER_PASSWORD',
    CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PASSWORD: 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PASSWORD',
    TOGGLE_MODAL_TO_CHANGE_USER_PHONE: 'TOGGLE_MODAL_TO_CHANGE_USER_PHONE',
    CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PHONE: 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PHONE',
    TOGGLE_MODAL_TO_CHANGE_USER_EMAIL: 'TOGGLE_MODAL_TO_CHANGE_USER_EMAIL',
    CHANGE_DATA_OF_FORM_TO_CHANGE_USER_EMAIL: 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_EMAIL',
    SET_POLLING_MAILBOX_NEED_TO_BIND: 'SET_POLLING_MAILBOX_NEED_TO_BIND',
    SET_SECONDS_OF_GET_VERIFY_CODE_AGAIN: 'SET_SECONDS_OF_GET_VERIFY_CODE_AGAIN'
}

/**
 * admin pages
 */
// power station List
const ofStationList = {
    QUERY_STATION_LIST: 'QUERY_STATION_LIST',
    QUERY_STATION_LIST_COUNT: 'QUERY_STATION_LIST_COUNT',
    // CHANGE_TYPE_USED_TO_QUERY_STATION: 'CHANGE_TYPE_USED_TO_QUERY_STATION',
    CHANGE_NAME_USED_TO_QUERY_STATION: 'CHANGE_NAME_USED_TO_QUERY_STATION',
    RESET_PARAMS_USED_TO_QUERY_STATION: 'RESET_PARAMS_USED_TO_QUERY_STATION',
    CHANGE_PAGE_NUM_USED_TO_QUERY_STATION: 'CHANGE_PAGE_NUM_USED_TO_QUERY_STATION',
    TOGGLE_DELETE_MODAL: 'TOGGLE_DELETE_MODAL',
    TOGGLE_UPGRADE_MODAL: 'TOGGLE_UPGRADE_MODAL',
    UPGRADE_STATION: 'UPGRADE_STATION',
    TOGGLE_MODAL_TO_UPLOAD_PICTURE_OF_STATION: 'TOGGLE_MODAL_TO_UPLOAD_PICTURE_OF_STATION',
    TOGGLE_LOAD_PICTURE_OF_STATION_CONTROLLED: 'TOGGLE_LOAD_PICTURE_OF_STATION_CONTROLLED',
    SUCCESSFULLY_LOAD_PICTURE_OF_STATION_CONTROLLED: 'SUCCESSFULLY_LOAD_PICTURE_OF_STATION_CONTROLLED'
};

// account manage
const ofAccountManage = {
    TOGGLE_DRAWER_TO_ADD_ACCOUNT: 'TOGGLE_DRAWER_TO_ADD_ACCOUNT',
    SET_FORM_DATA_TO_ADD_ACCOUNT: 'SET_FORM_DATA_TO_ADD_ACCOUNT',
    QUERY_ACCOUNT_LIST: 'QUERY_ACCOUNT_LIST',
    CHANGE_USERNAME_USED_TO_QUERY_ACCOUNT: 'CHANGE_USERNAME_USED_TO_QUERY_ACCOUNT',
    CHANGE_PAGE_NUM_USED_TO_QUERY_ACCOUNT: 'CHANGE_PAGE_NUM_USED_TO_QUERY_ACCOUNT',
    CHANGE_PAGE_SIZE_USED_TO_QUERY_ACCOUNT: 'CHANGE_PAGE_SIZE_USED_TO_QUERY_ACCOUNT',
    RESET_PARAMS_USED_TO_QUERY_ACCOUNT: 'RESET_PARAMS_USED_TO_QUERY_ACCOUNT',
    CHANGE_ACCOUNT_INFO_TO_CONTROL: 'CHANGE_ACCOUNT_INFO_TO_CONTROL',
    TOGGLE_MODAL_TO_EDIT_ACCOUNT: 'TOGGLE_MODAL_TO_EDIT_ACCOUNT',
    SET_FORM_DATA_USED_TO_EDIT_ACCOUNT: 'SET_FORM_DATA_USED_TO_EDIT_ACCOUNT',
    TOGGLE_RESET_ACCOUNT_PASSWORD_MODEL: 'TOGGLE_RESET_ACCOUNT_PASSWORD_MODEL',
    RESET_ACCOUNT_PASSWORD: 'RESET_ACCOUNT_PASSWORD',
    TOGGLE_ALLOCATE_STATION_DRAWER: 'TOGGLE_ALLOCATE_STATION_DRAWER',
    QUERY_STATION_LIST_USED_TO_ALLOCATE: 'QUERY_STATION_LIST_USED_TO_ALLOCATE',
    CHANGE_OWNED_STATION_ID_LIST: 'CHANGE_OWNED_STATION_ID_LIST',
    TOGGLE_DELETE_ACCOUNT_MODEL: 'TOGGLE_DELETE_ACCOUNT_MODEL',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT'
}

// Operation Log
const ofOperationLog = {
    SET_OPERATION_LIST: 'SET_OPERATION_LIST',
    CHANGE_KEYWORDS_USED_TO_QUERY_OPERATIONS: 'CHANGE_KEYWORDS_USED_TO_QUERY_OPERATIONS',
    CHANGE_PAGE_NUM_USED_TO_QUERY_OPERATIONS: 'CHANGE_PAGE_NUM_USED_TO_QUERY_OPERATIONS',
    CHANGE_PAGE_SIZE_USED_TO_QUERY_OPERATIONS: 'CHANGE_PAGE_SIZE_USED_TO_QUERY_OPERATIONS',
};

// Collector Upgrade
const ofCollectorUpgrade = {
    TOOGLE_DISPLAY_MODE: 'TOOGLE_DISPLAY_MODE',
    QUERY_TABLE_DATA: 'QUERY_TABLE_DATA',
    TOGGLE_VISIBILITY_OF_MODAL_TO_ADD_FILE: 'TOGGLE_VISIBILITY_OF_MODAL_TO_ADD_FILE',
    CHANGE_COLLECTOR_STATUS_USED_TO_QUERY_DATA: 'CHANGE_COLLECTOR_STATUS_USED_TO_QUERY_DATA',
    CHANGE_UPGRADE_FILE_TYPE_USED_TO_QUERY_DATA: 'CHANGE_UPGRADE_FILE_TYPE_USED_TO_QUERY_DATA',
    CHANGE_KEYWORDS_USED_TO_QUERY_DATA: 'CHANGE_KEYWORDS_USED_TO_QUERY_DATA',
    RESET_QUERY_PARAMETERS: 'RESET_QUERY_PARAMETERS',
    CHANGE_PAGE_NUM_USED_TO_QUERY_DATA: 'CHANGE_PAGE_NUM_USED_TO_QUERY_DATA',
    CHANGE_PAGE_SIZE_USED_TO_QUERY_DATA: 'CHANGE_PAGE_SIZE_USED_TO_QUERY_DATA',
    CHANGE_FORM_DATA_TO_UPLOAD_FILE: 'CHANGE_FORM_DATA_TO_UPLOAD_FILE',
    TOGGLE_VISIBILITY_OF_MODAL_TO_DELETE_FILE: 'TOGGLE_VISIBILITY_OF_MODAL_TO_DELETE_FILE',
    CHANGE_FILE_INFO_TO_CONTROL: 'CHANGE_FILE_INFO_TO_CONTROL',
    TOGGLE_VISIBILITY_OF_MODAL_TO_DEVICE_UPGRADE: 'TOGGLE_VISIBILITY_OF_MODAL_TO_DEVICE_UPGRADE',
    CHANGE_COLLECTOR_INFO_TO_CONTROL: 'CHANGE_COLLECTOR_INFO_TO_CONTROL',
    CHANGE_FORM_DATA_USED_TO_DEVICE_UPGRADE: 'CHANGE_FORM_DATA_USED_TO_DEVICE_UPGRADE',
    QUERY_FILE_LIST_USED_TO_DEVICE_UPGRADE: 'QUERY_FILE_LIST_USED_TO_DEVICE_UPGRADE',
    TOGGLE_MODAL_TO_NET_Penetrate: 'TOGGLE_MODAL_TO_NET_Penetrate',
    CHANGE_FORM_DATA_OF_NET_PENETRATION: 'CHANGE_FORM_DATA_OF_NET_PENETRATION',
};

// page setup
const ofPageSetup = {
    INIT_PAGE_SETUP: 'INIT_PAGE_SETUP',
    CHANGE_PLATFORM_NAME: 'CHANGE_PLATFORM_NAME',
    TOGGLE_UPLOAD_PLATFORM_LOGO: 'TOGGLE_UPLOAD_PLATFORM_LOGO',
    SUCCESSFULLY_UPLOAD_PLATFORM_LOGO: 'SUCCESSFULLY_UPLOAD_PLATFORM_LOGO',
    RESET_PLATFORM_INFORMATION: 'RESET_PLATFORM_INFORMATION'
};

/**
 * station pages
 */
// Common Actions Of Stations
const ofStation = {
    SET_DAILY_EXIST_ALARM_NUM_OF_STATION: 'SET_DAILY_EXIST_ALARM_NUM_OF_STATION',
    QUERY_COLLECTOR_LIST_OF_STATION: 'QUERY_COLLECTOR_LIST_OF_STATION',
    CHANGE_COLLECTOR_ID_TO_CONTROL: 'CHANGE_COLLECTOR_ID_TO_CONTROL',
    SET_SUB_DEVICE_LIST_OF_STATION: 'SET_SUB_DEVICE_LIST_OF_STATION',
    CHANGE_DEVICE_ID_TO_CONTROL: 'CHANGE_DEVICE_ID_TO_CONTROL',
    CHANGE_DEVICE_NAME_TO_CONTROL: 'CHANGE_DEVICE_NAME_TO_CONTROL',
    SET_NAVIGATION_OF_DEVICE: 'SET_NAVIGATION_OF_DEVICE',
    CHANGE_INDEX_OF_STATION_SECONDARY_NAV: 'CHANGE_INDEX_OF_STATION_SECONDARY_NAV',
    CHANGE_SELECTED_KEY_OF_STATION_NAV: 'CHANGE_SELECTED_KEY_OF_STATION_NAV',
    TOGGLE_VISIBILITY_OF_REALTIME_ALARM_LIST: 'TOGGLE_VISIBILITY_OF_REALTIME_ALARM_LIST',
    CHANGE_REALTIME_ALARM_LIST: 'CHANGE_REALTIME_ALARM_LIST',
    SET_STATE_OF_HAVE_SECONDARY_PASSWORD: 'SET_STATE_OF_HAVE_SECONDARY_PASSWORD',
    SET_WEB_SOCKET_OF_REAL_ALARM: 'SET_WEB_SOCKET_OF_REAL_ALARM',
    RESET_WEB_SOCKET_OF_REAL_ALARM: 'RESET_WEB_SOCKET_OF_REAL_ALARM',
    SET_DEVICE_TYPE_TO_CONTROL: 'SET_DEVICE_TYPE_TO_CONTROL',
    SET_OPEN_KEYS_OF_STATION_NAV: 'SET_OPEN_KEYS_OF_STATION_NAV',
}

// Microgrid Monitoring
const ofMicronetMonitoring = {
    SET_CANVAS_OF_CONCEPT_MAP: 'SET_CANVAS_OF_CONCEPT_MAP',
    QUERY_MONITORING_DATA_OF_COLLECTOR: 'QUERY_MONITORING_DATA_OF_COLLECTOR',
    SET_POLLING_OF_DRAW_CONCEPT_MAP: 'SET_POLLING_OF_DRAW_CONCEPT_MAP',
    SET_TIMES_OF_DRAW_CONCEPT_MAP: 'SET_TIMES_OF_DRAW_CONCEPT_MAP'
};

// Run Strategy
const ofRunStrategy = {
    QUERY_TYPE_AND_STRATEGY_OF_COLLECTOR: 'QUERY_TYPE_AND_STRATEGY_OF_COLLECTOR',
    SET_MAX_CAPACITY: 'SET_MAX_CAPACITY',
    SET_BATTERY_SOC_DATA_OF_COLLECTOR: 'SET_BATTERY_SOC_DATA_OF_COLLECTOR',
    SET_PV_ELECTRIC_PRICE_OF_COLLECTOR: 'SET_PV_ELECTRIC_PRICE_OF_COLLECTOR',
    SET_ELECTRIC_PRICE_DATA_OF_COLLECTOR: 'SET_ELECTRIC_PRICE_DATA_OF_COLLECTOR',
    SET_SPFV_DATA_OF_COLLECTOR: 'SET_SPFV_DATA_OF_COLLECTOR',
    CHANGE_STRATEGY_PATTERN: 'CHANGE_STRATEGY_PATTERN',
    SUBMIT_COLLECTOR_AND_BATTERY_SOC: 'SUBMIT_COLLECTOR_AND_BATTERY_SOC',
    RESET_COLLECTOR_AND_BATTERY_SOC: 'RESET_COLLECTOR_AND_BATTERY_SOC',
    TOGGLE_SELECT_OF_SPFV_TYPE: 'TOGGLE_SELECT_OF_SPFV_TYPE',
    SELECT_SPFV_TYPE: 'SELECT_SPFV_TYPE',
    INPUT_SECONDARY_PASSWORD: 'INPUT_SECONDARY_PASSWORD',
    TOOGLE_MODAL_TO_CHANGE_SECONDARY_PASSWORD: 'TOOGLE_MODAL_TO_CHANGE_SECONDARY_PASSWORD',
    INPUT_ORIGINAL_SECONDARY_PASSWORD: 'INPUT_ORIGINAL_SECONDARY_PASSWORD',
    TOOGLE_MODAL_TO_RESET_SECONDARY_PASSWORD: 'TOOGLE_MODAL_TO_RESET_SECONDARY_PASSWORD',
    TOOGLE_MODAL_TO_VERIFY_SECONDARY_PASSWORD: 'TOOGLE_MODAL_TO_VERIFY_SECONDARY_PASSWORD',
    INPUT_SECONDARY_PASSWORD_TO_VERIFY: 'INPUT_SECONDARY_PASSWORD_TO_VERIFY',
    QUERY_BASIC_INFO_OF_TYPE6: 'QUERY_BASIC_INFO_OF_TYPE6',
    CHANGE_BASIC_INFO_OF_TYPE6: 'CHANGE_BASIC_INFO_OF_TYPE6',
    CHANGE_FORM_DATA_OF_RUNNING_MODE_OF_TYPE6: 'CHANGE_FORM_DATA_OF_RUNNING_MODE_OF_TYPE6',
    CHANGE_PERIOD_LIST_OF_MODE2_OF_TYPE6: 'CHANGE_PERIOD_LIST_OF_MODE2_OF_TYPE6',
    OPEN_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET: 'OPEN_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET',
    CLOSE_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET: 'CLOSE_DRAWER_FOR_DATA_LIST_OF_ADVANCED_SET',
    SET_UL_LIMITS_OF_TH_IN_CABINET_OF_TYPE6: 'SET_UL_LIMITS_OF_TH_IN_CABINET_OF_TYPE6',
    SET_BATTERY_INFOS_OF_TYPE6: 'SET_BATTERY_INFOS_OF_TYPE6',
    CHANGE_FORM_DATA_OF_AC_COUPLE_SET_OF_TYPE6: 'CHANGE_FORM_DATA_OF_AC_COUPLE_SET_OF_TYPE6',
};

/**
 * large screen monitoring
 */
const ofLargeScreenMonitoring = {
    CHANGE_CURRENT_TIME: 'CHANGE_CURRENT_TIME',
    INIT_LARGE_SCREEN_MONITORING: 'INIT_LARGE_SCREEN_MONITORING',
    CHANGE_PAGE_NUMBER_OF_DEVICE: 'CHANGE_PAGE_NUMBER_OF_DEVICE',
}

/**
 * device monitoring
 */
// PCS
// const ofPCS = {
//     SET_DATA_ONLY_DISPLAYED_OF_PCS: 'SET_DATA_ONLY_DISPLAYED_OF_PCS',
//     CHANGE_INDEX_OF_RUNNING_STATE_CARD_DISPLAYED_IN_PCS: 'CHANGE_INDEX_OF_RUNNING_STATE_CARD_DISPLAYED_IN_PCS',
//     SET_OPTION_OF_ENERGY_TREND_CHART_IN_PCS: 'SET_OPTION_OF_ENERGY_TREND_CHART_IN_PCS',
//     SET_REAL_PARAMETER_LIST_OF_PCS: 'SET_REAL_PARAMETER_LIST_OF_PCS',
//     TOGGLE_HISTORY_DATA_DRAWER_OF_PCS: 'TOGGLE_HISTORY_DATA_DRAWER_OF_PCS',
//     TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_PCS: 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_PCS',
//     GENERATE_HISTORY_DATA_TABLE_OF_PCS: 'GENERATE_HISTORY_DATA_TABLE_OF_PCS',
//     CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_PCS: 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_PCS',
//     TOGGLE_SEND_DOWN_DRAWER_OF_PCS: 'TOGGLE_SEND_DOWN_DRAWER_OF_PCS',
//     CHANGE_FORM_DATA_TO_SEND_DOWN_OF_PCS: 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_PCS',
//     CHANGE_DATA_TO_SEND_DOWN_OF_PCS: 'CHANGE_DATA_TO_SEND_DOWN_OF_PCS'
// }

// GSE
const ofGSE = {
    SET_DATA_ONLY_DISPLAYED_OF_GSE: 'SET_DATA_ONLY_DISPLAYED_OF_GSE',
    SET_OPTION_OF_ENERGY_TREND_CHART_IN_GSE: 'SET_OPTION_OF_ENERGY_TREND_CHART_IN_GSE',
    SET_REAL_PARAMETER_LIST_OF_GSE: 'SET_REAL_PARAMETER_LIST_OF_GSE',
    TOGGLE_HISTORY_DATA_DRAWER_OF_GSE: 'TOGGLE_HISTORY_DATA_DRAWER_OF_GSE',
    TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_GSE: 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_GSE',
    GENERATE_HISTORY_DATA_TABLE_OF_GSE: 'GENERATE_HISTORY_DATA_TABLE_OF_GSE',
    CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_GSE: 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_GSE',
    TOGGLE_SEND_DOWN_DRAWER_OF_GSE: 'TOGGLE_SEND_DOWN_DRAWER_OF_GSE',
    CHANGE_FORM_DATA_TO_SEND_DOWN_OF_GSE: 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_GSE',
    CHANGE_DATA_TO_SEND_DOWN_OF_GSE: 'CHANGE_DATA_TO_SEND_DOWN_OF_GSE',
    GENERATE_HISTORY_DATA_TABLE_OF_GSE_NEW: 'GENERATE_HISTORY_DATA_TABLE_OF_GSE_NEW',
    SET_REAL_ALARM_OF_PCS: 'SET_REAL_ALARM_OF_PCS',
    CHANGE_DATA_TO_ALL_SEND_DOWN_OF_GSE: 'CHANGE_DATA_TO_ALL_SEND_DOWN_OF_GSE',
    TOGGLE_MODAL_TO_SEND_FORM_OF_SD: 'TOGGLE_MODAL_TO_SEND_FORM_OF_SD',
    CHANGE_DATA_TO_SEND_FORM_OF_SD: 'CHANGE_DATA_TO_SEND_FORM_OF_SD',
    TOGGLE_MODAL_TO_SEND_CONTROL_BIT: 'TOGGLE_MODAL_TO_SEND_CONTROL_BIT',
    CHANGE_CURRENT_CONTROL_BIT: 'CHANGE_CURRENT_CONTROL_BIT',
    CHANGE_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS: 'CHANGE_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS',
    CHANGE_FORM_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS: 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_CONTROL_BIT_IN_PCS',

    TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING: 'TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING',
    CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING: 'CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING',
    CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING: 'CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING',
}

// battery
const ofBattery = {
    SET_BRANCHES_OF_BATTERY: 'SET_BRANCHES_OF_BATTERY',
    SET_DATA_ONLY_DISPLAYED_OF_BATTERY: 'SET_DATA_ONLY_DISPLAYED_OF_BATTERY',
    SET_DATA_OF_BATTERY_SOC_CURVE: 'SET_DATA_OF_BATTERY_SOC_CURVE',
    SET_REAL_PARAMETER_LIST_OF_BATTERY: 'SET_REAL_PARAMETER_LIST_OF_BATTERY',
    SET_DATA_OF_BATTERY_ENERGY_TREND_CHART: 'SET_DATA_OF_BATTERY_ENERGY_TREND_CHART',
    TOGGLE_HISTORY_DATA_DRAWER_OF_BATTERY: 'TOGGLE_HISTORY_DATA_DRAWER_OF_BATTERY',
    TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_BATTERY: 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_BATTERY',
    CHANGE_SON_ID_TO_QUERY_HISTORY_DATA_OF_BATTERY_BOX: 'CHANGE_SON_ID_TO_QUERY_HISTORY_DATA_OF_BATTERY_BOX',
    CHANGE_START_TIME_OF_HISTORY_TABLE_IN_BATTERY_DRAWER: 'CHANGE_START_TIME_OF_HISTORY_TABLE_IN_BATTERY_DRAWER',
    GENERATE_HISTORY_TABLE_OF_DRAWER_IN_BATTERY: "GENERATE_HISTORY_TABLE_OF_DRAWER_IN_BATTERY",
    CHANGE_PAGE_NUM_OF_HISTORY_TABLE_IN_BATTERY_DRAWER: "CHANGE_PAGE_NUM_OF_HISTORY_TABLE_IN_BATTERY_DRAWER",
    GENERATE_HISTORY_DATA_TABLE_OF_DRAWER_IN_BATTERY: 'GENERATE_HISTORY_DATA_TABLE_OF_DRAWER_IN_BATTERY',
    SET_HISTORY_TABLE_OF_DRAWER_IN_BATTERY: 'SET_HISTORY_TABLE_OF_DRAWER_IN_BATTERY',
    SET_SYSTEM_PARAMETERS_OF_BATTERY_TBD: 'SET_SYSTEM_PARAMETERS_OF_BATTERY_TBD',
    SET_REAL_ALARM_OF_BATTERY: 'SET_REAL_ALARM_OF_BATTERY',
    TOGGLE_MODAL_TO_SEND_DOWN_IN_BATTERY: 'TOGGLE_MODAL_TO_SEND_DOWN_IN_BATTERY',
    SET_SEND_DOWN_LIST_OF_BATTERY: 'SET_SEND_DOWN_LIST_OF_BATTERY',
    CHANGE_FORM_DATA_TO_SEND_DOWN_OF_BATTERY: 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_BATTERY',
    CHANGE_SLAVE_ID_USED_TO_CONTROL: 'CHANGE_SLAVE_ID_USED_TO_CONTROL',
}

// energy storage tank
const ofEnergyStorageTank = {
    SET_DATA_ONLY_DISPLAYED_OF_ENERGY: 'SET_DATA_ONLY_DISPLAYED_OF_ENERGY',
    INIT_DATA_OF_ENERGY: 'INIT_DATA_OF_ENERGY',
}

// KDC
const ofKDC = {
    SET_DATA_ONLY_DISPLAYED_OF_KDC: 'SET_DATA_ONLY_DISPLAYED_OF_KDC',
    SET_DATA_OF_ENERGY_TREND_BAR_IN_KDC: 'SET_DATA_OF_ENERGY_TREND_BAR_IN_KDC',
    SET_DATA_OF_INPUT_CURVE_IN_KDC: 'SET_DATA_OF_INPUT_CURVE_IN_KDC',
    SET_DATA_OF_OUTPUT_CURVE_IN_KDC: 'SET_DATA_OF_OUTPUT_CURVE_IN_KDC',
    SET_REAL_PARAMETER_LIST_OF_KDC: 'SET_REAL_PARAMETER_LIST_OF_KDC',
    TOGGLE_HISTORY_DATA_DRAWER_OF_KDC: 'TOGGLE_HISTORY_DATA_DRAWER_OF_KDC',
    TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_KDC: 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_KDC',
    GENERATE_HISTORY_DATA_TABLE_OF_KDC: 'GENERATE_HISTORY_DATA_TABLE_OF_KDC',
    CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_KDC: 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_KDC',
    SET_SLAVE_DATA_OF_KDC: 'SET_SLAVE_DATA_OF_KDC',
    TOGGLE_SEND_DOWN_DRAWER_OF_KDC: 'TOGGLE_SEND_DOWN_DRAWER_OF_KDC',
    CHANGE_DATA_TO_SEND_DOWN_OF_KDC: 'CHANGE_DATA_TO_SEND_DOWN_OF_KDC',
    CHANGE_FORM_DATA_TO_SEND_DOWN_OF_KDC: 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_KDC'
}

// combiner box
const ofCombinerBox = {
    SET_REAL_PARAMETER_LIST_OF_COMBINER_BOX: 'SET_REAL_PARAMETER_LIST_OF_COMBINER_BOX',
    SET_REAL_ALARM_OF_COMBINER_BOX: 'SET_REAL_ALARM_OF_COMBINER_BOX',
    SET_DATA_OF_BRANCH_CURRENT_CURVE_IN_COMBINER_BOX: 'SET_DATA_OF_BRANCH_CURRENT_CURVE_IN_COMBINER_BOX'
}

// charging pile
const ofChargingPile = {
    SET_DATA_OF_CHARGING_PILE_LIST: 'SET_DATA_OF_CHARGING_PILE_LIST'
}

// PV Inverter
const ofPVInverter = {
    QUERY_BASIC_DATA_IN_PV_INVERTER: 'QUERY_BASIC_DATA_IN_PV_INVERTER',
    QUERY_DATA_OF_GENERATION_POWER_CHART_IN_PV_INVERTER: 'QUERY_DATA_OF_GENERATION_POWER_CHART_IN_PV_INVERTER',
    QUERY_DEVICE_LIST_IN_PV_INVERTER: 'QUERY_DEVICE_LIST_IN_PV_INVERTER',
    CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_IN_PV_INVERTER: 'CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_IN_PV_INVERTER',
    CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_IN_PV_INVERTER: 'CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_IN_PV_INVERTER',
    TOGGLE_MODAL_TO_DATA_STATISTICS_IN_PV_INVERTER: 'TOGGLE_MODAL_TO_DATA_STATISTICS_IN_PV_INVERTER',
    CHANGE_SN_OF_STATISTICS_TAB_SELECTED_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_SN_OF_STATISTICS_TAB_SELECTED_OF_DEVICE_IN_PV_INVERTER',
    QUERY_DATA_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER: 'QUERY_DATA_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER',
    CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_TYPE_CODE_OF_DATE_PICKER_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER',
    CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_START_TIME_OBJ_OF_GENERATION_POWER_CHART_OF_DEVICE_IN_PV_INVERTER',
    QUERY_DATA_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER: 'QUERY_DATA_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER',
    CHANGE_START_TIME_OBJ_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_START_TIME_OBJ_OF_PV_CURVE_OF_DEVICE_IN_PV_INVERTER',
    TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER: 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER',
    GENERATE_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER: 'GENERATE_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER',
    CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER',
    CHANGE_START_TIME_OBJ_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER: 'CHANGE_START_TIME_OBJ_OF_HISTORY_DATA_TABLE_OF_DEVICE_IN_PV_INVERTER',
    TOGGLE_MODAL_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER: 'TOGGLE_MODAL_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER',
    SET_DATA_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER: 'SET_DATA_TO_SEND_DOWN_OF_DEVICE_IN_PV_INVERTER',
    SET_FORM_DATA_TO_SEND_DOWN_OF_Device_IN_PV_INVERTER: 'SET_FORM_DATA_TO_SEND_DOWN_OF_Device_IN_PV_INVERTER'
}

/**
 * Electric Meter
 */
const ofElectricMeter = {
    QUERY_DEVICE_LIST_IN_ELECTRIC_METER: 'QUERY_DEVICE_LIST_IN_ELECTRIC_METER',
    TOGGLE_DRAWER_TO_HISTORY_DATA_IN_ELECTRICITY_METER: 'TOGGLE_DRAWER_TO_HISTORY_DATA_IN_ELECTRICITY_METER',
    RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_ELECTRICITY_METER: 'RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_ELECTRICITY_METER',
    CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_ELECTRICITY_METER: 'CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_ELECTRICITY_METER',
    SET_LOADING_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER: 'SET_LOADING_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER',
    SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER: 'SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER',
    CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER: 'CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_ELECTRICITY_METER',
};

/**
 * Camera
 */
const ofCamera = {
    INIT_DATA_OF_CAMERA: 'INIT_DATA_OF_CAMERA',
    CHANGE_DATE_LIST_OF_PLAYBACK: 'CHANGE_DATE_LIST_OF_PLAYBACK',
    CHANGE_TIME_LIST_OF_PLAYBACK: 'CHANGE_TIME_LIST_OF_PLAYBACK',
    VIEW_PLAYBACK: 'VIEW_PLAYBACK',
};

/**
 * Aircondition
 */
const ofAircondition = {
    QUERY_DEVICE_LIST_IN_AIRCONDITION: 'QUERY_DEVICE_LIST_IN_AIRCONDITION',
    TOGGLE_DRAWER_TO_HISTORY_DATA_IN_AIRCONDITION: 'TOGGLE_DRAWER_TO_HISTORY_DATA_IN_AIRCONDITION',
    RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_AIRCONDITION: 'RESET_QUERY_PARAMETERS_OF_HISTORY_DATA_IN_AIRCONDITION',
    CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_AIRCONDITION: 'CHANGE_START_TIME_MOMENT_OF_HISTORY_DATA_IN_AIRCONDITION',
    SET_LOADING_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION: 'SET_LOADING_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION',
    SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION: 'SET_OPTIONS_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION',
    CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION: 'CHANGE_PAGINATION_OF_HISTORY_DATA_TABLE_IN_AIRCONDITION',
    TOGGLE_MODAL_TO_SEND_DOWN_IN_AIRCONDITION: 'TOGGLE_MODAL_TO_SEND_DOWN_IN_AIRCONDITION',
    SET_SEND_DOWN_LIST_IN_AIRCONDITION: 'SET_SEND_DOWN_LIST_IN_AIRCONDITION',
};

/**
 * Aircondition
 */
const ofSTS = {
    'INITIALIZE_STS': 'INITIALIZE_STS',
    'TOGGLE_HISTORY_DATA_DRAWER_OF_STS': 'TOGGLE_HISTORY_DATA_DRAWER_OF_STS',
    'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_STS': 'TOGGLE_LOADING_OF_HISTORY_DATA_TABLE_IN_STS',
    'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_STS': 'CHANGE_PAGE_NUM_OF_HISTORY_DATA_TABLE_IN_STS',
    'GENERATE_HISTORY_DATA_TABLE_OF_STS': 'GENERATE_HISTORY_DATA_TABLE_OF_STS',
    'TOGGLE_SEND_DOWN_DRAWER_OF_STS': 'TOGGLE_SEND_DOWN_DRAWER_OF_STS',
    'CHANGE_DATA_TO_ALL_SEND_DOWN_OF_STS': 'CHANGE_DATA_TO_ALL_SEND_DOWN_OF_STS',
    'CHANGE_DATA_TO_SEND_DOWN_OF_STS': 'CHANGE_DATA_TO_SEND_DOWN_OF_STS',
    'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_STS': 'CHANGE_FORM_DATA_TO_SEND_DOWN_OF_STS',
    'TOGGLE_MODAL_TO_SEND_FORM_OF_STS': 'TOGGLE_MODAL_TO_SEND_FORM_OF_STS',
    'CHANGE_DATA_TO_SEND_FORM_OF_STS': 'CHANGE_DATA_TO_SEND_FORM_OF_STS',
    'SET_SEND_SINGLE_LIST_OF_STS': 'SET_SEND_SINGLE_LIST_OF_STS',
    'TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING_IN_STS': 'TOGGLE_MODAL_TO_SERIAL_PORT_DEBUGGING_IN_STS',
    'CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING_IN_STS': 'CHANGE_VALUE_OF_SERIAL_PORT_DEBUGGING_IN_STS',
    'CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING_IN_STS': 'CHANGE_RESPONSE_DATA_OF_SERIAL_PORT_DEBUGGING_IN_STS',
};

/**
 * report form
 */
// energy statistics
const ofEnergyStatistics = {
    SET_DATA_OF_ENERGY_STATISTICS_BAR_CHART: 'SET_DATA_OF_ENERGY_STATISTICS_BAR_CHART',
    SET_DATA_of_ENERGY_STATISTICS_LIST: 'SET_DATA_of_ENERGY_STATISTICS_LIST',
    SET_HAS_POWER_DATE_LIST: 'SET_HAS_POWER_DATE_LIST',
    CHANGE_TYPE_CODE_OF_DATA_PICKER_IN_ENERGY_STATISTICS: 'CHANGE_TYPE_CODE_OF_DATA_PICKER_IN_ENERGY_STATISTICS',
    CHANGE_START_TIME_OF_ENERGY_STATISTICS: 'CHANGE_START_TIME_OF_ENERGY_STATISTICS'
}

// fault alarm
const ofFaultAlarm = {
    INIT_PARAMS_USED_TO_QUERY_FAULT: 'INIT_PARAMS_USED_TO_QUERY_FAULT',
    SET_DATA_OF_FAULT_TABLE_GROUP: 'SET_DATA_OF_FAULT_TABLE_GROUP',
    CHANGE_DEVICE_TYPE_CODE_USED_TO_QUERY_FAULT: 'CHANGE_DEVICE_TYPE_CODE_USED_TO_QUERY_FAULT',
    CHANGE_DEVICE_CATEGORY1_CODE_USED_TO_QUERY_FAULT: 'CHANGE_DEVICE_CATEGORY1_CODE_USED_TO_QUERY_FAULT',
    CHANGE_FAULT_STATE_CODE_USED_TO_QUERY_FAULT: 'CHANGE_FAULT_STATE_CODE_USED_TO_QUERY_FAULT',
    CHANGE_START_TIME_USED_TO_QUERY_FAULT: 'CHANGE_START_TIME_USED_TO_QUERY_FAULT',
    CHANGE_END_TIME_USED_TO_QUERY_FAULT: 'CHANGE_END_TIME_USED_TO_QUERY_FAULT',
    CHANGE_DEVICE_NAME_USED_TO_QUERY_FAULT: 'CHANGE_DEVICE_NAME_USED_TO_QUERY_FAULT',
    CHANGE_PAGE_NUM_USED_TO_QUERY_FAULT: 'CHANGE_PAGE_NUM_USED_TO_QUERY_FAULT'
}

// screen logs
const ofScreenLogs = {
    SET_DATA_OF_SO_LOG_TABLE: 'SET_DATA_OF_SO_LOG_TABLE',
    CHANGE_KEYWORDS_USED_TO_QUERY_SCREEN_LOGS: 'CHANGE_KEYWORDS_USED_TO_QUERY_SCREEN_LOGS',
    CHANGE_PAGE_NUMBER_USED_TO_QUERY_SO_LOGS: 'CHANGE_PAGE_NUMBER_USED_TO_QUERY_SO_LOGS',
    SET_DATA_OF_DESCRIPTION_TABLE: 'SET_DATA_OF_DESCRIPTION_TABLE',
    TOGGLE_DRAWER_FOR_DESCRIPTION_TABLE: 'TOGGLE_DRAWER_FOR_DESCRIPTION_TABLE',
    RESET_DESCRIPTIONS: 'RESET_DESCRIPTIONS',
    CHANGE_LOG_ID_USED_TO_QUERY_DESCRIPTIONS: 'CHANGE_LOG_ID_USED_TO_QUERY_DESCRIPTIONS',
    CHANGE_KEYWORDS_USED_TO_QUERY_DESCRIPTIONS: 'CHANGE_KEYWORDS_USED_TO_QUERY_DESCRIPTIONS',
    CHANGE_PAGE_NUMBER_USED_TO_QUERY_DESCRIPTIONS: 'CHANGE_PAGE_NUMBER_USED_TO_QUERY_DESCRIPTIONS',
    TOGGLE_MODAL_FOR_DESCRIPTION_FORM: 'TOGGLE_MODAL_FOR_DESCRIPTION_FORM',
    CHANGE_DATA_OF_DESCRIPTION_FORM: 'CHANGE_DATA_OF_DESCRIPTION_FORM',
    RESET_PAGE_PARAMETERS_OF_DESCRIPTIONS: 'RESET_PAGE_PARAMETERS_OF_DESCRIPTIONS',
    TOGGLE_MODAL_TO_DELETE_DESCRIPTION: 'TOGGLE_MODAL_TO_DELETE_DESCRIPTION',
    RESET_PARAMETERS_OF_DESCRIPTIONS: 'RESET_PARAMETERS_OF_DESCRIPTIONS',
}

/**
 * request
 */


/**
 * component
 */
// Language Dropdown
const ofLangDropdown = {
    // CHANGE_LANGUAGE: 'CHANGE_LANGUAGE'
}

// Station Information Form
const ofStationInfoForm = {
    INIT_STATION_INFO_FORM: 'INIT_STATION_INFO_FORM',
    CHANGE_NAME_OF_STATION: 'CHANGE_NAME_OF_STATION',
    CHANGE_CREATION_TIME_OF_STATION: 'CHANGE_CREATION_TIME_OF_STATION',
    // CHANGE_TYPE_CODE_OF_STATION: 'CHANGE_TYPE_CODE_OF_STATION',
    CHANGE_INSTALLED_CAPACITY_OF_STATION: 'CHANGE_INSTALLED_CAPACITY_OF_STATION',
    CHANGE_TIME_ZONE_OF_STATION: 'CHANGE_TIME_ZONE_OF_STATION',
    CHANGE_ADDRESS_DETAILS_OF_STATION: 'CHANGE_ADDRESS_DETAILS_OF_STATION',
    CHANGE_LONGITUDE_OF_STATION: 'CHANGE_LONGITUDE_OF_STATION',
    CHANGE_LATITUDE_OF_STATION: 'CHANGE_LATITUDE_OF_STATION',
    CHANGE_ADDRESS_OF_STATION: 'CHANGE_ADDRESS_OF_STATION',
    SET_TIMES_OF_USE_AMAP_GEOLOCATION: 'SET_TIMES_OF_USE_AMAP_GEOLOCATION',
    CHANGE_PRICE_LIST_OF_STATION: 'CHANGE_PRICE_LIST_OF_STATION',
    TOGGLE_COLLECTOR_RECORD_OF_STATION: 'TOGGLE_COLLECTOR_RECORD_OF_STATION',
    TOGGLE_COLLECTOR_FORM_DRAWER: 'TOGGLE_COLLECTOR_FORM_DRAWER',
    TOGGLE_DELETE_COLLECTOR_MODAL: 'TOGGLE_DELETE_COLLECTOR_MODAL',
    CHANGE_COLLECTOR_TO_CONTROL: 'CHANGE_COLLECTOR_TO_CONTROL',
    CHANGE_COLLECTOR_LIST_OF_STATION: 'CHANGE_COLLECTOR_LIST_OF_STATION',
    TOGGLE_DEVICE_FORM_DRAWER: 'TOGGLE_DEVICE_FORM_DRAWER',
    TOGGLE_DELETE_DEVICE_MODAL: 'TOGGLE_DELETE_DEVICE_MODAL',
    CHANGE_DEVICE_TO_CONTROL: 'CHANGE_DEVICE_TO_CONTROL',
    CHANGE_DEVICE_LIST_OF_STATION: 'CHANGE_DEVICE_LIST_OF_STATION',
    RESET_STATION_INFO_FORM: 'RESET_STATION_INFO_FORM'
}

// Navigation of Station Pages
const navOfStation = {
    REJECT_SECONDARY_NAVIGATION: 'REJECT_SECONDARY_NAVIGATION',
    COLLAPSE_SECONDARY_NAVIGATION: 'COLLAPSE_SECONDARY_NAVIGATION'
}

// Tab Card
const tabCard = {
    CHANGE_INDEX_OF_CARD_DISPLAYED: 'CHANGE_INDEX_OF_CARD_DISPLAYED',
};

const actionTypes = {
    ...ofCommon,

    login: {
        ...ofLogin
    },

    personalCenter: {
        ...ofPersonalCenter
    },

    admin: {
        stationList: {
            ...ofStationList
        },
        accountManage: {
            ...ofAccountManage
        },
        operationLog: {
            ...ofOperationLog
        },
        collectorUpgrade: {
            ...ofCollectorUpgrade
        },
        pageSetup: {
            ...ofPageSetup
        }
    },

    station: {
        ...ofStation,
        micronetMonitoring: {
            ...ofMicronetMonitoring
        },
        runStrategy: {
            ...ofRunStrategy
        },
        largeScreenMonitoring: { ...ofLargeScreenMonitoring },
        device: {
            // PCS: {
            //     ...ofPCS
            // },
            GSE: { ...ofGSE },
            battery: {
                ...ofBattery
            },
            energyStorageTank: {
                ...ofEnergyStorageTank
            },
            KDC: {
                ...ofKDC
            },
            combinerBox: {
                ...ofCombinerBox
            },
            chargingPile: {
                ...ofChargingPile
            },
            PVInverter: {
                ...ofPVInverter
            },
            electricMeter: {
                ...ofElectricMeter
            },
            camera: {
                ...ofCamera,
            },
            aircondition: {
                ...ofAircondition,
            },
            STS: {
                ...ofSTS,
            },
        },
        reportForm: {
            energyStatistics: { ...ofEnergyStatistics },
            faultAlarm: { ...ofFaultAlarm },
            screenLogs: { ...ofScreenLogs },
        }
    },

    component: {
        langDropdown: {
            ...ofLangDropdown
        },
        stationInfoForm: {
            ...ofStationInfoForm
        },
        navOfStation: {
            ...navOfStation
        },
        tabCard: {
            ...tabCard,
        },
    }
};

export default actionTypes;
