import logo from "../assets/img/svg/logoOfStation.svg";
import avatar from "../assets/img/svg/avatarTest.svg";

const reducerOfCommon = (state = {
    language: localStorage.getItem('language'),
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    isLogined: Boolean(localStorage.getItem('isLogined')) ? Boolean(localStorage.getItem('isLogined')) : Boolean(sessionStorage.getItem('isLogined')),
    userId: localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : 0,
    username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
    // nickname: localStorage.getItem('nickname') ? localStorage.getItem('nickname') : '',
    userLevel: localStorage.getItem('userLevel') ? parseInt(localStorage.getItem('userLevel')) : 0,
    userAvatarURL: localStorage.getItem('userAvatarURL') ? localStorage.getItem('userAvatarURL') : avatar,
    platformName: localStorage.getItem('platformName') ? localStorage.getItem('platformName') : '科士达储能智慧能源管理控制系统',
    pathOfPlatformLogo: localStorage.getItem('pathOfPlatformLogo') ? localStorage.getItem('pathOfPlatformLogo') : logo,
    stationIdToControl: localStorage.getItem('stationIdToControl') ? parseInt(localStorage.getItem('stationIdToControl')) : '',
    allDeviceTypeList: localStorage.getItem('allDeviceTypeList') ? JSON.parse(localStorage.getItem('allDeviceTypeList')) : [],
    allDeviceTypeListOfCascader: localStorage.getItem('allDeviceTypeListOfCascader') ? JSON.parse(localStorage.getItem('allDeviceTypeListOfCascader')) : [],
    deviceDriverEntryObj: localStorage.getItem('deviceDriverEntryObj') ? JSON.parse(localStorage.getItem('deviceDriverEntryObj')) : {},
    deviceTypeList: localStorage.getItem('deviceTypeList') && localStorage.getItem('deviceTypeList') !== 'undefined' ? JSON.parse(localStorage.getItem('deviceTypeList')): [],
    loading: false,
}, action) => {
    switch(action.type) {
        case 'CHANGE_LANGUAGE':
            return Object.assign({}, state, {
                language: action.payload.language
            });
        case 'INITIAL_BASIC_INFORMATION':
            return Object.assign({}, state, {
                token: action.payload.token ? action.payload.token : '',
                isLogined: action.payload.isLogined,
                userId: action.payload.userId ? action.payload.userId : 0,
                username: action.payload.username ? action.payload.username : '',
                userLevel: action.payload.userLevel ? action.payload.userLevel : 0,
                userAvatarURL: action.payload.userAvatarURL ? action.payload.userAvatarURL : avatar,
                platformName: action.payload.platformName ? action.payload.platformName : '科士达储能智慧能源管理控制系统',
                pathOfPlatformLogo: action.payload.pathOfPlatformLogo ? action.payload.pathOfPlatformLogo : logo,
                stationIdToControl: action.payload.stationIdToControl ? action.payload.stationIdToControl : ''
            });
        case 'LOGOUT':
            return Object.assign({}, state, {
                isLogined: action.payload.isLogined
            });
        case 'CHANGE_STATION_ID_TO_CONTROL':
            return Object.assign({}, state, {
                stationIdToControl: action.payload.stationIdToControl
            });
        case 'SET_ALL_DEVICE_TYPE_LIST':
            return Object.assign({}, state, {
                allDeviceTypeList: action.payload.allDeviceTypeList,
                allDeviceTypeListOfCascader: action.payload.allDeviceTypeListOfCascader,
                deviceDriverEntryObj: action.payload.deviceDriverEntryObj,
            });
        case 'SET_DEVICE_TYPE_LIST':
            return Object.assign({}, state, {
                deviceTypeList: action.payload.deviceTypeList
            });
        case 'CHANGE_NAME_OF_PLATFORM':
            return Object.assign({}, state, {
                platformName: action.payload.platformName ? action.payload.platformName : '科士达储能智慧能源管理控制系统'
            });
        case 'CHANGE_LOGO_OF_PLATFORM':
            return Object.assign({}, state, {
                pathOfPlatformLogo: action.payload.pathOfPlatformLogo ? action.payload.pathOfPlatformLogo : logo
            });
        case 'CHANGE_USER_AVATAR':
            return Object.assign({}, state, {
                userAvatarURL: action.payload.userAvatarURL ? action.payload.userAvatarURL : avatar
            });
        case 'TOGGLE_LOADING':
            return Object.assign({}, state, {
                loading: action.payload.loading ?? false,
            });
        default:
            return state;
    }
}

export default reducerOfCommon;
