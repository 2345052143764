const reducerOfLogin = (state = {
    usernameToLogin: '',
    visibilityOfPassword: false,
    passwordToLogin: '',
    isRememberPassword: false,
    isForgetPassword: false,
    usernameToVerify: '',
    isSendVerificationCode: false,
    pollingOfPromptToVerify: -1,
    promptOfVerify: '',
    verificationCode: '',
    certificateOfResetPassword: '',
    newPassword: '',
    visibilityOfNewPassword: false,
    newPasswordToConfirm: '',
    visibilityOfNewPasswordToConfirm: false
}, action) => {
    switch(action.type) {
        case 'INIT_LOGIN_FORM':
            return Object.assign({}, state, {
                isRememberPassword: action.payload.isRememberPassword,
                usernameToLogin: action.payload.usernameToLogin,
                passwordToLogin: action.payload.passwordToLogin
            });
        case 'CHANGE_USERNAME':
            return Object.assign({}, state, {
                usernameToLogin: action.payload.usernameToLogin
            });
        case 'TOGGLE_VISIBILITY_OF_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfPassword: action.payload.visibilityOfPassword
            });
        case 'CHANGE_PASSWORD':
            return Object.assign({}, state, {
                passwordToLogin: action.payload.passwordToLogin
            });
        case 'TOGGLE_IS_REMEMBER_PASSWORD':
            return Object.assign({}, state, {
                isRememberPassword: action.payload.isRememberPassword
            });
        case 'LOGIN_SUCCESSFULLY':
            return Object.assign({}, state, {
                usernameToLogin: action.payload.usernameToLogin,
                passwordToLogin: action.payload.passwordToLogin
            });
        case 'TOGGLE_FORGET_PASSWORD':
            return Object.assign({}, state, {
                isForgetPassword: action.payload.isForgetPassword
            });
        case 'RETURN_TO_LOGIN':
            return Object.assign({}, state, {
                isForgetPassword: action.payload.isForgetPassword,
                usernameToVerify: action.payload.usernameToVerify,
                verificationCode: action.payload.verificationCode,
                certificateOfResetPassword: action.payload.certificateOfResetPassword,
                newPassword: action.payload.newPassword,
                visibilityOfNewPassword: action.payload.visibilityOfNewPassword,
                newPasswordToConfirm: action.payload.newPasswordToConfirm,
                visibilityOfNewPasswordToConfirm: action.payload.visibilityOfNewPasswordToConfirm
            });
        case 'CHANGE_USERNAME_TO_VERIFY':
            return Object.assign({}, state, {
                usernameToVerify: action.payload.usernameToVerify
            });
        case 'TOGGLE_IS_SEND_VERIFICATION_CODE':
            return Object.assign({}, state, {
                isSendVerificationCode: action.payload.isSendVerificationCode
            });
        case 'SET_POLLING_OF_PROMPT_OF_VERIFY':
            return Object.assign({}, state, {
                pollingOfPromptToVerify: action.payload.pollingOfPromptToVerify
            });
        case 'CLEAR_POLLING_OF_PROMPT_OF_VERIFY':
            return Object.assign({}, state, {
                isSendVerificationCode: action.payload.isSendVerificationCode,
                pollingOfPromptToVerify: action.payload.pollingOfPromptToVerify,
                promptOfVerify: action.payload.promptOfVerify
            });
        case 'SET_PROMPT_OF_VERIFY':
            return Object.assign({}, state, {
                promptOfVerify: action.payload.promptOfVerify
            });
        case 'CHANGE_VERIFICATION_CODE':
            return Object.assign({}, state, {
                verificationCode: action.payload.verificationCode
            });
        case 'SET_CERTIFICATE_OF_RESET_PASSWORD':
            return Object.assign({}, state, {
                certificateOfResetPassword: action.payload.certificateOfResetPassword
            });
        case 'INPUT_NEW_PASSWORD':
            return Object.assign({}, state, {
                newPassword: action.payload.newPassword
            });
        case 'TOGGLE_VISIBILITY_OF_NEW_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfNewPassword: action.payload.visibilityOfNewPassword
            });
        case 'INPUT_NEW_PASSWORD_TO_CONFIRM':
            return Object.assign({}, state, {
                newPasswordToConfirm: action.payload.newPasswordToConfirm
            });
        case 'TOGGLE_VISIBILITY_OF_NEW_PASSWORD_TO_CONFIRM':
            return Object.assign({}, state, {
                visibilityOfNewPasswordToConfirm: action.payload.visibilityOfNewPasswordToConfirm
            });
        default:
            return state;
    }
}

export default reducerOfLogin;
