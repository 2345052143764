const reducerOfPersonalCenter = (state = {
    userBasicInfoObj: [
        {
            name: 'account',
            // nameList_zh: ['账', '号'],
            // nameList_en: ['Account', 'Number'],
            nameList_zh: ['账号'],
            nameList_en: ['Account'],
            placeholder: '',
            value: '',
            enableToEdit: false
        },
        {
            name: 'nickname',
            // nameList_zh: ['昵', '称'],
            // nameList_en: ['Nick', 'Name'],
            nameList_zh: ['昵称'],
            nameList_en: ['Nickname'],
            placeholder: '1 ~ 30个中英文字符或数字',
            value: '',
            enableToEdit: true
        },
        {
            name: 'companyName',
            // nameList_zh: ['公', '司', '名', '称'],
            // nameList_en: ['Company', 'Name'],
            nameList_zh: ['公司名称'],
            nameList_en: ['Company Name'],
            placeholder: '1 ~ 30个字符',
            value: '',
            enableToEdit: true
        },
        {
            name: 'registerDate',
            // nameList_zh: ['注', '册', '日', '期'],
            // nameList_en: ['Register', 'Date'],
            nameList_zh: ['注册日期'],
            nameList_en: ['Register Date'],
            placeholder: '',
            value: '',
            enableToEdit: false
        }
    ],
    enableToEditUserBasicInfo: false,
    securityDataObj: {
        password: '',
        phone: '',
        email: ''
    },
    visibilityOfModalToChangeUserAvatar: false,
    pathOfUserAvatar: '',
    userAvatar: null,
    loadingOfUserAvatar: false,
    visibilityOfModalToChangeUserPassword: false,
    dataOfFormToChangeUserPassword: {
        password: '',
        newPassword: '',
        newPasswordAgain: ''
    },
    visibilityOfModalToChangeUserPhone: false,
    dataOfFormToChangeUserPhone: {
        password: '',
        phone: ''
    },
    visibilityOfModalToChangeUserEmail: false,
    dataOfFormToChangeUserEmail: {
        password: '',
        email: '',
        verificationCode: ''
    },
    loadingOfGetVerifyCodeToChangeEmail: false,
    pollingMailboxNeedToBind: -1,
    secondsOfGetVerifyCodeAgain: 300
}, action) => {
    switch(action.type) {
        case 'INIT_PERSONAL_DETAILS':
            return Object.assign({}, state, {
                userBasicInfoObj: action.payload.userBasicInfoObj,
                securityDataObj: action.payload.securityDataObj
            });
        case 'TOGGLE_ENABLE_TO_EDIT_USER_BASIC_INFO':
            return Object.assign({}, state, {
                enableToEditUserBasicInfo: action.payload.enableToEditUserBasicInfo
            });
        case 'SET_USER_BASIC_INFO':
            return Object.assign({}, state, {
                userBasicInfoObj: action.payload.userBasicInfoObj
            });
        case 'TOGGLE_MODAL_TO_CHANGE_USER_AVATAR':
            return Object.assign({}, state, {
                visibilityOfModalToChangeUserAvatar: action.payload.visibilityOfModalToChangeUserAvatar
            });
        case 'TOGGLE_UPLOAD_USER_AVATAR':
            return Object.assign({}, state, {
                loadingOfUserAvatar: action.payload.loadingOfUserAvatar
            });
        case 'SUCCESSFULLY_UPLOAD_USER_AVATAR':
            return Object.assign({}, state, {
                loadingOfUserAvatar: action.payload.loadingOfUserAvatar,
                pathOfUserAvatar: action.payload.pathOfUserAvatar,
                userAvatar: action.payload.userAvatar
            });
        case 'RESET_UPLOAD_USER_AVATAR':
            return Object.assign({}, state, {
                loadingOfUserAvatar: action.payload.loadingOfUserAvatar,
                pathOfUserAvatar: action.payload.pathOfUserAvatar,
                userAvatar: action.payload.userAvatar
            });
        case 'TOGGLE_MODAL_TO_CHANGE_USER_PASSWORD':
            return Object.assign({}, state, {
                visibilityOfModalToChangeUserPassword: action.payload.visibilityOfModalToChangeUserPassword
            });
        case 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PASSWORD':
            return Object.assign({}, state, {
                dataOfFormToChangeUserPassword: action.payload.dataOfFormToChangeUserPassword
            });
        case 'TOGGLE_MODAL_TO_CHANGE_USER_PHONE':
            return Object.assign({}, state, {
                visibilityOfModalToChangeUserPhone: action.payload.visibilityOfModalToChangeUserPhone
            });
        case 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_PHONE':
            return Object.assign({}, state, {
                dataOfFormToChangeUserPhone: action.payload.dataOfFormToChangeUserPhone
            });
        case 'TOGGLE_MODAL_TO_CHANGE_USER_EMAIL':
            return Object.assign({}, state, {
                visibilityOfModalToChangeUserEmail: action.payload.visibilityOfModalToChangeUserEmail
            });
        case 'CHANGE_DATA_OF_FORM_TO_CHANGE_USER_EMAIL':
            return Object.assign({}, state, {
                dataOfFormToChangeUserEmail: action.payload.dataOfFormToChangeUserEmail
            });
        case 'SET_POLLING_MAILBOX_NEED_TO_BIND':
            return Object.assign({}, state, {
                pollingMailboxNeedToBind: action.payload.pollingMailboxNeedToBind
            });
        case 'SET_SECONDS_OF_GET_VERIFY_CODE_AGAIN':
            return Object.assign({}, state, {
                loadingOfGetVerifyCodeToChangeEmail: action.payload.loadingOfGetVerifyCodeToChangeEmail,
                secondsOfGetVerifyCodeAgain: action.payload.secondsOfGetVerifyCodeAgain
            });
        default:
            return state;
    }
}

export default reducerOfPersonalCenter;
