/**
 * Station List
 */
export const stationList = (state = {
    totalNumOfStation: 0,
    alarmNumOfStation: 0,
    offlineNumOfStation: 0,
    normalNumOfStation: 0,
    totalOfStations: 10,
    stationList: [],
    nameUsedToQueryStation: '',
    pageNumUsedToQueryStation: 1,
    pageSizeUsedToQueryStation: 10,
    visibilityOfModalToDeleteStation: false,
    visibilityOfModalToUpgrade: false,
    visibilityOfModalToUploadPictureInStation: false,
    pathOfPictureInStationControlled: '',
    pictureOfStationControlled: null,
    loadingOfPictureInStationControlled: false
}, action) => {
    switch(action.type) {
        case 'QUERY_STATION_LIST':
            return Object.assign({}, state, {
                stationList: action.payload.stationList,
                totalOfStations: action.payload.totalOfStations,
                // alarmNumOfStation: action.payload.alarmNumOfStation,
                // offlineNumOfStation: action.payload.offlineNumOfStation,
                // normalNumOfStation: action.payload.normalNumOfStation
            });
        case 'QUERY_STATION_LIST_COUNT':
            return Object.assign({}, state, {
                totalNumOfStation: action.payload.totalNumOfStation,
                alarmNumOfStation: action.payload.alarmNumOfStation,
                offlineNumOfStation: action.payload.offlineNumOfStation,
                normalNumOfStation: action.payload.normalNumOfStation
            });
        case 'CHANGE_NAME_USED_TO_QUERY_STATION':
            return Object.assign({}, state, {
                nameUsedToQueryStation: action.payload.nameUsedToQueryStation
            });
        case 'RESET_PARAMS_USED_TO_QUERY_STATION':
            return Object.assign({}, state, {
                typeCodeUsedToQueryStation: action.payload.typeCodeUsedToQueryStation,
                nameUsedToQueryStation: action.payload.nameUsedToQueryStation
            });
        case 'CHANGE_PAGE_NUM_USED_TO_QUERY_STATION':
            return Object.assign({}, state, {
                pageNumUsedToQueryStation: action.payload.pageNumUsedToQueryStation
            });
        case 'TOGGLE_DELETE_MODAL':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteStation: action.payload.visibilityOfModalToDeleteStation
            });
        case 'TOGGLE_UPGRADE_MODAL':
            return Object.assign({}, state, {
                visibilityOfModalToUpgrade: action.payload.visibilityOfModalToUpgrade
            });
        case 'UPGRADE_STATION':
            return Object.assign({}, state, {

            });
        case 'TOGGLE_MODAL_TO_UPLOAD_PICTURE_OF_STATION':
            return Object.assign({}, state, {
                visibilityOfModalToUploadPictureInStation: action.payload.visibilityOfModalToUploadPictureInStation,
                pathOfPictureInStationControlled: action.payload.pathOfPictureInStationControlled
            });
        case 'TOGGLE_LOAD_PICTURE_OF_STATION_CONTROLLED':
            return Object.assign({}, state, {
                loadingOfPictureInStationControlled: action.payload.loadingOfPictureInStationControlled
            });
        case 'SUCCESSFULLY_LOAD_PICTURE_OF_STATION_CONTROLLED':
            return Object.assign({}, state, {
                loadingOfPictureInStationControlled: action.payload.loadingOfPictureInStationControlled,
                pathOfPictureInStationControlled: action.payload.pathOfPictureInStationControlled,
                pictureOfStationControlled: action.payload.pictureOfStationControlled
            });
        default:
            return state;
    }
}

/**
 * Account Manage
 */
export const accountManage = (state = {
    visibilityOfModalToAddAccount: false,
    formDataToAddAccount: {
        userCode: '',
        userName: '',
        userPwd: '',
        isAdmin: 0,
        userEmail: '',
        userPhone: '',
        companyName: ''
    },
    usernameUsedToQueryAccount: '',
    pageNumUsedToQueryAccount: 1,
    pageSizeUsedToQueryAccount: 10,
    totalOfAccounts: 0,
    accountList: [],
    directionOfTooltipInAccountTable: 'bottom',
    accountInfoToControl: {
        accountId: '',
        accountCode: '',
        accountLevel: ''
    },
    visibilityOfModalToEditAccount: false,
    formDataUsedToEditAccount: {
        userName: '',
        userPhone: '',
        companyName: ''
    },
    visibilityOfModalToResetAccountPassword: false,
    visibilityOfModalToAllocateStation: false,
    stationListUsedToAllocate: [],
    ownedStationIdList: [],
    visibilityOfModalToDeleteAccount: false
}, action) => {
    switch(action.type) {
        case 'TOGGLE_DRAWER_TO_ADD_ACCOUNT':
            return Object.assign({}, state, {
                visibilityOfModalToAddAccount: action.payload.visibilityOfModalToAddAccount
            });
        case 'SET_FORM_DATA_TO_ADD_ACCOUNT':
            return Object.assign({}, state, {
                formDataToAddAccount: action.payload.formDataToAddAccount
            });
        case 'QUERY_ACCOUNT_LIST':
            return Object.assign({}, state, {
                totalOfAccounts: action.payload.totalOfAccounts,
                accountList: action.payload.accountList,
                directionOfTooltipInAccountTable: action.payload.directionOfTooltipInAccountTable
            });
        case 'CHANGE_USERNAME_USED_TO_QUERY_ACCOUNT':
            return Object.assign({}, state, {
                usernameUsedToQueryAccount: action.payload.usernameUsedToQueryAccount
            });
        case 'CHANGE_PAGE_NUM_USED_TO_QUERY_ACCOUNT':
            return Object.assign({}, state, {
                pageNumUsedToQueryAccount: action.payload.pageNumUsedToQueryAccount
            });
        case 'CHANGE_PAGE_SIZE_USED_TO_QUERY_ACCOUNT':
            return Object.assign({}, state, {
                pageNumUsedToQueryAccount: action.payload.pageNumUsedToQueryAccount,
                pageSizeUsedToQueryAccount: action.payload.pageSizeUsedToQueryAccount
            });
        case 'RESET_PARAMS_USED_TO_QUERY_ACCOUNT':
            return Object.assign({}, state, {
                usernameUsedToQueryAccount: action.payload.usernameUsedToQueryAccount
            });
        case 'CHANGE_ACCOUNT_INFO_TO_CONTROL':
            return Object.assign({}, state, {
                accountInfoToControl: action.payload.accountInfoToControl
            });
        case 'TOGGLE_MODAL_TO_EDIT_ACCOUNT':
            return Object.assign({}, state, {
                visibilityOfModalToEditAccount: action.payload.visibilityOfModalToEditAccount
            });
        case 'SET_FORM_DATA_USED_TO_EDIT_ACCOUNT':
            return Object.assign({}, state, {
                formDataUsedToEditAccount: action.payload.formDataUsedToEditAccount
            });
        case 'TOGGLE_RESET_ACCOUNT_PASSWORD_MODEL':
            return Object.assign({}, state, {
                visibilityOfModalToResetAccountPassword: action.payload.visibilityOfModalToResetAccountPassword
            });
        case 'QUERY_STATION_LIST_USED_TO_ALLOCATE':
            return Object.assign({}, state, {
                stationListUsedToAllocate: action.payload.stationListUsedToAllocate,
                ownedStationIdList: action.payload.ownedStationIdList
            });
        case 'CHANGE_OWNED_STATION_ID_LIST':
            return Object.assign({}, state, {
                ownedStationIdList: action.payload.ownedStationIdList
            });
        case 'TOGGLE_ALLOCATE_STATION_DRAWER':
            return Object.assign({}, state, {
                visibilityOfModalToAllocateStation: action.payload.visibilityOfModalToAllocateStation
            });
        case 'TOGGLE_DELETE_ACCOUNT_MODEL':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteAccount: action.payload.visibilityOfModalToDeleteAccount
            });
        default:
            return state;
    }
}

/**
 * Operation Log
 */
export const operationLog = (state = {
    keywordsUsedToQueryOperations: '',
    pageNumUsedToQueryOperations: 1,
    pageSizeUsedToQueryOperations: 10,
    totalOfOperations: 0,
    operationList: [],
}, action) => {
    switch(action.type) {
        case 'SET_OPERATION_LIST':
            return Object.assign({}, state, {
                totalOfOperations: action.payload.totalOfOperations,
                operationList: action.payload.operationList,
            });
        case 'CHANGE_KEYWORDS_USED_TO_QUERY_OPERATIONS':
            return Object.assign({}, state, {
                keywordsUsedToQueryOperations: action.payload.keywordsUsedToQueryOperations,
            });
        case 'CHANGE_PAGE_NUM_USED_TO_QUERY_OPERATIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryOperations: action.payload.pageNumUsedToQueryOperations,
            });
        case 'CHANGE_PAGE_SIZE_USED_TO_QUERY_OPERATIONS':
            return Object.assign({}, state, {
                pageNumUsedToQueryOperations: action.payload.pageNumUsedToQueryOperations,
                pageSizeUsedToQueryOperations: action.payload.pageSizeUsedToQueryOperations,
            });
        default:
            return state;
    }
}

/**
 * Collector Upgrade
 */
export const collectorUpgrade = (state = {
    displayMode: 1,
    collectorStatusUsedToQueryData: '',
    upgradeFileTypeUsedToQueryData: '',
    keywordsUsedToQueryData: '',
    pageNumUsedToQueryData: 1,
    pageSizeUsedToQueryData: 10,
    totalNumberOfData: 0,
    tableData: [],
    visibilityOfModalToUploadFile: false,
    isOnlyFileName: false,
    formDataUsedToUploadFile: {
        file: null,
        upgradeFileType: '1',
        version: '',
        description: '',
    },
    visibilityOfModalToDeleteFile: false,
    fileInfoToControl: {
        upgradeFileName: '',
    },
    visibilityOfModalToDeviceUpgrade: false,
    collectorInfoToControl: {
        powerId: '',
        powerName: '',
        collectId: '',
        collectName: '',
    },
    formDataUsedToDeviceUpgrade: {
        orderInfoStr: '',
        upgradeFileObjStr: '',
        deviceAddress: '',
    },
    fileListUsedToDeviceUpgrade: [],
    visibilityOfModalToNetPenetrate: false,
    collectorIdToControl: '',
    formDataOfNetPenetration: {
        ip: '',
        port: 0,
        key: '',
        type: '1',
    },
}, action) => {
    switch(action.type) {
        case 'TOOGLE_DISPLAY_MODE':
            return Object.assign({}, state, {
                displayMode: action.payload.displayMode,
                collectorStatusUsedToQueryData: action.payload.collectorStatusUsedToQueryData,
                upgradeFileTypeUsedToQueryData: action.payload.upgradeFileTypeUsedToQueryData,
                keywordsUsedToQueryData: action.payload.keywordsUsedToQueryData,
                pageNumUsedToQueryData: action.payload.pageNumUsedToQueryData,
                pageSizeUsedToQueryData: action.payload.pageSizeUsedToQueryData,
            });
        case 'QUERY_TABLE_DATA':
            return Object.assign({}, state, {
                totalNumberOfData: action.payload.totalNumberOfData,
                tableData: action.payload.tableData,
            });
        case 'TOGGLE_VISIBILITY_OF_MODAL_TO_ADD_FILE':
            return Object.assign({}, state, {
                visibilityOfModalToUploadFile: action.payload.visibilityOfModalToUploadFile,
                isOnlyFileName: action.payload.isOnlyFileName,
            });
        case 'CHANGE_COLLECTOR_STATUS_USED_TO_QUERY_DATA':
            return Object.assign({}, state, {
                collectorStatusUsedToQueryData: action.payload.collectorStatusUsedToQueryData,
            });
        case 'CHANGE_UPGRADE_FILE_TYPE_USED_TO_QUERY_DATA':
            return Object.assign({}, state, {
                upgradeFileTypeUsedToQueryData: action.payload.upgradeFileTypeUsedToQueryData,
            });
        case 'CHANGE_KEYWORDS_USED_TO_QUERY_DATA':
            return Object.assign({}, state, {
                keywordsUsedToQueryData: action.payload.keywordsUsedToQueryData,
            });
        case 'RESET_QUERY_PARAMETERS':
            return Object.assign({}, state, {
                collectorStatusUsedToQueryData: action.payload.collectorStatusUsedToQueryData,
                upgradeFileTypeUsedToQueryData: action.payload.upgradeFileTypeUsedToQueryData,
                keywordsUsedToQueryData: action.payload.keywordsUsedToQueryData,
                pageNumUsedToQueryData: action.payload.pageNumUsedToQueryData,
                pageSizeUsedToQueryData: action.payload.pageSizeUsedToQueryData,
            });
        case 'CHANGE_PAGE_NUM_USED_TO_QUERY_DATA':
            return Object.assign({}, state, {
                pageNumUsedToQueryData: action.payload.pageNumUsedToQueryData,
            });
        case 'CHANGE_PAGE_SIZE_USED_TO_QUERY_DATA':
            return Object.assign({}, state, {
                pageNumUsedToQueryData: action.payload.pageNumUsedToQueryData,
                pageSizeUsedToQueryData: action.payload.pageSizeUsedToQueryData,
            });
        case 'CHANGE_FORM_DATA_TO_UPLOAD_FILE':
            return Object.assign({}, state, {
                formDataUsedToUploadFile: action.payload.formDataUsedToUploadFile,
            });
        case 'TOGGLE_VISIBILITY_OF_MODAL_TO_DELETE_FILE':
            return Object.assign({}, state, {
                visibilityOfModalToDeleteFile: action.payload.visibilityOfModalToDeleteFile,
            });
        case 'CHANGE_FILE_INFO_TO_CONTROL':
            return Object.assign({}, state, {
                fileInfoToControl: action.payload.fileInfoToControl,
            });
        case 'TOGGLE_VISIBILITY_OF_MODAL_TO_DEVICE_UPGRADE':
            return Object.assign({}, state, {
                visibilityOfModalToDeviceUpgrade: action.payload.visibilityOfModalToDeviceUpgrade,
            });
        case 'CHANGE_COLLECTOR_INFO_TO_CONTROL':
            return Object.assign({}, state, {
                collectorInfoToControl: action.payload.collectorInfoToControl,
            });
        case 'CHANGE_FORM_DATA_USED_TO_DEVICE_UPGRADE':
            return Object.assign({}, state, {
                formDataUsedToDeviceUpgrade: action.payload.formDataUsedToDeviceUpgrade,
            });
        case 'QUERY_FILE_LIST_USED_TO_DEVICE_UPGRADE':
            return Object.assign({}, state, {
                fileListUsedToDeviceUpgrade: action.payload.fileListUsedToDeviceUpgrade,
            });
        case 'TOGGLE_MODAL_TO_NET_Penetrate':
            return Object.assign({}, state, {
                visibilityOfModalToNetPenetrate: action.payload.visibilityOfModalToNetPenetrate,
                collectorIdToControl: action.payload.collectorIdToControl,
            });
        case 'CHANGE_FORM_DATA_OF_NET_PENETRATION':
            return Object.assign({}, state, {
                formDataOfNetPenetration: action.payload.formDataOfNetPenetration,
            });
        default:
            return state;
    }
}

/**
 * Page Setup
 */
export const pageSetup = (state = {
    platformName: '',
    pathOfPlatformLogo: '',
    platformLogo: null,
    loadingOfPlatformLogo: false
}, action) => {
    switch(action.type) {
        case 'INIT_PAGE_SETUP':
            return Object.assign({}, state, {
                platformName: action.payload.platformName,
                pathOfPlatformLogo: action.payload.pathOfPlatformLogo,
                platformLogo: action.payload.platformLogo
            });
        case 'CHANGE_PLATFORM_NAME':
            return Object.assign({}, state, {
                platformName: action.payload.platformName
            });
        case 'TOGGLE_UPLOAD_PLATFORM_LOGO':
            return Object.assign({}, state, {
                loadingOfPlatformLogo: action.payload.loadingOfPlatformLogo
            });
        case 'SUCCESSFULLY_UPLOAD_PLATFORM_LOGO':
            return Object.assign({}, state, {
                loadingOfPlatformLogo: action.payload.loadingOfPlatformLogo,
                pathOfPlatformLogo: action.payload.pathOfPlatformLogo,
                platformLogo: action.payload.platformLogo
            });
        default:
            return state;
    }
}
