import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import "moment/locale/zh-cn";

import enTrans from "./assets/i18n/en-us.json";
import zhTrans from "./assets/i18n/zh-cn.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            // Prefer querying localStorage, check browser default language code if no relevant data is queried.
            order: ['localStorage', 'navigator'],
            caches: ['localStorage']
        },

        resources: {
            en: {
                translation: enTrans
            },
            zh: {
                translation: zhTrans
            }
        },

        debug: false,

        interpolation: {
            escapeValue: false
        }
    }).then(() => {});

export default i18n;
